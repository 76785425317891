import "./Addisir.scss";
import React, { useState, useEffect, useRef } from "react";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Grid, MenuItem, TextField, Button, Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { isirApprovedRemarkBysiemens, isirRejectedRemarkBysiemens } from "../../Redux/APIs/api_view";
import Loading from "../../Components/Loader/Loader";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

const Remark = ({ isirNumberPassed, prevDataAdd2, afterClosingIsirActionDataStateInRemark }) => {
    console.log("prevDataAdd2", prevDataAdd2);
   // console.log("prevDataAdd2", prevDataAdd2.massReleasetoSQE && prevDataAdd2.statusId && prevDataAdd2.statusName);
   
  
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    console.log("userid", userDetails.userId);
    const [openDialog, setOpenDialog] = useState(false);
    const [isLoaderFeedback, setIsLoaderFeedback] = useState(false);
    const handleClickOpen = (text) => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const [getRemarkByCe, setGetRemarkByCe] = useState("");
    const [openModalContent, setOpenModalContend] = useState(false);
    const [getRemarkByCeForApprove, setGetRemarkByCeForApprove] = useState("");
    const [openModalContentForApprove, setOpenModalContentForApprove] = useState(false);
    const handleChangeRemark = (e) => {
        console.log(e.target.value);
        setGetRemarkByCe(e.target.value);   
    }
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("Required **");
    const [isLoader, setIsLoader] = useState(false);
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    const handleRemarkforApprove = (e) => {
        console.log(e.target.value);
        setGetRemarkByCeForApprove(e.target.value);
    }
    
    const handleOpenModalcontentRemarkReject = () => {
        setOpenModalContend(true);
    }
    const handleOpenModalcontentRemarkApproved = () => {
        setOpenModalContentForApprove(true);
    }
    const handleCloseRemarkModal = () => {
        setOpenModalContend(false);
    }
    const handleCloseApproveReamrk = () => {
        setOpenModalContentForApprove(false);

    }

    const [openSnack, setOpenSnack] = useState(false)
    const handleOpenSnack = () => {
        setOpenSnack(true)
    }
    const handleCloseSnack = () => {
        setOpenSnack(false)
    }
    const [openSnackRed, setOpenSnackRed] = useState(false)
    const handleOpenSnackRed = () => {
        setOpenSnackRed(true)
    }
    const handleCloseSnackRed = () => {
        setOpenSnackRed(false)
    }
    const [getApprovedData, setGetApprovedData] = useState("");


    function getApprovedRemark() {
        setIsLoaderFeedback(true);
        //setIsLoader(true);
        isirApprovedRemarkBysiemens(token, isirNumberPassed, userDetails.userId)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoaderFeedback(false);
                    handleOpenSnack();
                   // setIsLoader(false);
                   // getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    //handleOpenSnackRed();
                    setIsLoader(false);
                }
                console.log("getApproveApiData", response);
                setGetApprovedData(response);
              


            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                setIsLoader(false);
            });


    };
    //isirRejectedRemarkBysiemens
    const [getRejectedDataApi, setGetRejectedDataApi] = useState("");

    function getRejectedRemark() {
        setIsLoaderFeedback(true);
        isirRejectedRemarkBysiemens(token, isirNumberPassed, getRemarkByCe, userDetails.userId)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    //setIsLoader(false);
                    setIsLoaderFeedback(false);
                    handleOpenSnackRed();

                    handleCloseDialog();
                   

                   // getTableData();
                }
                //else {
                //    handleSnackOpen(response.responseMsg, "error");
                //    setIsLoader(false);
                //}
                console.log("getRejectedApiData", response);
                setGetRejectedDataApi(response);
               


            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                setIsLoader(false);
            });
    };
    useEffect(() => {
        console.log("getApprovedData", getApprovedData); 
       
    }, [getApprovedData]);
    useEffect(() => {
        
        console.log("getRejectedDataApi", getRejectedDataApi);
    }, [ getRejectedDataApi]);

    
    return (
        <div>  {/*className="remark-content"*/ }
           
            {/* <h3 style={{ paddingBottom: "25px" }}>Closure Comments</h3>*/}
           

            <div>
                <Dialog fullWidth open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">

                    <DialogContent>
                        <h3 style={{ paddingBottom: "25px" }}>Component Engineer Comments</h3>
                        <Grid>
                            <TextField
                                fullWidth
                                id="standard-multiline-flexible"
                                placeholder=" Enter Feedback"
                                variant="outlined"
                                //error={
                                //    feedbackError && feedbackError.length > 0
                                //}
                                //helperText={feedbackError}
                                multiline
                                inputProps={{
                                    style: {
                                        height: "70px",
                                    },
                                }}
                                maxRows={5}
                                value={getRemarkByCeForApprove}
                                onChange={handleRemarkforApprove}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        {userDetails.roleId === 6 || userDetails.roleId === 1 ?
                            <div> <Button className="pt-button--primary button-submit first-btn" variant="contained" onClick={getApprovedRemark} color="primary">
                                Submit 
                            </Button>


                                <Button className="pt-button--secondary button-submit" variant="contained" onClick={handleCloseDialog} color="primary">
                                    Cancel 
                                </Button></div>
                            : <></>}

                    </DialogActions>
                </Dialog>
                <>
                    <SnackbarCustom
                        open={open}
                        message={snackMessage}
                        alertType={alertType}
                        handleClose={handleClose}
                    />
                </>
               
                {isLoaderFeedback ? <Loading loaderText="Loading...." /> : <></>}
            </div>

            <div>
                <Dialog fullWidth open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">

                    <DialogContent>
                        <h3 style={{ paddingBottom: "25px" }}>Component Engineer Comments</h3>
                        <Grid>
                            <TextField
                                fullWidth
                                id="standard-multiline-flexible"
                                placeholder=" Enter Feedback"
                                variant="outlined"
                                //error={
                                //    feedbackError && feedbackError.length > 0
                                //}
                                //helperText={feedbackError}
                                multiline
                                inputProps={{
                                    style: {
                                        height: "70px",
                                    },
                                }}
                                maxRows={5}
                                value={getRemarkByCe}
                                onChange={handleChangeRemark}
                               // value={getRemarkByCeForApprove}
                                //onChange={handleRemarkforApprove}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        {userDetails.roleId === 6 || userDetails.roleId === 1 ?
                            <div> <Button className="pt-button--primary button-submit first-btn" variant="contained" onClick={getRejectedRemark} color="primary">
                                Submit
                            </Button>


                                <Button className="pt-button--secondary button-submit cancel-btn" variant="contained" onClick={handleCloseDialog} color="primary">
                                    Cancel
                                </Button></div>
                            : <></>}

                    </DialogActions>
                </Dialog>
                {isLoaderFeedback ? <Loading loaderText="Loading...." /> : <></>}
            </div>
           
          

            {userDetails.roleId === 6 || userDetails.roleId === 1 ? <Grid className="btns-apprrej">

                


                <Button className="apprv-btn"
                    className="pr-button--secondary bulk-upload-submit-btn submit"
                    onClick={getApprovedRemark}
                    disabled={prevDataAdd2.groupId == 3 || afterClosingIsirActionDataStateInRemark|| (prevDataAdd2.statusName == "ISIR Closed" && prevDataAdd2.statusId == 7 && prevDataAdd2.massReleasetoSQE != null) }
                    //onClick={handleClickOpen}

                    
                >
                    Approve
                </Button>

                <Button
                    onClick={handleClickOpen}
                    className="pr-button--secondary bulk-upload-submit-btn submit "
                    disabled={prevDataAdd2.groupId == 3 || afterClosingIsirActionDataStateInRemark|| (prevDataAdd2.statusName == "ISIR Closed" && prevDataAdd2.statusId == 7 && prevDataAdd2.massReleasetoSQE != null)}
                >
                    Reject
                </Button>
            </Grid>: <></>
}

{/*onClick={handleOpenModalcontentRemarkApproved}*/ }
{/*onClick={handleOpenModalcontentRemarkReject}>*/ }
           


           
            <Dialog
                className="test"
                open={openSnack}
                onClose={handleCloseSnack}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

            >
                <DialogTitle id="alert-dialog-title" onClose={handleCloseSnack}></DialogTitle>
                <DialogContent >
                    <DialogContentText id="alert-dialog-description">

                        "Files uploaded are Approved by Component Engineer"

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="pt-button--primary button-submit" variant="contained" onClick={handleCloseSnack} color="primary">
                        Close
                    </Button>
                    {/*<Button onClick={handleClose} color="primary" autoFocus>*/}
                    {/*    Agree*/}
                    {/*</Button>*/}
                </DialogActions>
            </Dialog>
            <Dialog className="snackgreen"
                open={openSnackRed}
                onClose={handleCloseSnackRed}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

            >
                <DialogTitle id="alert-dialog-title" ></DialogTitle>
                <DialogContent >
                    <DialogContentText id="alert-dialog-description">

                        "Files uploaded are rejected by Component Engineer"

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="pt-button--primary button-submit" variant="contained" onClick={handleCloseSnackRed} color="primary">
                        Close
                    </Button>
                    {/*<Button onClick={handleClose} color="primary" autoFocus>*/}
                    {/*    Agree*/}
                    {/*</Button>*/}
                </DialogActions>
            </Dialog>
        </div>
       
       
         
       
    );
}
export default Remark;