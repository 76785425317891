import {
    AddBox,
    Check,
    DeleteOutline,
    Edit,
    SaveAlt,
    FilterList,
    FirstPage,
    LastPage,
    ChevronRight,
    ChevronLeft,
    Clear,
    Search,
    ArrowDownward,
    Remove,
    ViewColumn,
} from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import { forwardRef } from "react";
import { GroupAdd, ListAltOutlined } from "@material-ui/icons";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import DescriptionIcon from '@material-ui/icons/Description';
import { Button } from "@material-ui/core";
import {
    withStyles,
} from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

import AddIsir from "../Screens/ADDISIR/AddlSir";

import Isirhm from "../Screens/ISIRHomePage/Isirhm";
//import Epwork from "../Screens/Listisir/Epwork";
import Epwork from "../Screens/Listisir/Epwork";


export const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => (
        <DeleteOutline {...props} ref={ref} style={{ color: "var(--red)" }} />
    )),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => (
        <Search {...props} ref={ref} style={{ fontSize: "2.5rem" }} />
    )),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const tableIconsInputScreen = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => (
        <Check {...props} style={{ fontSize: "large", padding: 0 }} ref={ref} />
    )),
    Clear: forwardRef((props, ref) => (
        <Clear {...props} style={{ fontSize: "large", padding: 0 }} ref={ref} />
    )),
    Delete: forwardRef((props, ref) => (
        <DeleteOutline {...props} ref={ref} style={{ color: "var(--red)" }} />
    )),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => (
        <Edit {...props} style={{ fontSize: "large", padding: 0 }} ref={ref} />
    )),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => (
        <Search {...props} ref={ref} style={{ fontSize: "2.5rem" }} />
    )),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
export let customStylesDefault = {
    control: (provided, state) => ({
        ...provided,
        border: "none",
        borderBottom: `1px solid #00B3B3`,
        borderRadius: 0,
        cursor: "text",
        boxShadow: "0 0 0 0 transparent !important",
        minHeight: 0,
        ":hover": {
            border: "none",
            borderBottom: `1px solid #00B3B3`,
            borderRadius: 0,
            boxShadow: "0 0 0 0 transparent !important",
        },
    }),
    singleValue: (provided, state) => ({
        ...provided,
        textAlign: "left",
    }),
    input: (provided, state) => ({
        ...provided,
        textAlign: "left",
        padding: 0,
    }),
    menu: (provided, state) => ({
        ...provided,
        textAlign: "left",
        backgroundColor: `var(--select-active-bg-color)`,
        color: `var(--select-active-text-color)`,
    }),
    menuList: (provided, state) => ({
        ...provided,
        color: `var(--select-active-text-color)`,
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: "none",
    }),
    clearIndicator: (provided, state) => ({
        ...provided,
        padding: "1px !important",
        cursor: "pointer",
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: "1px !important",
        cursor: "pointer",
    }),
};
export let customStylesYear = {
    control: (provided, state) => ({
        ...provided,
        cursor: "text",
        boxShadow: "0 0 0 0 transparent !important",
        minHeight: 0,
        ":hover": {
            boxShadow: "0 0 0 0 transparent !important",
        },
    }),
    singleValue: (provided, state) => ({
        ...provided,
        textAlign: "left",
    }),
    input: (provided, state) => ({
        ...provided,
        textAlign: "left",
        padding: 0,
    }),
    menu: (provided, state) => ({
        ...provided,
        textAlign: "left",
        backgroundColor: `var(--select-active-bg-color)`,
        color: `var(--select-active-text-color)`,
    }),
    menuList: (provided, state) => ({
        ...provided,
        color: `var(--select-active-text-color)`,
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: "none",
    }),
    clearIndicator: (provided, state) => ({
        ...provided,
        padding: "1px !important",
        cursor: "pointer",
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: "1px !important",
        cursor: "pointer",
    }),
};
export const theme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: `var(--side-drawer-bg-color)`,
        primary25: `var(--app-permission-list-item-color)`,
        primary50: `var(--app-permission-list-item-color)`,
    },
});

export const Label = styled('label')`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
  font-size: 12px;
`;

export const InputWrapper = styled('div')`
width: 331px;
border: 1px solid #008AA6;
background:#EBF7F8;
  border-radius: 4px;
  padding-top: 8px;
    padding-bottom: 1px;
  display: flex;
  flex-wrap: wrap;

  
  & input {
    background:#EBF7F8;
    font-size: 14px;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

export const Tag = styled(({ label, onDelete, ...props }) => (
    <div {...props}>
        <span>{label}</span>
        <CloseIcon onClick={onDelete} />
    </div>
))`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`;

export const Listbox = styled('ul')`
  width: 331px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #D1FFF2;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus='true'] {
    background-color: #62EEC780;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
`;
export const homeScreenIconStyle = {
    padding: "5px",
    margin: "5px -20px 0px 10px",
};
const userKey = "supplier.quality.management_tool@siemens";
const passKey = "Supplier_Quanlity_Manamenet_Tool@SIEMENS";
const userdata = "YzNWd2NHeHBaWEl1Y1hWaGJHbDBlUzV0WVc1aFoyVnRaVzUwWDNSdmIyeEFjMmxsYldWdWN5QlVhSFVnVTJWd0lESXlJREl3TWpJPQ==";
const userpass = "VTNWd2NHeHBaWEpmVVhWaGJteHBkSGxmVFdGdVlXMWxibVYwWDFSdmIyeEFVMGxGVFVWT1V5QlVhSFVnVTJWd0lESXlJREl3TWpJPQ==";
export const LandingAdmin = [
    {
        roleId: 1,
        role: "Admin",
        menuLink: "/complaint",
        menuName: "8D Tool",
        icon: <ListAltOutlined style={homeScreenIconStyle} />,
    },
    {
        roleId: 1,
        role: "Admin",
        menuLink: "/Isirhm",
        menuName: "Development Tool",
        icon: <DescriptionIcon style={homeScreenIconStyle} />,
    },
    {
        roleId: 1,
        role: "Admin",
        menuLink: "/admin",
        menuName: "Administrator",
        icon: <GroupAdd style={homeScreenIconStyle} />,
    },
    //{
    //    roleId: 1,
    //    role: "Admin",
    //    menuLink: "/otherRole",
    //    menuName: "OtherRole",
    //    icon: <GroupAdd style={homeScreenIconStyle} />,
    //},
];
export const LadingComponentEngineer = [
    {
        roleId: 6,
        roleList: 1,
        role: "Component Engineer (CE)",
        menuLink: "/complaint",
        menuName: "8D Tool",
        icon: <ListAltOutlined style={homeScreenIconStyle} />,
    },
    {
        roleId: 6,
        roleList: 1,
        role: "Component Engineer (CE)",
        menuLink: "/Isirhm",
        menuName: "Development Tool",
        icon: <DescriptionIcon style={homeScreenIconStyle} />,
    },
    {
        roleList: 1,
        role: "Component Engineer (CE)",
        menuLink: "/admin",
        menuName: "Administrator",
        icon: <GroupAdd style={homeScreenIconStyle} />,
    },
    //{
    //    roleId: 6,
    //    role: "Component Engineer (CE)",
    //    menuLink: "/admin",
    //    menuName: "Administrator",
    //    icon: <GroupAdd style={homeScreenIconStyle} />,
    //},
];
export const LadingRD = [
    {
        roleId: 7,
        roleList: 1,
        role: "R&D",
        menuLink: "/Epwork",
        menuName: "List Of Development Tracker",
        icon: <DescriptionIcon style={homeScreenIconStyle} />,
    },
    {
        roleList: 1,
        role: "R&D",
        menuLink: "/admin",
        menuName: "Administrator",
        icon: <GroupAdd style={homeScreenIconStyle} />,
    },
    
];
export const LadingSQE = [
    //{
    //    roleId:8,
    //    role: "SQE",
    //    menuLink: "/complaint",
    //    menuName: "8D Tool",
    //    icon: <ListAltOutlined style={homeScreenIconStyle} />,
    //},
    //{
    //    roleId:8,
    //    role: "SQE",
    //    menuLink: "/Isirhm",
    //    menuName: "Development Tool",
    //    icon: <DescriptionIcon style={homeScreenIconStyle} />,
    //},
    {
        roleId: 8,
        roleList:1,
        role: "SQE",
        menuLink: "/Epwork",
        menuName: "List Of Development Tracker",
        icon: <DescriptionIcon style={homeScreenIconStyle} />,
    },
    {
        roleList:1,
        role: "SQE",
        menuLink: "/admin",
        menuName: "Administrator",
        icon: <GroupAdd style={homeScreenIconStyle} />,
    },
];
export const LadingSPR = [
    {
        roleId: 9,
        roleList: 1,
        role: "SPR",
        menuLink: "/Epwork",
        menuName: "List Of Development Tracker",
        icon: <DescriptionIcon style={homeScreenIconStyle} />,
    },
    {
        roleList: 1,
        role: "SPR",
        menuLink: "/admin",
        menuName: "Administrator",
        icon: <GroupAdd style={homeScreenIconStyle} />,
    },
];
export const LadingOPR = [
    {
        roleId: 10,
        roleList: 1,
        role: "OPR",
        menuLink: "/Epwork",
        menuName: "List Of Development Tracker",
        icon: <DescriptionIcon style={homeScreenIconStyle} />,
    },
    {
        roleList: 1,
        role: "OPR",
        menuLink: "/admin",
        menuName: "Administrator",
        icon: <GroupAdd style={homeScreenIconStyle} />,
    }
];


export const AdminstratorOptions = [
    {
        adminMenuId: 1,
        adminMenuName: "User Management",
        adminMenuLink: "/userManagement",

    },
    {
        adminMenuId: 2,
        adminMenuName: "Master Tables",
        adminMenuLink: "/masterTables",
    },
    {
        adminMenuId: 3,
        adminMenuName: "Development Tracker Attachments",
        adminMenuLink: "/masterAttachments",
    },
    {
        adminMenuId: 4,
        adminMenuName: "Other Role",
        adminMenuLink: "/otherRole",
    },
]
export const ComplaintScreenOptions = [
    {
        complaintMenuId: 1,
        complaintMenuName: "Notify Complaint",
        complaintMenuLink: "/createComplaint",
    },
    {
        complaintMenuId: 2,
        complaintMenuName: "List of Complaints",
        complaintMenuLink: "/complaintsList",
    },
    {
        complaintMenuId: 3,
        complaintMenuName: "Reports",
        complaintMenuLink: "/reports",
    },
];

export const IsirScreenOptions = [
    {
        complaintMenuId: 1,
        complaintMenuName: "Add Development Tracker",
        complaintMenuLink: "/AddIsir",
    },
    {
        complaintMenuId: 2,
        complaintMenuName: "List of Development Tracker",
        complaintMenuLink: "/Epwork",
    },
];
export const ScreenOptions = [

    {
        complaintMenuId: 2,
        complaintMenuName: "List of Development Tracker",
        complaintMenuLink: "/Epwork",
    },
];
export const LandingSupplier = [


    {
       
        roleId: 3,
        roleList: 1,
        role: "Supplier",
        supplierLoginType:1,
        menuLink: "/complaintsList",
        menuName: "Complaint List",
        icon: <ListAltOutlinedIcon style={homeScreenIconStyle} />,
    
    },


    {
        roleId: 3,
        roleList: 1,
        role: "Supplier",
        supplierLoginType:2,
        menuLink: "/Epwork",
        menuName: "List Of Development Tracker",
        icon: <DescriptionIcon style={homeScreenIconStyle} />,
    },
    {
        roleList: 1,
        role: "Supplier",
        menuLink: "/admin",
        menuName: "Administrator",
        icon: <GroupAdd style={homeScreenIconStyle} />,
    },
    
    //{
       // roleId: 3,
       // role: "Supplier",
       // menuLink: "/complaint",
       // menuName: "8D Tool",
       // icon: <ListAltOutlined style={homeScreenIconStyle} />,
    //},
    //{
        ///roleId: 3,
       // role: "Supplier",
       // menuLink: "/Isirhm",
       // menuName: "Development Tool",
       // icon: <DescriptionIcon style={homeScreenIconStyle} />,
   // },
    //{
       // roleId: 3,
       //role: "Supplier",
        //menuLink: "/admin",
       // menuName: "Administrator",
       // icon: <GroupAdd style={homeScreenIconStyle} />,
   // },
];
export const LandingSQMEngineer = [
    {
        roleId: 2,
        roleList: 1,
        role: "SQM Engineer Siemens",
        menuLink: "/complaint",
        menuName: "8D Tool",
        icon: <ListAltOutlined style={homeScreenIconStyle} />,
    },
    {
        roleId: 2,
        roleList: 1,
        role: "SQM Engineer Siemens",
        menuLink: "/Isirhm",
        menuName: "Development Tool",
        icon: <DescriptionIcon style={homeScreenIconStyle} />,
    },
    {
        roleList: 1,
        role: "SQM Engineer Siemens",
        menuLink: "/admin",
        menuName: "Administrator",
        icon: <GroupAdd style={homeScreenIconStyle} />,
    },
  
];
export const MasterRouteLanding = [
    ...LandingAdmin,
    ...LandingSupplier,
    ...LandingSQMEngineer,  
    //  ...LandingComponentEngineer,
    ...LadingComponentEngineer,
    ...LadingRD,
    ...LadingSQE,
    ...LadingSPR,
    ...LadingOPR
];

export const apiKeys = {
    userName: userKey,
    password: passKey,
};

//export const baseApiURL = "https://localhost:44304/api";
/*export const baseURL = "https://localhost:44304";*/

export const baseApiURL = "https://sqm.si-in.siemens.cloud/api";
export const baseURL = "https://sqm.si-in.siemens.cloud";

export function logoutUser() {
    var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
    window.history.pushState({ path: newurl }, "", `/`);
    localStorage.clear();
    sessionStorage.clear();
    caches.keys().then((names) => {
        names.forEach((name) => {
            caches.delete(name);
        });
    });
    window.location.href = "/";
}
export const tableCellPreviousFY = {
    background: `var(--light-red)`,
};
export const tableCellCurrentFY = {
    background: `var(--green-type-1)`,
};
export const tdInputPreviousFY = {
    background: `var(--light-sand--type-1)`,
};
export const tdInputCurrentFY = {
    background: `var(--light-sand)`,
};
export const thInputPreviousFY = {
    background: `var(--light-sand--type-1)`,
    lineHeight: `0.5rem`,
    fontWeight: `bold`,
};
export const thInputCurrentFY = {
    background: `var(--light-sand)`,
    lineHeight: `0.65rem`,
    fontWeight: `bold`,
};
export const getCurrentFiscalYear = () => {
    var today = new Date();
    var curMonth = today.getMonth();

    var fiscalYr = "";
    if (curMonth > 8) {
        var nextYr1 = (today.getFullYear() + 1).toString();
        fiscalYr =
            today.getFullYear().toString() +
            "-" +
            nextYr1.charAt(2) +
            nextYr1.charAt(3);
    } else {
        var nextYr2 = today.getFullYear().toString();
        fiscalYr =
            (today.getFullYear() - 1).toString() +
            "-" +
            nextYr2.charAt(2) +
            nextYr2.charAt(3);
    }
    return fiscalYr;
};
export const getTodayDate = () => {
    var today = new Date();
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }

    if (mm < 10) {
        mm = '0' + mm;
    }
    today = mm + '/' + dd + '/' + yyyy;
    return today;
}
export const getTodayDateMoment = () => {
    var today = new Date();
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }

    if (mm < 10) {
        mm = '0' + mm;
    }
    today = yyyy + '-' + mm + '-' + dd;
    return today;
}
export const getRoundUptoXDecimals = (value, decimal) => {
    let factor = Math.pow(10, decimal);
    return Math.round(value * factor + Number.EPSILON) / factor;
};

export const getURLParameter = (sParam) => {
    var sPageURL = Buffer.from(
        window.location.search.substring(1),
        "base64"
    ).toString();
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split("=");
        var value = "";
        for (var j = 0; j < sParameterName.length; j++) {
            if (sParameterName[j] === sParam) {
                for (var k = j + 1; k < sParameterName.length; k++) {
                    value = value + sParameterName[k];
                }
                break;
            }
        }
        if (!value) {
            continue;
        } else {
            break;
        }
    }
    return decodeURI(value);
};
export const renderValueWithThousandSeparator = (value) => {
    return !value
        ? value
        : value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};
export const spocEmail = "sowmya.bhat@siemens.com";
export const GetAddButton = () => {
    return <Button className="pr-button--primary ">Add</Button>;
};
export const GetEditButton = () => {
    return <Button className="pr-button--primary">Edit</Button>;
};
export const GetExportButton = () => {
    return <Button className="pr-button--primary">Export</Button>;
};
export const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: "var(--input-bg-color)",
        border: '1px solid var(--blue-green)',
        fontSize: 16,
        width: '100%',
        padding: '10px 12px',
    },
}))(InputBase);

export const user = {
    userName: userdata,
    password: userpass,
}
