import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  MenuItem,
  Button,
  makeStyles,
  InputLabel,
  FormControl,
  Select,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loader/Loader";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import FileSaver from "file-saver";
import { BootstrapInput } from "../../../Utilities/Utility";
import { GetStatusMasterDDL, GetSupplierMasterDDL } from "../../../Redux/APIs/api_Common";
import { Report8DDownload } from "../../../Redux/APIs/api_Disciplines";
const useStyles = makeStyles(() => ({
  paperSty: {
    padding: "30px",
    marginTop: "10px",
  },
  childGrid: {
  },
  btnGrid: {
    justifyContent: "center",
    marginTop: "2rem",
  },
}));

export default function BaseReport() {
  const classes = useStyles();
  const [isLoader, setIsLoader] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const siteToken = useSelector((state) => state.saveTokenReducer);
  const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const handleSnackOpen = () => {
    setOpenSnackbar(true);
  };
  const handleCloseSnackbar = (e) => {
    setOpenSnackbar(false);
  };
  const [reportInputs,setReportInputs]=useState({
    fromDate:null,
    toDate:null,
    supplierId:null,
    statusId:null,
  });
  function handleReportChange(event,value){
    let elem = event.target.name;
    switch (elem) {
      case "fromDate":
           setReportInputs({
            ...reportInputs,
            fromDate:event.target.value,
          })
       break;
       case "toDate":
           setReportInputs({
        ...reportInputs,
        toDate:event.target.value,
      })
       break;
       case "supplierId":
        setReportInputs({
        ...reportInputs,
        supplierId:value.props.value,
   })
    break;
    case "statusId":
      setReportInputs({
      ...reportInputs,
      statusId:value.props.value,
 })
  break;
       default:
        break;
  
  }
  }

     function report8DDownload(){
setIsLoader(true);
    Report8DDownload(siteToken, reportInputs)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.byteLength <= 200) {
            setIsLoader(false);
            handleSnackOpen();
            setSnackMessage("No Records are Found ");
            setAlertType("success");
          } else {
            var blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            FileSaver.saveAs(blob, "8DClosure Time.xlsx");
            setIsLoader(false);
            handleSnackOpen();
            setSnackMessage("8DClosure Time Report Downloaded");
            setAlertType("success");
          }
        } else {
          setIsLoader(false);
          handleSnackOpen();
          setSnackMessage("Report download failed");
          setAlertType("error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        handleSnackOpen();
        setSnackMessage("Error: ", error);
        setAlertType("error");
      });
  }

 

 
  const [supplierEmail, setSupplierEmail] = useState([]);
  async function SupplierEmailDDL() {
    const response = await GetSupplierMasterDDL(token, userDetails.userId);
    if (response && response.length > 0) {
      setSupplierEmail(response);
    }
     else {
      setSupplierEmail([ {
        id: 0,
        name: "No Options",
      },]);
    }
  }
  const [statusDDL, setStatusDDL] = useState([]);
  async function StatusDDL() {
    const response = await GetStatusMasterDDL(token, userDetails.userId);
    if (response && response.length > 0) {
      setStatusDDL(response);
    }
     else {
      setStatusDDL([ {
        id: 0,
        name: "No Options",
      },]);
    }
  }
  useEffect(()=>{
    SupplierEmailDDL() ;
    StatusDDL();
  },[])
  return (
    <Paper className={classes.paperSty}>
      <form>
        <Grid container spacing={2} className={classes.childGrid}>
        <Grid item xs={12} sm={6} md={3}>
        <FormControl  fullWidth>
        <InputLabel shrink htmlFor="bootstrap-input">
         From Date 
        </InputLabel>
        <BootstrapInput
         type="date"
         onChange={handleReportChange}
         value={reportInputs.fromDate}
         id="bootstrap-input" 
         name="fromDate"
       //  inputProps={{ max:getTodayDateMoment() }}
        />
      </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
        <FormControl  fullWidth>
        <InputLabel shrink htmlFor="bootstrap-input">
         To Date 
        </InputLabel>
        <BootstrapInput
         type="date"
         id="bootstrap-input" 
         name="toDate"
         onChange={handleReportChange}
         value={reportInputs.toDate}
        />
      </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
        <FormControl  fullWidth>
          <InputLabel shrink htmlFor="bootstrap-input">
            {" "}
            Supplier Company Name
          </InputLabel>
          <Select
            labelId="demo-customized-select-label"
            id="supplierId"
            name="supplierId"
            onChange={handleReportChange}
            value={reportInputs.supplierId}
            input={<BootstrapInput />}
          >
            {supplierEmail.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
      
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
      <FormControl  fullWidth>
        <InputLabel shrink htmlFor="bootstrap-input">
          {" "}
          Status
        </InputLabel>
        <Select
          labelId="demo-customized-select-label"
          id="supplierEmail"
          name="statusId"
          onChange={handleReportChange}
          value={reportInputs.statusId}
          input={<BootstrapInput />}
        >
          {statusDDL.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
    
      </FormControl>
    </Grid>
        </Grid>
        <Grid container spacing={1} className={classes.btnGrid}>
          <Button
            onClick={report8DDownload}
            className="pt-button--secondary bulk-upload-submit-btn"
            type="button"
          >
            Download
          </Button>
        </Grid>
        <SnackbarCustom
          open={openSnackbar}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleCloseSnackbar}
        />
        {isLoader ? <Loader /> : <React.Fragment></React.Fragment>}
      </form>
    </Paper>
  );
}
