import {Grid,TextField,Button, Dialog, DialogContent, DialogActions} from "@material-ui/core";
import { useState,useEffect } from "react";
import { baseApiURL, getTodayDateMoment, tableIcons } from "../../../Utilities/Utility";
import MaterialTable from "material-table";
import Loading from "../../../Components/Loader/Loader";
import { tableOptionsExport } from "../../../Themes/LightTheme";
import { theme ,customStylesDefault} from "../../../Utilities/Utility";
import { useSelector ,useDispatch} from "react-redux";
import Select from 'react-select'
import {
  FormControl,
 FormHelperText
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import { getD1WhoDDL, getStatusDDL } from "../../../Redux/APIs/api_Common";
import { AddDisciplineAttachmentDetails, DeleteD7, GetD7Details, GetDisciplineAttachmentDetails, PostD7Details, PostD7TextDetails, UpdateD7, UpdateD7TextDetails } from "../../../Redux/APIs/api_Disciplines";
import { DeleteDisciplineAttachments } from "../../../Redux/APIs/api_Complaint";
import moment from "moment";
import { disciplineAction } from "../../../Redux/Actions/actions";
export default function Discipline5(props){
  const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
      (state) => state.storeUserDetailsReducer.userData
  );
  const dispatch=useDispatch();
  const discpline7Data= useSelector(
    (state) => state.disciplineReducer.disciplineData
);
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [attachmentData,setAttchmentData]=useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isLoaderDraft, setIsLoaderDraft] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [isLoader1, setIsLoader1] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  let [inputFileAttachment, setInputFileAttachment] = useState(Date.now);
  const [openValidationDialog,setOpenValidationDialog]=useState("");
  const [dialogErrorText,setDialogErrorText]=useState("");
   const handleClickOpenDialogValidation = (text) => {
      setDialogErrorText(text);
      setOpenValidationDialog(true);
    };
  function renderDate(value){
    let DateFormat=!value?null:moment(value).format('YYYY-MM-DD');
    return DateFormat;
  }
  const [inputValues,setInputValues]=useState({
    final8Dsummary: props.d7Details?.length>0?props?.d7Details[0]?.final8Dsummary:null,
    complaintNum: props.rowData[0]?.complaintNumber,
   
  })
  const [errorTexts,setErrorTexts]=useState({
    final8Dsummary:"",
  })
  const [d7Data,setD7Data]=useState({
    final8Dsummary:props.d7Details?.length>0? props?.d7Details[0].final8Dsummary:null,
    loggedinUserId: userDetails.userId,
  });
  const handleChange = (event,value) => {
    let elem = event.target.name;
      switch (elem){
        case "final8Dsummary":
         setInputValues({
          ...inputValues,
          [elem]:event.target.value
  
         })
         setErrorTexts({
          ...errorTexts,
          final8Dsummary:""
        })
        break;
        default:break;
      }
    }
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = (e) => {
    setOpen(false);
    setOpenValidationDialog(false);
  };
  
  function validateD7(value) {
    return !value
      ? { isValid: false, helperText: "*Required" }
      : { isValid: true, helperText: "" };
  }
  const columns=[
    {
      title:"What",
      field:"whatD7",
      editComponent: ({ value, onChange }) => (   
        <TextField
          fullWidth
          onChange={e => onChange(e.target.value)}
          error={!value}
          helperText={!value?"required":""}
          value={value}
          multiline
        />
      ),
      validate:(rowData)=>validateD7(rowData.whatD7),
      cellStyle: {
        minWidth: 900,
        maxWidth: 900,
      },
  },
  {
      title:"Who",
      field:"whoD7",
      editComponent:(props)=>getWhoComponent(props),
      validate:(rowData)=>validateD7(rowData.whoD7)
  },
  {
      title:"When",
      field:"whenD7",
      render:(rowData)=><span>{renderDate(rowData.whenD7)}</span>,
      editComponent:(objProps)=>getWhenComponent(objProps) ,
     validate: (rowData) =>validateD7(rowData.whenD7),
  },
  {
      title:"Status",
      field:"statusId",
      render: (rowData) => <span>{renderStatusValue(rowData.statusId)}</span>,
      validate:(rowData)=>validateD7(rowData.statusId),
      editComponent:(props)=>getStatusComponent(props)
  },
];
var columnsAttachment=[
  {
      title:"Attachment",
      field:"fileName",
      editComponent: (props) => getAttachmentComponent(props)
  },
  {
    title: "Download",
    field: "download",
    editable:"never",
    render: (rowData) => DownloadFile(rowData),
  }

];
 function DownloadFile(data){
  return (
    <Button
    className="pt-button--tertiary" variant="contained" 
    onClick={() =>
      downloadFileAttachment(data)
    }
  >
   Download
  </Button>
  );
 }
 function downloadFileAttachment(data){
  let attachmentData={
    fileName: (data.fileName).trim(),                                                                                                                                                           
    fileUrl:(data.fileUrl).trim(),
    filetype:(data.filetype).trim()
  }
  const fetchPromise=fetch(baseApiURL + "/Admin/downloadfile",
  {
    method:"post",
    headers:{
      "Content-type":"application/json; charset=UTF-8",
      "Authorization": "Bearer " + token,
    },
    body: JSON.stringify(attachmentData),
});
fetchPromise.then(response=>{
  response.blob().then(blob=>{
    const link=document.createElement('a');
    const url=URL.createObjectURL(blob);
    link.href=url;
    link.download="AttachmentD7_"+props.rowData[0]?.complaintNumber+attachmentData.filetype;
    link.click();
  })
})
}
const [uploadedFileName, setUploadedFileName] = useState("");

const updateExcelHandler = (event) => {
  setSelectedFile(event.target.files);
  setUploadedFileName(event.target.files[0].name);
};
function getAttachmentComponent(objProps) {
  // const value=selectedFile?.name?selectedFile.name:"";
   return ( 
     <form className="bulk-upload-form">
     {selectedFile == null ?<>
      <TextField
         id="fileUploadAttachment"
         variant="filled"
         label="Attachment Upload"
         fullWidth
         onChange={updateExcelHandler}  
         key={inputFileAttachment}
         type="file"
         InputLabelProps={{shrink: true}}
         inputProps={{ multiple: true }}   
         helperText={uploadedFileName} 
     />
     </>:<>
     <span>{uploadedFileName}</span></>}
   </form>
   );
 }
const [statusData,setStatusData]=useState([]);

async function getStatusDetails() {
  const response = await getStatusDDL(token, userDetails.userId);
  if (response && response.length > 0) {
    setStatusData(
      response.map((item) => ({ value: item.id, label: item.name }))
    );
  } else {
    setStatusData([]);
  }
}
const [whoData,setWhoData]=useState([]);
async function getWhoDetails() {
  const response = await getD1WhoDDL(token, userDetails.userId,props?.rowData[0].complaintNumber);
  if (response && response.length > 0) {
    setWhoData(
    response.map((item,key)=>({ value:key,label:response[key]}))
    );
  } else {
    setWhoData([]);
  }
}

function renderStatusValue(value) {
  let obj = {};
  if (statusData && statusData.length > 0) {
    obj = statusData.find((c) => c.value === value);
  }
  if (obj) {
    return obj.label;
  } else {
    return "";
  }
}
function getStatusComponent(objProps) {
  const Status= objProps.rowData.statusId
    ? statusData.find((x) => x.value === objProps.rowData.statusId)
    : null;
  return (
    <FormControl error={!objProps.value ? true : false} fullWidth>
      <Select
        theme={theme}
        options={statusData}
        value={
          typeof objProps.value === "string" ||
          typeof objProps.value === "number"
            ? Status
            : objProps.value
        }
        onChange={(opt) => {
          objProps.onChange(opt?.value);
        }}
        styles={customStylesDefault}
        placeholder="Status"
        isClearable
      />
      <>
        <FormHelperText>{!objProps.value ? "*Required" : ""}</FormHelperText>
      </>
    </FormControl>
  );
}
function getWhoComponent(objProps) {
 
  return (
    <FormControl error={!objProps.value ? true : false} fullWidth>
    <Select
    theme={theme}
    options={whoData}
    value={
      objProps.label
    }
    onChange={(opt) => {
      objProps.onChange(opt?.label);
    }}
    styles={customStylesDefault}
    placeholder="Who"
    isClearable
  />
  <>
  <FormHelperText>{!objProps.value ? "*Required" : ""}</FormHelperText>
</>
    </FormControl>
  );
}
function handleRowDelete(oldFormData, resolve, reject){
  var  attachmentId=oldFormData.attachmentId;
   setIsLoader(true);
   DeleteDisciplineAttachments(token,attachmentId,userDetails.userId)
   .then((response) => {
     if (response.response) {
      handleSnackOpen(response.responseMsg, "success");
       resolve();
      setIsLoader(false);
      getAttchmentData()
     } else {
      handleSnackOpen(response.responseMsg, "error");
       reject();
       setIsLoader(false);
     }
   })
   .catch((error) => {
     console.log(error)
     setIsLoader(false);
     handleSnackOpen("Exception : " + error, "error");
     reject();
   });
 }
function getTableData() {
  var flag=true;
  setIsLoader(true);
  GetD7Details(token,props?.rowData[0].complaintNumber,userDetails.userId)
    .then((response) => {
      if(response.result.length>0){
        discpline7Data.discipline7=true;
        for(var i=0;i<response.result.length;i++){
         var obj=response.result[i];
         if(obj?.statusId!==2){
           flag=false;
        }
        }  
        if(flag===true){ 
        discpline7Data.disciplineStatus7=true;
        }
        else{
          discpline7Data.disciplineStatus7=false;
        }
        dispatch(disciplineAction(discpline7Data));
      }
      setTableData(response.result);
      setIsLoader(false);
    })
    .catch((error) => {
      handleSnackOpen("Error : " + error, "error");
      setIsLoader(false);
    });
}
const AddD7Details = () => { 
  if
   (
    !inputValues.final8Dsummary){
    handleClickOpenDialogValidation("Please Fill Inputs !!");
   }
   else{
    setIsLoaderDraft(true);
   if(props?.d71UpdateResponse?.length>0){
    var updateId=props.d71UpdateResponse[0].id;
    const updateValues={
      ...inputValues,
      id:updateId
    }
    UpdateD7TextDetails(token, updateValues)
      .then((response) => {
        if (response.response) {
          handleSnackOpen(response.responseMsg, "success");
          setIsLoaderDraft(false);
           props.getD7SaveDetails();
        } else {
          handleSnackOpen(response.responseMsg, "error");
          setIsLoaderDraft(false);
        }
      })
      .catch((error) => {
        handleSnackOpen("Exception : " + error, "error");
        setIsLoaderDraft(false);
      });
   }
   else{
    PostD7TextDetails(token, inputValues)
      .then((response) => {
        if (response.response) {
          handleSnackOpen(response.responseMsg, "success");
          setIsLoaderDraft(false);
           props.getD7SaveDetails();
        } else {
          handleSnackOpen(response.responseMsg, "error");
          setIsLoaderDraft(false);
        }
      })
      .catch((error) => {
        handleSnackOpen("Exception : " + error, "error");
        setIsLoaderDraft(false);
      });
    }
  };
  }
const handleRowAdd = (newTableData, resolve, reject) => {  
  setIsLoader(true);
  newTableData.loggedinUserId = userDetails.userId;
  newTableData.complaintNum=props?.rowData[0].complaintNumber;
  if(isEditable()===false){
    handleSnackOpen("Action Restricted", "Info");
    reject();
    setIsLoader(false)
  }
  else{
  PostD7Details(token, newTableData)
    .then((response) => {
      if (response.response) {
        handleSnackOpen(response.responseMsg, "success");
        resolve();
        setIsLoader(false);
        getTableData();
      } else {
        handleSnackOpen(response.responseMsg, "error");
        reject();
        setIsLoader(false);
      }
    })
    .catch((error) => {
      handleSnackOpen("Exception : " + error, "error");
      reject();
      setIsLoader(false);
    });
  }
};
const handleRowUpdate=(newTableData, oldTableData, resolve, reject)=>{
  newTableData.loggedinUserId = userDetails.userId;
  setIsLoader(true);
  newTableData.loggedinUserId = userDetails.userId;
  newTableData.complaintNum=props?.rowData[0].complaintNumber;
 UpdateD7(token, newTableData)
      .then((response) => {
          if (response.response) {
              handleSnackOpen(response.responseMsg, "success");
              resolve();
              setIsLoader(false);
              getTableData();
          } else {
              handleSnackOpen(response.responseMsg, "error");
              reject();
              setIsLoader(false);
          }
      })
      .catch((error) => {
          handleSnackOpen("Exception : " + error, "error");
          reject();
          setIsLoader(false);
      });
};
function handleRowDeleteD7(oldData, resolve, reject){
  setIsLoader(true);
  DeleteD7(token,oldData)
  .then((response) => {
    if (response.response) {
     handleSnackOpen(response.responseMsg, "success");
      resolve();
     setIsLoader(false);
     getTableData();
    } else {
     handleSnackOpen(response.responseMsg, "error");
      reject();
      setIsLoader(false);
    }
  })
  .catch((error) => {
    console.log(error)
    setIsLoader(false);
    handleSnackOpen("Exception : " + error, "error");
    reject();
  });
}
function getAttchmentData() {
  setIsLoader1(true);
      GetDisciplineAttachmentDetails(token, userDetails.userId,props?.rowData[0].complaintNumber,"D7")
      .then((response) => {
        setAttchmentData(response);
        setIsLoader1(false);
      })
      .catch((error) => {
       handleSnackOpen("Error : " + error, "error");
        setIsLoader1(false);
      });
  }
function handleRowAddAttachment(newTableData, resolve, reject){
  if(isEditable()===false){
    handleSnackOpen("Action Restricted", "Info");
    reject();
    setIsLoader(false)
  }
 else if (!selectedFile) {
    handleSnackOpen("Please Select a File", "info");
    setIsLoader1(false);
    reject();
  } 
  else{
    setIsLoader1(true);
    const formData = new FormData();
    for (let i = 0 ; i < selectedFile.length ; i++) {
      formData.append("file", selectedFile[i]);
  }
  formData.append("files", selectedFile);
  formData.append("userId", userDetails.userId);
  formData.append("ComplaintNumber",props?.rowData[0].complaintNumber);
  formData.append("Discipline", "D7");
       setIsLoader1(false); 
    AddDisciplineAttachmentDetails(token, formData)
    .then((response) => {
      if (response.response) {
       handleSnackOpen(response.responseMsg, "success");
        resolve();
       setIsLoader1(false);
       getAttchmentData();
       setSelectedFile(null);
      } else {
       handleSnackOpen(response.responseMsg, "error");
        reject();
        setIsLoader1(false);
      }
    })
    .catch((error) => {
      console.log(error)
      setIsLoader1(false);
      setSelectedFile(null);
      setInputFileAttachment(Date.now);
      handleSnackOpen("Exception : " + error, "error");
      reject();
    });
  }
  }
  function isEditable(){
    if(userDetails.roleId!==3){
        return false;
    }
    else{
      if(props.rowData[0]?.status==="Pending by Supplier"||props.rowData[0]?.status==="Rejected by User"){
        return true
      }
      else{
        return false;
      }
    }
  }
useEffect(()=>{
  console.log("d7",props.d7Details)
  props.getD7SaveDetails();
  getTableData();
  getWhoDetails();
  getStatusDetails();
  getAttchmentData();
},[])

function getWhenComponent(objProps) {
  const rowData = objProps.rowData;
  let value =rowData.whenD7;
  
  return (
    <TextField
      id="standard-basic"
      value={value}
      type="date"
      fullWidth
      inputProps={{ min:getTodayDateMoment()}}
      placeholder="Password"
      onChange={(e) => objProps.onChange(e.target.value)}
      error={!value}
      helperText={!value?"*required":""}
    />
  );
 }
    return(         
    <Grid container spacing={2} md={12}>  
    <Grid item xs={12} className="details-center">
    <h3 className="title-heading">Identify and Implement Permanent Preventive Actions</h3>
  </Grid>
  <Grid className="align-ctr" item xs={12}  md={6}>
 <span>Final 8D summary/Notes</span>
</Grid>
<Grid item xs={12} sm={3} md={4}>
<TextField
   className="mt-1"
   fullWidth
   name="final8Dsummary"
   onChange={handleChange}
   multiline
   maxRows={3}
   disabled={userDetails.roleId!==3||props?.rowData[0]?.status==="8D Completed"||props?.rowData[0]?.status==="Approved"}
                    value={
                        userDetails.roleId === 1 || userDetails.roleId === 2 || userDetails.roleId==6
    ?d7Data?.final8Dsummary
    :inputValues.final8Dsummary
  }
  error={
    !inputValues.final8Dsummary
  }
  helperText={!inputValues.final8Dsummary?"** Required":""}
   type="text"
   id="manufacturingCause"
  
 />
</Grid>
{userDetails.roleId===3?
<Grid item>
<Button  className="pt-button--primary button-submit" disabled={props?.rowData[0].status==="8D Completed"||props?.rowData[0]?.status==="Approved"} onClick={AddD7Details} variant="contained">
Save
</Button>
</Grid>:<Grid></Grid>
}
  <>      
  <Grid container xs={12} className="complaintsHomePage">

    <Grid item xs={12}  className="matTable">
    <div className="Table-Margin">
      <MaterialTable
        //title={getTableTitle()}
        columns={columns}
        data={tableData}
        icons={tableIcons}
        editable={{
            editTooltip: () => userDetails.roleId === 3 ?
                "Edit" : "Action Restricted"
            ,
            deleteTooltip: () => userDetails.roleId === 3 ?
                "Delete" : "Action Restricted"
            ,
            isEditable: isEditable,
            isDeletable: isEditable,
          onRowUpdate: (newTableData, oldTableData) =>
          new Promise((resolve, reject) => {
            handleRowUpdate(
              newTableData,
              oldTableData,
              resolve,
              reject
            );
          }),
          onRowDelete: (oldFormData) =>
          new Promise((resolve, reject) => {
              handleRowDeleteD7(oldFormData, resolve, reject);
          }),
        onRowAdd: (newTableData) =>
          new Promise((resolve, reject) => {
            handleRowAdd(newTableData, resolve, reject);
          }),
                   
        }}
        isLoading={isLoader}
        options={tableOptionsExport}
      />
      </div>
    </Grid>
  </Grid>
</>

<Grid className="align-ctr" item xs={12} sm={6} md={6}>
<h3>Upload Attachment</h3>
</Grid>
<Grid item xs={12} sm={12} md={12}>
<Grid item xs={12}  className="fileMatTable">
    <MaterialTable
      //title={getTableTitle()}
      columns={columnsAttachment}
     data={attachmentData}
      icons={tableIcons}
      editable={{
        deleteTooltip:()=>userDetails.roleId===3?
        "Delete":"Action Restricted",
         isDeletable:isEditable,
        onRowDelete: (oldFormData) =>
        new Promise((resolve, reject) => {
            handleRowDelete(oldFormData, resolve, reject);
        }),
      onRowAdd: (newTableData) =>
        new Promise((resolve, reject) => {
          handleRowAddAttachment(newTableData, resolve, reject);
        }),
        onRowAddCancelled:()=> {
          setUploadedFileName("");
          setSelectedFile(null)
        },    
      }}
      isLoading={isLoader1}
      options={tableOptionsExport}
    />
  </Grid>

</Grid>
<Dialog open={openValidationDialog}>
      
<DialogContent className="content-center">
{dialogErrorText}
</DialogContent>
<DialogActions>
<Button className="pt-button--secondary button-submit" variant="contained" onClick={handleClose} color="primary">
Ok
</Button>
</DialogActions>
</Dialog>
<>
<SnackbarCustom
  open={open}
  message={snackMessage}
  alertType={alertType}
  handleClose={handleClose}
/>
</>
{isLoaderDraft ? <Loading loaderText="Loading...." /> : <></>}
</Grid>
    )
}