import React, { useEffect, useState } from "react";
import { Grid, MenuItem } from "@material-ui/core";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
import moment from "moment";
import CheckIcon from "@material-ui/icons/Check";
import { useSelector } from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { BootstrapInput } from "../../../Utilities/Utility";
import { Label, InputWrapper, Listbox, Tag, getTodayDate } from "../../../Utilities/Utility";
import Loading from "../../../Components/Loader/Loader";
import {
    GetPartDDl,
    GetSupplierEmailDDl,
} from "../../../Redux/APIs/api_Common";
import UseAutocomplete from "../../../Components/Autocomplete/AutoComplete";
import EmailAutocomplete from "../../../Components/Autocomplete/EmailAutoComplete";

export default function CreateComplaintDetails(props) {
    console.log("props", props);
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );

    let [isLoader, setIsLoader] = useState(false);
    const [ccOptions, setCcOptions] = useState([]);
    const handleChangeSupplierInputs = (event, value) => {
        let elem = event.target.name;
        switch (elem) {
            case "supplierEmail":
                const supplierName = supplierEmail.find((x) => x.id === value.props.value)?.name;
                const CCEmails = supplierEmail.find((x) => x.id === value.props.value)?.ccEmails;
                const supplierCode = supplierEmail.find((x) => x.id === value.props.value)?.code;
                setCcOptions(CCEmails);
                if (props?.rowData?.status === "Draft" || props?.rowData?.status === "Recall" || (props?.rowData?.status === "Sent back to Siemens")) {
                    props.setDraftInputs({
                        ...props.draftInputs,
                        id: value.props.value,
                        supplierId: value.props.value,
                        supplierName: supplierName,
                        suppliernumber: supplierCode
                    })
                }
                else {
                    props.setCreateComplaintInputs({
                        ...props.createComplaintInputs,
                        [elem]: value.props.value,
                        supplierId: value.props.value,
                        supplierNameAuto: supplierName,
                        supplierNumberAuto: supplierCode
                    });
                }

                break;
            case "partNumber":
                const partDesc = partNumber.find((x) => x.id === value.props.value)?.partDesc;
                if (props?.rowData?.status === "Draft" || props?.rowData?.status === "Recall" || props?.rowData?.status === "Sent back to Siemens") {
                    props.setDraftInputs({
                        ...props.draftInputs,
                        partId: value.props.value,
                        partDescription: partDesc,
                    })
                }
                else {
                    props.setCreateComplaintInputs({
                        ...props.createComplaintInputs,
                        [elem]: value.props.value,
                        partDescription: partDesc,
                    });
                }

                break;
            case "supplierPo":
                if (props?.rowData?.status === "Draft" || props?.rowData?.status === "Recall" || props?.rowData?.status === "Sent back to Siemens") {
                    props.setDraftInputs({
                        ...props.draftInputs,
                        [elem]: event.target.value,
                    })
                }
                else {
                    props.setCreateComplaintInputs({
                        ...props.createComplaintInputs,
                        [elem]: event.target.value,
                    });
                }
                break;
            case "ccEmail":
                if (props?.rowData?.status === "Draft" || props?.rowData?.status === "Recall" || props?.rowData?.status === "Sent back to Siemens") {
                    props.setDraftInputs((old) => {
                        return {
                            ...props.draftInputs,
                            ...old,
                            cclist: value.props.value,
                        }
                    });
                }
                else {
                    props.setCreateComplaintInputs((old) => {
                        return {
                            ...props.createComplaintInputs,
                            ...old,
                            [elem]: value.props.value
                        }
                    });
                }
                break;
            case "supplierInvoice":
                if (props?.rowData?.status === "Draft" || props?.rowData?.status === "Recall" || props?.rowData?.status === "Sent back to Siemens") {
                    props.setDraftInputs({
                        ...props.draftInputs,
                        [elem]: event.target.value,
                    })
                }
                else {
                    props.setCreateComplaintInputs({
                        ...props.createComplaintInputs,
                        [elem]: event.target.value,
                    })
                }
                break;
            case "deliveredQuantity":
                if (props?.rowData?.status === "Draft" || props?.rowData?.status === "Recall" || props?.rowData?.status === "Sent back to Siemens") {
                    props.setDraftInputs({
                        ...props.draftInputs,
                        deliveredQty: parseInt(event.target.value),
                    })
                }
                else {
                    props.setCreateComplaintInputs({
                        ...props.createComplaintInputs,
                        [elem]: event.target.value,
                    })
                }
                break;
            case "dateCodeBatchLot":
                if (props?.rowData?.status === "Draft" || props?.rowData?.status === "Recall" || props?.rowData?.status === "Sent back to Siemens") {
                    props.setDraftInputs({
                        ...props.draftInputs,
                        [elem]: event.target.value,
                    })
                }
                else {
                    props.setCreateComplaintInputs({
                        ...props.createComplaintInputs,
                        [elem]: event.target.value,
                    })
                }
                break;
            default:
                break;
        }
    };
    const {
        getRootProps,
        getInputLabelProps,
        getInputProps,
        getTagProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
        value,
        focused,
        setAnchorEl,
    } = useAutocomplete({
        id: "customized-hook-demo",
        name: "ccEmail",
        multiple: true,
        defaultValue: props?.rowData?.status ? (props.rowData.cclist).map((item, key) => (props.rowData.cclist[key])) : [],
        options: ccOptions,
        getOptionLabel: (option) => option,
        onChange: (e, value) => {
            if (props?.rowData?.status === "Draft" || props?.rowData?.status === "Recall" || props?.rowData?.status === "Sent back to Siemens") {
                props.setDraftInputs((old) => {
                    return {
                        ...old,
                        cclist: value
                    }
                });
            }
            else {
                props.setCreateComplaintInputs((old) => {
                    return {
                        ...old,
                        cclist: value
                    }
                })
            }
        }
    });

    const [defaIndex, setDefaIndex] = useState(null);
    function findDefSupplerMailIdx() {
        let defaIndexVal = supplierEmail.findIndex(x => x.id == props.draftInputs.id);
        setDefaIndex(defaIndexVal);
        console.log("Index", defaIndex);
    }
    const [supplierEmail, setSupplierEmail] = useState([]);
    async function SupplierEmailDDL() {
        const response = await GetSupplierEmailDDl(token, userDetails.userId);
        if (response && response.length > 0) {
            setSupplierEmail(response);
        }
        else {
            setSupplierEmail([{
                id: 0,
                name: "No Options",
            },]);
        }
    }

    //var defaIndex = null;
    useEffect(() => {
        findDefSupplerMailIdx();
    }, [supplierEmail]);

    const [partNumber, setPartNumber] = useState([]);
    async function PartNumberDDL() {
        setIsLoader(true);
        const response = await GetPartDDl(token, userDetails.userId);
        if (response && response.length > 0) {
            setIsLoader(false);
            setPartNumber(response);
        } else {
            setPartNumber([
                {
                    id: 0,
                    name: "No Options",
                },
            ]);
        }
    }
    function onChangePartNumber(event, value) {
        const partDesc = partNumber.find((x) => x.id === value.id)?.partDesc;
        if (props?.rowData?.status === "Draft" || props?.rowData?.status === "Recall" || props?.rowData?.status === "Sent back to Siemens") {
            props.setDraftInputs({
                ...props.draftInputs,
                partId: value.id,
                partNumber: value.partNumber,
                partDescription: partDesc,
            })
        }
        else {
            props.setCreateComplaintInputs({
                ...props.createComplaintInputs,
                partNumber: value.id,
                partDescription: partDesc,
            });
        }
    }
    function onChangeSupplierEmail(event, value) {
        const supplierName = supplierEmail.find((x) => x.id === value.id)?.name;
        const CCEmails = supplierEmail.find((x) => x.id === value.id)?.ccEmails;
        const supplierCode = supplierEmail.find((x) => x.id === value.id)?.code;
        setCcOptions(CCEmails);
        if (props?.rowData?.status === "Draft" || props?.rowData?.status === "Recall" || (props?.rowData?.status === "Sent back to Siemens")) {
            props.setDraftInputs({
                ...props.draftInputs,
                id: value.id,
                supplierId: value.supplierId,
                supplierUserId: value.id,
                supplierName: supplierName,
                suppliernumber: supplierCode
            })
        }
        else {
            props.setCreateComplaintInputs({
                ...props.createComplaintInputs,
                id: value.id,
                supplierEmail: value.id,
                supplierId: value.supplierId,
                supplierUserId: value.id,
                supplierNameAuto: supplierName,
                supplierNumberAuto: supplierCode
            });
        }
    }
    const [disable, setDisable] = useState(true);
    function disableForm() {
        if (props?.rowData?.status === "Draft" || props?.rowData === "" || props?.rowData?.status === "Recall" || props?.rowData?.status === "Sent back to Siemens") {
            if (props?.rowData?.status === "Sent back to Siemens" && userDetails.roleId === 3) {
                setDisable(true);
            }
            else {
                setDisable(false);
            }
        }
        else {
            setDisable(true);
        }
    }
    useEffect(() => {
        console.log("Comp Deatil Props", props);
        if (props) {
            disableForm();
        }
        SupplierEmailDDL();
        PartNumberDDL();

    }, []);

    return (
        <>
            <div className="main-block">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={7} md={10}>
                        <h3>Non Conformity Notification</h3>
                    </Grid>
                    <Grid item className="date-id" xs={12} sm={5} md={2}>
                        <h4 style={{ background: "var(--btn-hero-bg-hover)" }}>
                            Date :{props?.rowData?.raiseDate ? moment(props?.rowData?.raiseDate).format("MM/DD/YYYY") : getTodayDate()}
                        </h4>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className="complaint-id">
                    <Grid item xs={12} sm={5} md={2}>
                        <h4 style={{ background: "var(--btn-hero-bg-hover)" }}>
                            Complaint Id : {
                                props.rowData?.status ?
                                    props.rowData?.name ?
                                        props.complaintDetails.complaintNumber :
                                        props.rowData?.complaintNumber :
                                    props.complaintDetails.complaintNumber
                            }
                        </h4>
                    </Grid>
                </Grid>

                <Grid container className="complaint-id"></Grid>
                <Grid>
                    <Grid className="mt-10">
                        <Grid container spacing={2} md={12}>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        Sender Name
                                    </InputLabel>
                                    <BootstrapInput value={
                                        props.rowData ? props.rowData?.senderName : userDetails.userFirstName
                                    } id="Sender-Name"
                                        name="senderName"
                                        disabled={disable}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl error={
                                    props.rowData
                                        ? props.rowData.status === "Draft" || props.rowData.status === "Recall"
                                            ? !(props.draftInputs.supplierId)
                                            : !(props.rowData.supplierId)
                                        : !(props.createComplaintInputs.supplierEmail)
                                } fullWidth>
                                    {/* <InputLabel shrink htmlFor="bootstrap-input">
                    {" "}
                    Supplier Email **
                  </InputLabel>
                  <Select
                    labelId="demo-customized-select-label"
                    id="supplierEmail"
                    name="supplierEmail"
                    disabled={disable}
                   value={
                  props.rowData
                  ?props.rowData.status==="Draft"||props.rowData.status==="Recall"
                  ?props.draftInputs.supplierId
                  :props.rowData.supplierId
                  :props.createComplaintInputs.supplierEmail
                } 
                    onChange={handleChangeSupplierInputs}
                    input={<BootstrapInput />}
                  >
                    {supplierEmail.map((item) => (
                      <MenuItem key={item.email} value={item.id}>
                        {item.email}
                      </MenuItem>
                    ))}
                  </Select> */}

                                    <EmailAutocomplete
                                        title="Supplier Email **"
                                        onChange={onChangeSupplierEmail}
                                        defaultValue={
                                            defaIndex
                                        }
                                        disable={disable}
                                    />

                                </FormControl>
                            </Grid>

                            <Grid className="autocomplete-pr" item xs={12} sm={6} md={3}>
                                <div style={{ padding: "2px" }} {...getRootProps()}>
                                    <Label {...getInputLabelProps()}>CC(Optional)</Label>
                                    <InputWrapper
                                        ref={setAnchorEl}
                                        className={focused ? "focused" : ""}
                                    >
                                        {value.map((option, index) => (
                                            <Tag label={option} {...getTagProps({ index })} />
                                        ))}

                                        <input disabled={disable} {...getInputProps()}
                                            onInputChange={handleChangeSupplierInputs} />
                                    </InputWrapper>
                                </div>
                                {groupedOptions.length > 0 ? (
                                    <Listbox {...getListboxProps()}>
                                        {groupedOptions.map((option, index) => (
                                            <li {...getOptionProps({ option, index })}>
                                                <span>{option}</span>
                                                <CheckIcon fontSize="small" />
                                            </li>
                                        ))}
                                    </Listbox>
                                ) : null}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className="view-block">
                    <Grid item xs={12} sm={6} md={3}>

                        <UseAutocomplete
                            title="Part Number **"
                            onChange={onChangePartNumber}
                            defaultValue={
                                props.rowData
                                    ? props.rowData.status === "Draft" || props.rowData.status === "Recall" ||
                                        (props?.rowData?.status === "Sent back to Siemens" && userDetails.roledId !== 3)
                                        ? props.draftInputs.partNo
                                        : { partId: props.rowData.partId, partNumber: props.rowData.partNumber }
                                    : props.createComplaintInputs.partId
                            }
                            disable={disable}
                        />

                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl error={
                            props.rowData
                                ? props.rowData.status === "Draft" || props.rowData.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                                    ? !(props.draftInputs.partDescription)
                                    : !(props.rowData.partDescription)
                                : !(props.createComplaintInputs.partDescription)
                        } fullWidth >
                            <InputLabel shrink htmlFor="bootstrap-input" name="partDescription" style={{ color: "#000028" }}>
                                Part Description **
                            </InputLabel>
                            <BootstrapInput
                                className="partdesc-top"
                                value={
                                    props.rowData
                                        ? props.rowData.status === "Draft" || props.rowData.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                                            ? props.draftInputs.partDescription
                                            : props.rowData.partDescription
                                        : props.createComplaintInputs.partDescription
                                }
                                multiline

                                disabled={disable}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl error={
                            props.rowData
                                ? props.rowData.status === "Draft" || props.rowData.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                                    ? !(props.draftInputs.supplierName)
                                    : !(props.rowData.supplierName)
                                : !(props.createComplaintInputs.supplierNameAuto)
                        } fullWidth >
                            <InputLabel shrink htmlFor="bootstrap-input" name="supplierNameAuto" style={{ color: "#000028" }}>
                                Supplier Name **
                            </InputLabel>
                            <BootstrapInput
                                value={
                                    props.rowData
                                        ? props.rowData.status === "Draft" || props.rowData.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                                            ? props.draftInputs.supplierName
                                            : props.rowData.supplierName
                                        : props.createComplaintInputs.supplierNameAuto
                                }
                                disabled={disable}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input" name="supplierNumberAuto">
                                Supplier Number
                            </InputLabel>
                            <BootstrapInput
                                value={
                                    props.rowData
                                        ? props.rowData.status === "Draft" || props.rowData.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                                            ? props.draftInputs.suppliernumber
                                            : props.rowData.suppliernumber
                                        : props.createComplaintInputs.supplierNumberAuto
                                }
                                disabled={disable}
                                id="bootstrap-input" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input"
                            >
                                Supplier PO
                            </InputLabel>
                            <BootstrapInput id="bootstrap-input" name="supplierPo"
                                type="text"
                                value={
                                    props.rowData
                                        ? props.rowData.status === "Draft" || props.rowData.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                                            ? props.draftInputs.supplierPo
                                            : props.rowData.supplierPo
                                        : props.createComplaintInputs.supplierPo
                                }
                                disabled={disable}
                                onChange={handleChangeSupplierInputs} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input"
                            >
                                Supplier InVoice
                            </InputLabel>
                            <BootstrapInput id="bootstrap-input" name="supplierInvoice"
                                value={
                                    props.rowData
                                        ? props.rowData.status === "Draft" || props.rowData.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                                            ? props.draftInputs.supplierInvoice
                                            : props.rowData.supplierInvoice
                                        : props.createComplaintInputs.supplierInvoice
                                }
                                disabled={disable}
                                onChange={handleChangeSupplierInputs} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input"

                            >
                                Delivered Quantity
                            </InputLabel>
                            <BootstrapInput id="bootstrap-input" name="deliveredQuantity"
                                type="number"
                                inputProps={{ min: 0 }}
                                disabled={disable}
                                value={
                                    props.rowData
                                        ? props.rowData.status === "Draft" || props.rowData.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                                            ? props.draftInputs.deliveredQty
                                            : props.rowData.deliveredQty
                                        : props.createComplaintInputs.deliveredQuantity
                                }
                                onChange={handleChangeSupplierInputs} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input"
                            >
                                Date Code/Batch/Lot
                            </InputLabel>
                            <BootstrapInput id="bootstrap-input" name="dateCodeBatchLot"
                                value={
                                    props.rowData
                                        ? props.rowData.status === "Draft" || props.rowData.status === "Recall" || props?.rowData?.status === "Sent back to Siemens"
                                            ? props.draftInputs.dateCodeBatchLot
                                            : props.rowData.dateCodeBatchLot
                                        : props.createComplaintInputs.dateCodeBatchLot
                                }
                                disabled={disable}
                                onChange={handleChangeSupplierInputs} />
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
            {isLoader ? <Loading loaderText="Loading...." /> : <></>}
        </>
    );
}
