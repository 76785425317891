import React,{useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { GetPartDDl, GetSupplierEmailDDl } from '../../Redux/APIs/api_Common';

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'block',
    padding: '0 0 4px',
    lineHeight: '1.5',
    fontSize: '12px'
  },
  input: {
        background:"#EBF7F8",
        fontSize: "14px",
        height: "42px",
        borderRadius: '4px',
        paddingTop:' 8px',
       paddingBottom: '1px',
        boxSizing: 'border-box',
        padding: "4px 6px",
        width: '100%',
        minWidth: '30px',
        flexGrow: '1',
        border: '1px solid #008AA6',
        margin: 0,
        outline: 0,
      
  },
  listbox: {
    width: 342,
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: '#D1FFF2',
    overflow: 'auto',
    maxHeight: 200,
    border: '1px solid rgba(0,0,0,.25)',
    '& li[data-focus="true"]': {
      backgroundColor: '#62EEC780',
      color: 'white',
      cursor: 'pointer',
    },
    '& li:active': {
      backgroundColor: '#EBF7F8',
      color: 'white',
    },
  },
}));

export default function EmailAutocomplete(props) {
    const staticData = [
        {
            "id": 20,
            "supplierId": 2,
            "email": "pavanironanki55@gmail.com",
            "name": "YSupplier",
            "code": "456",
            "partNumber": null,
            "partDesc": null,
            "ccEmails": [
                "saurabh.agarwal.ext@siemens.com",
                "bindu.nandigam.ext@siemens.com",
                "padmaraj.m.ext@siemens.com",
                "saurav.mazumder.ext@siemens.com",
                "abdulkadar.afraj.ext@siemens.com",
                "sowmya.bhat@siemens.com",
                "pavanironanki55@gmail.com",
                "dsa@df.cd",
                "test@gmail.com",
                "afrajkadar@gmail.com",
                "kj@hmm.vgt",
                "test@gmaial.cdg",
                "asd@fmd.cds",
                "test@gmail.chn",
                "vaishali.gadgil.ext@siemens.com",
                "nisha.bhatia.ext@siemens.com",
                "sachin.prabhat.ext@siemens.com",
                "yogesh.prajapati.ext@siemens.com",
                "sakshi.dabade.ext@siemens.com",
                "padaraj@test.cv",
                "asdas@af.vbn",
                "sgdvcasc@ads.cv",
                "clive.colaco@siemens.com",
                "ambika.annekkanavar@siemens.com",
                "sauravdiganta@gmail.com",
                "prajapati.yogesh991@gmail.com",
                "zaid.shirodkar@siemens.com",
                "so.bhat24@gmail.com"
            ]
        },
        {
            "id": 32,
            "supplierId": 2,
            "email": "asd@fmd.cds",
            "name": "YSupplier",
            "code": "456",
            "partNumber": null,
            "partDesc": null,
            "ccEmails": [
                "saurabh.agarwal.ext@siemens.com",
                "bindu.nandigam.ext@siemens.com",
                "padmaraj.m.ext@siemens.com",
                "saurav.mazumder.ext@siemens.com",
                "abdulkadar.afraj.ext@siemens.com",
                "sowmya.bhat@siemens.com",
                "pavanironanki55@gmail.com",
                "dsa@df.cd",
                "test@gmail.com",
                "afrajkadar@gmail.com",
                "kj@hmm.vgt",
                "test@gmaial.cdg",
                "asd@fmd.cds",
                "test@gmail.chn",
                "vaishali.gadgil.ext@siemens.com",
                "nisha.bhatia.ext@siemens.com",
                "sachin.prabhat.ext@siemens.com",
                "yogesh.prajapati.ext@siemens.com",
                "sakshi.dabade.ext@siemens.com",
                "padaraj@test.cv",
                "asdas@af.vbn",
                "sgdvcasc@ads.cv",
                "clive.colaco@siemens.com",
                "ambika.annekkanavar@siemens.com",
                "sauravdiganta@gmail.com",
                "prajapati.yogesh991@gmail.com",
                "zaid.shirodkar@siemens.com",
                "so.bhat24@gmail.com"
            ]
        },
        {
            "id": 38,
            "supplierId": 1,
            "email": "vaishu4388@gmail.com",
            "name": "ABCSupplier",
            "code": "123",
            "partNumber": null,
            "partDesc": null,
            "ccEmails": [
                "saurabh.agarwal.ext@siemens.com",
                "bindu.nandigam.ext@siemens.com",
                "padmaraj.m.ext@siemens.com",
                "saurav.mazumder.ext@siemens.com",
                "abdulkadar.afraj.ext@siemens.com",
                "sowmya.bhat@siemens.com",
                "dsa@df.cd",
                "test@gmail.com",
                "afrajkadar@gmail.com",
                "kj@hmm.vgt",
                "test@gmaial.cdg",
                "test@gmail.chn",
                "vaishali.gadgil.ext@siemens.com",
                "nisha.bhatia.ext@siemens.com",
                "sachin.prabhat.ext@siemens.com",
                "vaishu4388@gmail.com",
                "yogesh.prajapati.ext@siemens.com",
                "sakshi.dabade.ext@siemens.com",
                "padaraj@test.cv",
                "asdas@af.vbn",
                "sgdvcasc@ads.cv",
                "clive.colaco@siemens.com",
                "ambika.annekkanavar@siemens.com",
                "sauravdiganta@gmail.com",
                "test@test.cb",
                "djvcs!@jhb.cv",
                "prajapati.yogesh991@gmail.com",
                "zaid.shirodkar@siemens.com",
                "so.bhat24@gmail.com"
            ]
        },
        {
            "id": 50,
            "supplierId": 7,
            "email": "smselvam@gt-electronicindia.com",
            "name": "GT Electronic (India) Pvt. Ltd.",
            "code": "50187171",
            "partNumber": null,
            "partDesc": null,
            "ccEmails": [
                "saurabh.agarwal.ext@siemens.com",
                "bindu.nandigam.ext@siemens.com",
                "padmaraj.m.ext@siemens.com",
                "saurav.mazumder.ext@siemens.com",
                "abdulkadar.afraj.ext@siemens.com",
                "sowmya.bhat@siemens.com",
                "dsa@df.cd",
                "test@gmail.com",
                "afrajkadar@gmail.com",
                "kj@hmm.vgt",
                "test@gmaial.cdg",
                "test@gmail.chn",
                "vaishali.gadgil.ext@siemens.com",
                "nisha.bhatia.ext@siemens.com",
                "sachin.prabhat.ext@siemens.com",
                "yogesh.prajapati.ext@siemens.com",
                "sakshi.dabade.ext@siemens.com",
                "smselvam@gt-electronicindia.com",
                "padaraj@test.cv",
                "asdas@af.vbn",
                "sgdvcasc@ads.cv",
                "clive.colaco@siemens.com",
                "ambika.annekkanavar@siemens.com",
                "sauravdiganta@gmail.com",
                "saravanan@gt-electronicindia.com",
                "prajapati.yogesh991@gmail.com",
                "zaid.shirodkar@siemens.com",
                "so.bhat24@gmail.com"
            ]
        },
        {
            "id": 51,
            "supplierId": 40,
            "email": "qc@imagelabels.com",
            "name": "Image Labels Pvt. Ltd.",
            "code": "50168571",
            "partNumber": null,
            "partDesc": null,
            "ccEmails": [
                "saurabh.agarwal.ext@siemens.com",
                "bindu.nandigam.ext@siemens.com",
                "padmaraj.m.ext@siemens.com",
                "saurav.mazumder.ext@siemens.com",
                "abdulkadar.afraj.ext@siemens.com",
                "sowmya.bhat@siemens.com",
                "dsa@df.cd",
                "test@gmail.com",
                "afrajkadar@gmail.com",
                "kj@hmm.vgt",
                "test@gmaial.cdg",
                "test@gmail.chn",
                "vaishali.gadgil.ext@siemens.com",
                "nisha.bhatia.ext@siemens.com",
                "sachin.prabhat.ext@siemens.com",
                "yogesh.prajapati.ext@siemens.com",
                "sakshi.dabade.ext@siemens.com",
                "qc@imagelabels.com",
                "padaraj@test.cv",
                "asdas@af.vbn",
                "sgdvcasc@ads.cv",
                "clive.colaco@siemens.com",
                "ambika.annekkanavar@siemens.com",
                "sauravdiganta@gmail.com",
                "prajapati.yogesh991@gmail.com",
                "zaid.shirodkar@siemens.com",
                "so.bhat24@gmail.com"
            ]
        },
        {
            "id": 53,
            "supplierId": 18,
            "email": "quality@srilakshmitools.in",
            "name": "Sri Lakshmi Tools",
            "code": "50204712",
            "partNumber": null,
            "partDesc": null,
            "ccEmails": [
                "saurabh.agarwal.ext@siemens.com",
                "bindu.nandigam.ext@siemens.com",
                "padmaraj.m.ext@siemens.com",
                "saurav.mazumder.ext@siemens.com",
                "abdulkadar.afraj.ext@siemens.com",
                "sowmya.bhat@siemens.com",
                "dsa@df.cd",
                "test@gmail.com",
                "afrajkadar@gmail.com",
                "kj@hmm.vgt",
                "test@gmaial.cdg",
                "test@gmail.chn",
                "vaishali.gadgil.ext@siemens.com",
                "nisha.bhatia.ext@siemens.com",
                "sachin.prabhat.ext@siemens.com",
                "yogesh.prajapati.ext@siemens.com",
                "sakshi.dabade.ext@siemens.com",
                "quality@srilakshmitools.in",
                "padaraj@test.cv",
                "asdas@af.vbn",
                "sgdvcasc@ads.cv",
                "clive.colaco@siemens.com",
                "ambika.annekkanavar@siemens.com",
                "sauravdiganta@gmail.com",
                "srilakshmitools@gmail.com",
                "prajapati.yogesh991@gmail.com",
                "zaid.shirodkar@siemens.com",
                "so.bhat24@gmail.com"
            ]
        },
        {
            "id": 62,
            "supplierId": 2,
            "email": "asdas@af.vbn",
            "name": "YSupplier",
            "code": "456",
            "partNumber": null,
            "partDesc": null,
            "ccEmails": [
                "saurabh.agarwal.ext@siemens.com",
                "bindu.nandigam.ext@siemens.com",
                "padmaraj.m.ext@siemens.com",
                "saurav.mazumder.ext@siemens.com",
                "abdulkadar.afraj.ext@siemens.com",
                "sowmya.bhat@siemens.com",
                "pavanironanki55@gmail.com",
                "dsa@df.cd",
                "test@gmail.com",
                "afrajkadar@gmail.com",
                "kj@hmm.vgt",
                "test@gmaial.cdg",
                "asd@fmd.cds",
                "test@gmail.chn",
                "vaishali.gadgil.ext@siemens.com",
                "nisha.bhatia.ext@siemens.com",
                "sachin.prabhat.ext@siemens.com",
                "yogesh.prajapati.ext@siemens.com",
                "sakshi.dabade.ext@siemens.com",
                "padaraj@test.cv",
                "asdas@af.vbn",
                "sgdvcasc@ads.cv",
                "clive.colaco@siemens.com",
                "ambika.annekkanavar@siemens.com",
                "sauravdiganta@gmail.com",
                "prajapati.yogesh991@gmail.com",
                "zaid.shirodkar@siemens.com",
                "so.bhat24@gmail.com"
            ]
        },
        {
            "id": 64,
            "supplierId": 27,
            "email": "sakshisureshdabade@gmail.com",
            "name": "Advent Electronics",
            "code": "50223346",
            "partNumber": null,
            "partDesc": null,
            "ccEmails": [
                "saurabh.agarwal.ext@siemens.com",
                "bindu.nandigam.ext@siemens.com",
                "padmaraj.m.ext@siemens.com",
                "saurav.mazumder.ext@siemens.com",
                "abdulkadar.afraj.ext@siemens.com",
                "sowmya.bhat@siemens.com",
                "dsa@df.cd",
                "test@gmail.com",
                "afrajkadar@gmail.com",
                "kj@hmm.vgt",
                "test@gmaial.cdg",
                "test@gmail.chn",
                "vaishali.gadgil.ext@siemens.com",
                "nisha.bhatia.ext@siemens.com",
                "sachin.prabhat.ext@siemens.com",
                "yogesh.prajapati.ext@siemens.com",
                "sakshi.dabade.ext@siemens.com",
                "padaraj@test.cv",
                "asdas@af.vbn",
                "sgdvcasc@ads.cv",
                "sakshisureshdabade@gmail.com",
                "clive.colaco@siemens.com",
                "ambika.annekkanavar@siemens.com",
                "sauravdiganta@gmail.com",
                "prajapati.yogesh991@gmail.com",
                "zaid.shirodkar@siemens.com",
                "so.bhat24@gmail.com"
            ]
        },
        {
            "id": 73,
            "supplierId": 18,
            "email": "sauravdiganta@gmail.com",
            "name": "Sri Lakshmi Tools",
            "code": "50204712",
            "partNumber": null,
            "partDesc": null,
            "ccEmails": [
                "saurabh.agarwal.ext@siemens.com",
                "bindu.nandigam.ext@siemens.com",
                "padmaraj.m.ext@siemens.com",
                "saurav.mazumder.ext@siemens.com",
                "abdulkadar.afraj.ext@siemens.com",
                "sowmya.bhat@siemens.com",
                "dsa@df.cd",
                "test@gmail.com",
                "afrajkadar@gmail.com",
                "kj@hmm.vgt",
                "test@gmaial.cdg",
                "test@gmail.chn",
                "vaishali.gadgil.ext@siemens.com",
                "nisha.bhatia.ext@siemens.com",
                "sachin.prabhat.ext@siemens.com",
                "yogesh.prajapati.ext@siemens.com",
                "sakshi.dabade.ext@siemens.com",
                "quality@srilakshmitools.in",
                "padaraj@test.cv",
                "asdas@af.vbn",
                "sgdvcasc@ads.cv",
                "clive.colaco@siemens.com",
                "ambika.annekkanavar@siemens.com",
                "sauravdiganta@gmail.com",
                "srilakshmitools@gmail.com",
                "prajapati.yogesh991@gmail.com",
                "zaid.shirodkar@siemens.com",
                "so.bhat24@gmail.com"
            ]
        },
        {
            "id": 74,
            "supplierId": 1,
            "email": "test@test.cb",
            "name": "ABCSupplier",
            "code": "123",
            "partNumber": null,
            "partDesc": null,
            "ccEmails": [
                "saurabh.agarwal.ext@siemens.com",
                "bindu.nandigam.ext@siemens.com",
                "padmaraj.m.ext@siemens.com",
                "saurav.mazumder.ext@siemens.com",
                "abdulkadar.afraj.ext@siemens.com",
                "sowmya.bhat@siemens.com",
                "dsa@df.cd",
                "test@gmail.com",
                "afrajkadar@gmail.com",
                "kj@hmm.vgt",
                "test@gmaial.cdg",
                "test@gmail.chn",
                "vaishali.gadgil.ext@siemens.com",
                "nisha.bhatia.ext@siemens.com",
                "sachin.prabhat.ext@siemens.com",
                "vaishu4388@gmail.com",
                "yogesh.prajapati.ext@siemens.com",
                "sakshi.dabade.ext@siemens.com",
                "padaraj@test.cv",
                "asdas@af.vbn",
                "sgdvcasc@ads.cv",
                "clive.colaco@siemens.com",
                "ambika.annekkanavar@siemens.com",
                "sauravdiganta@gmail.com",
                "test@test.cb",
                "djvcs!@jhb.cv",
                "prajapati.yogesh991@gmail.com",
                "zaid.shirodkar@siemens.com",
                "so.bhat24@gmail.com"
            ]
        },
        {
            "id": 75,
            "supplierId": 1,
            "email": "djvcs!@jhb.cv",
            "name": "ABCSupplier",
            "code": "123",
            "partNumber": null,
            "partDesc": null,
            "ccEmails": [
                "saurabh.agarwal.ext@siemens.com",
                "bindu.nandigam.ext@siemens.com",
                "padmaraj.m.ext@siemens.com",
                "saurav.mazumder.ext@siemens.com",
                "abdulkadar.afraj.ext@siemens.com",
                "sowmya.bhat@siemens.com",
                "dsa@df.cd",
                "test@gmail.com",
                "afrajkadar@gmail.com",
                "kj@hmm.vgt",
                "test@gmaial.cdg",
                "test@gmail.chn",
                "vaishali.gadgil.ext@siemens.com",
                "nisha.bhatia.ext@siemens.com",
                "sachin.prabhat.ext@siemens.com",
                "vaishu4388@gmail.com",
                "yogesh.prajapati.ext@siemens.com",
                "sakshi.dabade.ext@siemens.com",
                "padaraj@test.cv",
                "asdas@af.vbn",
                "sgdvcasc@ads.cv",
                "clive.colaco@siemens.com",
                "ambika.annekkanavar@siemens.com",
                "sauravdiganta@gmail.com",
                "test@test.cb",
                "djvcs!@jhb.cv",
                "prajapati.yogesh991@gmail.com",
                "zaid.shirodkar@siemens.com",
                "so.bhat24@gmail.com"
            ]
        },
        {
            "id": 76,
            "supplierId": 7,
            "email": "saravanan@gt-electronicindia.com",
            "name": "GT Electronic (India) Pvt. Ltd.",
            "code": "50187171",
            "partNumber": null,
            "partDesc": null,
            "ccEmails": [
                "saurabh.agarwal.ext@siemens.com",
                "bindu.nandigam.ext@siemens.com",
                "padmaraj.m.ext@siemens.com",
                "saurav.mazumder.ext@siemens.com",
                "abdulkadar.afraj.ext@siemens.com",
                "sowmya.bhat@siemens.com",
                "dsa@df.cd",
                "test@gmail.com",
                "afrajkadar@gmail.com",
                "kj@hmm.vgt",
                "test@gmaial.cdg",
                "test@gmail.chn",
                "vaishali.gadgil.ext@siemens.com",
                "nisha.bhatia.ext@siemens.com",
                "sachin.prabhat.ext@siemens.com",
                "yogesh.prajapati.ext@siemens.com",
                "sakshi.dabade.ext@siemens.com",
                "smselvam@gt-electronicindia.com",
                "padaraj@test.cv",
                "asdas@af.vbn",
                "sgdvcasc@ads.cv",
                "clive.colaco@siemens.com",
                "ambika.annekkanavar@siemens.com",
                "sauravdiganta@gmail.com",
                "saravanan@gt-electronicindia.com",
                "prajapati.yogesh991@gmail.com",
                "zaid.shirodkar@siemens.com",
                "so.bhat24@gmail.com"
            ]
        },
        {
            "id": 77,
            "supplierId": 18,
            "email": "srilakshmitools@gmail.com",
            "name": "Sri Lakshmi Tools",
            "code": "50204712",
            "partNumber": null,
            "partDesc": null,
            "ccEmails": [
                "saurabh.agarwal.ext@siemens.com",
                "bindu.nandigam.ext@siemens.com",
                "padmaraj.m.ext@siemens.com",
                "saurav.mazumder.ext@siemens.com",
                "abdulkadar.afraj.ext@siemens.com",
                "sowmya.bhat@siemens.com",
                "dsa@df.cd",
                "test@gmail.com",
                "afrajkadar@gmail.com",
                "kj@hmm.vgt",
                "test@gmaial.cdg",
                "test@gmail.chn",
                "vaishali.gadgil.ext@siemens.com",
                "nisha.bhatia.ext@siemens.com",
                "sachin.prabhat.ext@siemens.com",
                "yogesh.prajapati.ext@siemens.com",
                "sakshi.dabade.ext@siemens.com",
                "quality@srilakshmitools.in",
                "padaraj@test.cv",
                "asdas@af.vbn",
                "sgdvcasc@ads.cv",
                "clive.colaco@siemens.com",
                "ambika.annekkanavar@siemens.com",
                "sauravdiganta@gmail.com",
                "srilakshmitools@gmail.com",
                "prajapati.yogesh991@gmail.com",
                "zaid.shirodkar@siemens.com",
                "so.bhat24@gmail.com"
            ]
        },
        {
            "id": 90,
            "supplierId": 2,
            "email": "prajapati.yogesh991@gmail.com",
            "name": "YSupplier",
            "code": "456",
            "partNumber": null,
            "partDesc": null,
            "ccEmails": [
                "saurabh.agarwal.ext@siemens.com",
                "bindu.nandigam.ext@siemens.com",
                "padmaraj.m.ext@siemens.com",
                "saurav.mazumder.ext@siemens.com",
                "abdulkadar.afraj.ext@siemens.com",
                "sowmya.bhat@siemens.com",
                "pavanironanki55@gmail.com",
                "dsa@df.cd",
                "test@gmail.com",
                "afrajkadar@gmail.com",
                "kj@hmm.vgt",
                "test@gmaial.cdg",
                "asd@fmd.cds",
                "test@gmail.chn",
                "vaishali.gadgil.ext@siemens.com",
                "nisha.bhatia.ext@siemens.com",
                "sachin.prabhat.ext@siemens.com",
                "yogesh.prajapati.ext@siemens.com",
                "sakshi.dabade.ext@siemens.com",
                "padaraj@test.cv",
                "asdas@af.vbn",
                "sgdvcasc@ads.cv",
                "clive.colaco@siemens.com",
                "ambika.annekkanavar@siemens.com",
                "sauravdiganta@gmail.com",
                "prajapati.yogesh991@gmail.com",
                "zaid.shirodkar@siemens.com",
                "so.bhat24@gmail.com"
            ]
        },
        {
            "id": 94,
            "supplierId": 2,
            "email": "so.bhat24@gmail.com",
            "name": "YSupplier",
            "code": "456",
            "partNumber": null,
            "partDesc": null,
            "ccEmails": [
                "saurabh.agarwal.ext@siemens.com",
                "bindu.nandigam.ext@siemens.com",
                "padmaraj.m.ext@siemens.com",
                "saurav.mazumder.ext@siemens.com",
                "abdulkadar.afraj.ext@siemens.com",
                "sowmya.bhat@siemens.com",
                "pavanironanki55@gmail.com",
                "dsa@df.cd",
                "test@gmail.com",
                "afrajkadar@gmail.com",
                "kj@hmm.vgt",
                "test@gmaial.cdg",
                "asd@fmd.cds",
                "test@gmail.chn",
                "vaishali.gadgil.ext@siemens.com",
                "nisha.bhatia.ext@siemens.com",
                "sachin.prabhat.ext@siemens.com",
                "yogesh.prajapati.ext@siemens.com",
                "sakshi.dabade.ext@siemens.com",
                "padaraj@test.cv",
                "asdas@af.vbn",
                "sgdvcasc@ads.cv",
                "clive.colaco@siemens.com",
                "ambika.annekkanavar@siemens.com",
                "sauravdiganta@gmail.com",
                "prajapati.yogesh991@gmail.com",
                "zaid.shirodkar@siemens.com",
                "so.bhat24@gmail.com"
            ]
        }
    ]
    const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
    );
    const suppMailIndex = useSelector((state) => state.supplierMailValActionReducer);
    const storedSupplierMailArr = useSelector((state) => state.supplierEmailArrReducer);
    const [partNumber, setPartNumber] = useState([]);
  async function PartNumberDDL() {
    const response = await GetPartDDl(token, userDetails.userId);
    if (response && response.length > 0) {
      setPartNumber(response);
    } else {
      setPartNumber([
        {
          id: 0,
          name: "No Options",
        },
      ]);
    }
  }


   

  // new
  const [supplierEmail, setSupplierEmail] = useState([]);
  async function SupplierEmailDDL() {
    const response = await GetSupplierEmailDDl(token, userDetails.userId);
      if (response && response.length > 0) {
      console.log("Supp Email Arr", response);
      setSupplierEmail(response);
    }
     else {
      setSupplierEmail([ {
        id: 0,
        name: "No Options",
      },]);
    }
  }
    var defaIndex = 7;
    const [emailDefaultVal, setEmailDefaultVal] = useState(null);
  const classes = useStyles();
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
      id: 'use-autocomplete-demo',
      options: supplierEmail,
      defaultValue: storedSupplierMailArr[parseInt(suppMailIndex)],
    getOptionLabel: (option) => option.email,
    onChange:props.onChange,
  });
    

    function findDefSupplerMailIdx() {
        defaIndex = supplierEmail.findIndex(x => x.id == props.defaultValue);
        console.log("Index", defaIndex);
    }
    

    //useEffect(() => {
    //    findDefSupplerMailIdx();
    //}, [supplierEmail]);

    useEffect(() => {
        SupplierEmailDDL();
        console.log("Mail props", suppMailIndex);
        console.log("storedSupplierMailArr", storedSupplierMailArr);
        setEmailDefaultVal(parseInt(suppMailIndex));
    }, []);

  return (
    <div>
      <div style={{ padding: "2px" }} {...getRootProps()}>
        <label className={classes.label} {...getInputLabelProps()}>
          Supplier Email **
        </label>
        <input disabled={props.disable}  className={classes.input} {...getInputProps()} />
      </div>
      {groupedOptions.length > 0 ? (
        <ul className={classes.listbox} {...getListboxProps()} name="supplierEmail">
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option, index })}>{option.email}</li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}


