import { baseApiURL } from "../../Utilities/Utility";
import axios from "axios";


export function GetAttachmentDetails(siteToken, userId, complaintNumber) {
    const options = {
        headers: { Authorization: "Bearer " + siteToken },
        params: { userId: userId, complaintNumber: complaintNumber },
    };
    return axios.get(
        baseApiURL + "/Admin/GetAttachmentsDetails/",
        options
    )
        .then((response) => {
            return response.data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function AddAttachmentDetails(siteToken, formData) {
    const options = {
        method: "post",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken,
        },
        body: formData,
    };
    return fetch(baseApiURL + "/Admin/UploadMultiple", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function DeleteAttachmentDetails(siteToken, formData) {

    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(formData),
    };
    return fetch(baseApiURL + "/Admin/DeleteAttachments", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function DeleteDisciplineAttachments(siteToken, attachmentId, userId) {
    const options = {
        method: "put",
        url: baseApiURL + "/Discipline/DeleteAttachments",
        headers: {
            Authorization: "Bearer " + siteToken,
        },
        params: { userId: userId, attachmentId: attachmentId },
    };
    return (
        axios(options)
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}
export function AddSnapShotDetails(siteToken, formData) {
    const options = {
        method: "post",
        mode: "cors",
        headers: {
            Authorization: "Bearer " + siteToken,
        },
        body: formData,
    };
    return fetch(baseApiURL + "/Admin/UploadSnapshot", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function GetNonConformityDetails(siteToken, userId) {
    const options = {
        method: "get",
        url: baseApiURL + "/Admin/GetAllNonConformities",
        headers: {
            Authorization: "Bearer " + siteToken,
        },
        params: { userId: userId },
    };
    return (
        axios(options)
            .then((response) => {
                return response.data.result.objResult;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}

export function PostComplaintDetails(siteToken, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PostNotification", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function GetComplaintDetails(siteToken, userId) {
    const options = {
        method: "get",
        headers: { Authorization: "Bearer " + siteToken },
    };
    return fetch(
        baseApiURL + "/Admin/GetAllComplaints?userId=" + userId,
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function GetComplaintDetailsBySupplier(siteToken, supplierId) {
    const options = {
        method: "get",
        headers: { Authorization: "Bearer " + siteToken },
    };
    return fetch(
        baseApiURL + "/Admin/GetComplaintsbySupplier?supplierId=" + supplierId,
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function GetComplaintDetailsByComponent(siteToken, userId) {
    const options = {
        method: "get",
        headers: { Authorization: "Bearer " + siteToken },
    };
    return fetch(
        baseApiURL + "/Admin/GetComponentComplaints?userId=" + userId,
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function PostDescriptionDetails(siteToken, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PostDescriptionDetails", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function SaveDraft(siteToken, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/SaveDraft", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function DownloadFile(siteToken, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        responseType: 'arraybuffer',
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/downloadfile/", options)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function GetSnapShotDetails(siteToken, userId, complaintNumber) {
    const options = {
        headers: { Authorization: "Bearer " + siteToken },
        params: { userId: userId, complaintNumber: complaintNumber },
    };
    return axios.get(
        baseApiURL + "/Admin/GetSnapshotView/",
        options
    )
        .then((response) => {
            return response.data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function GetKPIDetails(siteToken, fromDate, toDate, userId, supplierId) {
    const options = {
        method: "get",
        url: baseApiURL + "/Admin/GetKPI'sDetails",
        headers: {
            Authorization: "Bearer " + siteToken,
        },
        params: {
            userId: userId,
            supplierId: supplierId,
            fromDate: fromDate,
            toDate: toDate
        },
    };
    return (
        axios(options)
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}