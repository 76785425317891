import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../Screens/Home/Home";
import UserManagement from "../Screens/Administrator/UserManagement/UserManagement";
import OtherRole from "../Screens/Administrator/UserManagement/OtherRole";
import ComplaintsList from "../Screens/Complaints/ComplaintsList/ComplaintsList";
import CreateComplaintRoot from "../Screens/Complaints/CreateComplaintRoot";
import Complaint from "../Screens/Complaints/Complaints";
import Report8D from "../Screens/8DReport/8DReportRoot";
import Admin from "../Screens/Administrator/Admin";
import MasterFiles from "../Screens/Administrator/MasterTables/MasterFiles";
import ChangePassword from "../Screens/ResetPassword/ChangePassword";
import Reports from "../Screens/Reports/Reports";
import ContactUs from "../Screens/ContactUs/ContactUs";
import Isirhm from "../Screens/ISIRHomePage/Isirhm";
import AddIsir from "../Screens/ADDISIR/AddlSir";
import NewDownload from "../Screens/ADDISIR/NewDownload";


import Epwork from "../Screens/Listisir/Epwork";

import AddIsirRoot from "../Screens/ADDISIR/AddIsirRoot";
import AttachmentMaster from "../Screens/Administrator/MasterTables/MasterTabs/AttachmentMaster";
export const MasterRoute = (
    <Routes>
       
        <Route path="/signin-oidc*" element={<Navigate to="/" />} />
        <Route path="/signin-oidc#code=*" element={<Navigate to="/" />} />
        <Route exact path="/" element={<Home />} />
        <Route exact path="/complaint" element={<Complaint />} />
        <Route exact path="/complaintsList" element={<ComplaintsList />} />
        <Route exact path="/reports" element={<Reports />} />
        <Route exact path="/userManagement" element={<UserManagement />} />
        <Route exact path="/otherRole" element={<OtherRole/>} />
        <Route exact path="/createComplaint" element={<CreateComplaintRoot />} />
        <Route exact path="/admin" element={<Admin />} />
        <Route exact path="/report8D" element={<Report8D />} />
        <Route exact path="/masterTables" element={<MasterFiles />} />
        <Route exact path="/masterAttachments" element={<AttachmentMaster />} />
        <Route exact path="/changePassword" element={<ChangePassword />} />
        <Route exact path="/contactus" element={<ContactUs />} />
        <Route exact path="/Isirhm" element={<Isirhm />} />
        <Route exact path="/AddIsir" element={<AddIsir />} />
        <Route exact path="/ISIRdownloadfile" element={<NewDownload />} />
        <Route exact path="/Epwork" element={<Epwork />} />
        <Route exact path="/AddIsirRoot" element={<AddIsirRoot />} />
    </Routes>
);
