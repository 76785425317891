
import React,{useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useSelector,useDispatch } from "react-redux";
import Loading from "../../Components/Loader/Loader";
import { AppBar, Tabs, Tab, Box ,Grid} from '@material-ui/core';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Discipline1 from "./Disciplines/Discipline1";
import Discipline2 from "./Disciplines/Discipline2";
import Discipline3 from './Disciplines/Discipline3';
import Discipline5 from './Disciplines/Discipline5';
import Discipline4 from './Disciplines/Discipline4';
import Discipline6 from './Disciplines/Discipline6';
import Discipline7 from './Disciplines/Discipline7';
import Discipline8 from './Disciplines/Discipline8';
import { GetD1Details, GetD2Details, GetD3Details, GetD4Details, GetD4WhyDetails, GetD4WhyNotDetails, GetD5Section1Details, GetD5Section2Details, GetD6Details, GetD6TextDetails, GetD7Details, GetD7TextDetails, GetD8Details } from '../../Redux/APIs/api_Disciplines';
import { disciplineAction } from '../../Redux/Actions/actions';
import { BootstrapInput } from '../../Utilities/Utility';
import moment from 'moment';

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    pdBt:{
      paddingBottom:"20px"
    }
}));

export default function Report8D() {
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const dispatch=useDispatch();
    const discplineData= useSelector(
      (state) => state.disciplineReducer.disciplineData
  );
    const [loader,setIsLoader]=useState(false);
    const location = useLocation();
    const rowData= useState(location?.state?.rowData?location.state.rowData:"");
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
    setValue(newValue);
    };
    const [d4UpdateResponse,setd4UpdateResponse]=useState(null);   
    const [d8UpdateResponse,setd8UpdateResponse]=useState(null);
    const [d61UpdateResponse,setd61UpdateResponse]=useState(null);   
    const [d71UpdateResponse,setd71UpdateResponse]=useState(null);
    const [response,setResponse]=useState([]);
    async function getTableDataD1() {
      setIsLoader(true);
        var complaintNum=rowData[0].complaintNumber;
       const response = await   GetD1Details(token,complaintNum,userDetails.userId)
            if(response.length>0){
             response.isDraft=true 
            }
            setIsLoader(false);
      }
      async function getTableDataD3() {
        var flag=true;
        setIsLoader(true);
        var complaintNum=rowData[0].complaintNumber;
        const response = await GetD3Details(token,complaintNum,userDetails.userId)
        // if(response.length>0){
        //  response.isDraft=true 
        //   discplineData.discipline3=true;
        //   dispatch(disciplineAction(discplineData));
        // }
        if(response.result.length>0){
          discplineData.discipline3=true;
          for(var i=0;i<response.result.length;i++){
           var obj=response.result[i];
           if(obj?.statusId!==2){
             flag=false;
          }
          }  
          if(flag===true){ 
          discplineData.disciplineStatus3=true;
          }
          else{
            discplineData.disciplineStatus3=false;
          }
          dispatch(disciplineAction(discplineData));
        }
        setIsLoader(false);
      }
      async function getTableDataD4Why() {
        setIsLoader(true);
        var complaintNum=rowData[0].complaintNumber;
        const response = await GetD4WhyDetails(token,complaintNum,userDetails.userId)
        if(response.length>1){
         response.isDraft=true 
          discplineData.discipline41=true;
          dispatch(disciplineAction(discplineData));
        }
        setIsLoader(false);
      }
      async function getTableDataD4WhyNot() {
        setIsLoader(true);
        var complaintNum=rowData[0].complaintNumber;
        const response = await GetD4WhyNotDetails(token,complaintNum,userDetails.userId)
        if(response.length>1){
         response.isDraft=true ;
          discplineData.discipline42=true;
          dispatch(disciplineAction(discplineData));
        }
        setIsLoader(false);
      }
     async function getTableDataD5Sec1() {
      var flag=true;
      setIsLoader(true);
        var complaintNum=rowData[0].complaintNumber;
        const response = await GetD5Section1Details(token,complaintNum,userDetails.userId)
        // if(response.length>0){
        //  response.isDraft=true 
        //   discplineData.discipline5=true;
        //   dispatch(disciplineAction(discplineData));
        // }
        if(response.result.length>0){
          discplineData.discipline5=true;
          for(var i=0;i<response.result.length;i++){
           var obj=response.result[i];
           if(obj?.statusId!==2){
             flag=false;
          }
          }  
          if(flag===true){ 
          discplineData.disciplineStatus51=true;
          }
          else{
            discplineData.disciplineStatus51=false;
          }
          dispatch(disciplineAction(discplineData));
        }
        setIsLoader(false);
      }
     async function getTableDataD5Sec2() {
      var flag=true;
      setIsLoader(true);
        var complaintNum=rowData[0].complaintNumber;
        const response = await GetD5Section2Details(token,complaintNum,userDetails.userId)
        // if(response.length>0){
        //  response.isDraft=true
        //   discplineData.discipline51=true;
        //   dispatch(disciplineAction(discplineData));
        // }
        if(response.result.length>0){
          discplineData.discipline51=true;
          for(var i=0;i<response.result.length;i++){
           var obj=response.result[i];
           if(obj?.statusId!==2){
             flag=false;
          }
          }  
          if(flag===true){ 
          discplineData.disciplineStatus52=true;
          }
          else{
            discplineData.disciplineStatus52=false;
          }
          dispatch(disciplineAction(discplineData));
        }
        setIsLoader(false);
      }
     async  function getTableDataD6() {
      var flag=true;
      setIsLoader(true);
        var complaintNum=rowData[0].complaintNumber;
        const response = await GetD6Details(token,complaintNum,userDetails.userId)
        // if(response.length>0){
        //  response.isDraft=true 
        //   discplineData.discipline6=true;
        //   dispatch(disciplineAction(discplineData));
        // }
        if(response.result.length>0){
          discplineData.discipline6=true;
          for(var i=0;i<response.result.length;i++){
           var obj=response.result[i];
           if(obj?.statusId!==2){
             flag=false;
          }
          }  
          if(flag===true){ 
          discplineData.disciplineStatus6=true;
          }
          else{
            discplineData.disciplineStatus6=false;
          }
          dispatch(disciplineAction(discplineData));
        }
        setIsLoader(false);
      }
      async function getTableDataD7() {
        var flag=true;
        setIsLoader(true);
        var complaintNum=rowData[0].complaintNumber;
        const response = await GetD7Details(token,complaintNum,userDetails.userId)
        // if(response.length>0){
        //  response.isDraft=true 
        //   discplineData.discipline7=true;
        //   dispatch(disciplineAction(discplineData));
        // }
        if(response.result.length>0){
          discplineData.discipline7=true;
          for(var i=0;i<response.result.length;i++){
           var obj=response.result[i];
           if(obj?.statusId!==2){
             flag=false;
          }
          }  
          if(flag===true){ 
          discplineData.disciplineStatus7=true;
          }
          else{
            discplineData.disciplineStatus7=false;
          }
          dispatch(disciplineAction(discplineData));
        }
        setIsLoader(false);
      }

   async function getD4Details() {
    setIsLoader(true);
     GetD4Details(token,rowData[0].complaintNumber,userDetails.userId)
    .then((response) => {
        if(response.length>0){
           setd4UpdateResponse(response);
          discplineData.discipline4=true;
          dispatch(disciplineAction(discplineData));
          }
      setResponse(response);
      setIsLoader(false);
    })
    .catch((error) => {
        console.log(error)
        setIsLoader(false);
    });
};
const [d6Details,setD6Details]=useState([]);
const [d7Details,setD7Details]=useState([]);
async function getD6SaveDetails() {
  setIsLoader(true);
   GetD6TextDetails(token,rowData[0].complaintNumber,userDetails.userId)
  .then((response) => {
      if(response.length>0){
         setd61UpdateResponse(response);
        discplineData.discipline61=true;
        dispatch(disciplineAction(discplineData));
        }
    setD6Details(response);
    setIsLoader(false);
  })
  .catch((error) => {
      console.log(error)
      setIsLoader(false);
  });
};
async function getD7SaveDetails() {
  setIsLoader(true);
   GetD7TextDetails(token,rowData[0].complaintNumber,userDetails.userId)
  .then((response) => {
      if(response.length>0){
         setd71UpdateResponse(response);
        discplineData.discipline71=true;
        dispatch(disciplineAction(discplineData));
        }
    setD7Details(response);
    setIsLoader(false);
  })
  .catch((error) => {
      console.log(error)
      setIsLoader(false);
  });
};
    const [d8Details,setD8Details]=useState([]);
   async function getD8Details() {
    var flag=true;
    setIsLoader(true);
  GetD8Details(token,rowData[0].complaintNumber,userDetails.userId)
    .then((response) => {
        // if(response.length>0){
        //     setd8UpdateResponse(response);
        //     discplineData.discipline8=true;
        //     dispatch(disciplineAction(discplineData));
        //   }
        
        if(response.result.length>0){
          setd8UpdateResponse(response.result)
          discplineData.discipline8=true;
          for(var i=0;i<response.result.length;i++){
           var obj=response.result[i];
           if(obj?.statusId!==2){
             flag=false;
          }
          }  
          if(flag===true){ 
          discplineData.disciplineStatus8=true;
          }
          else{
            discplineData.disciplineStatus8=false;
          }
          dispatch(disciplineAction(discplineData));
        }
      setIsLoader(false);
      setD8Details(response.result);
    })
    .catch((error) => {
      setIsLoader(false);
        console.log(error)
    });

};
const [d2Details,setD2Details]=useState([]);
function getD2Details() {
  setIsLoader(true);
GetD2Details(token,rowData[0].complaintNumber,userDetails.userId)
 .then((response) => {
     response.isDraft=true;
     if(response.length>0){
      discplineData.discipline2=true;
      dispatch(disciplineAction(discplineData));
       }
   setD2Details(response);
   setIsLoader(false);
 })
 .catch((error) => {
  setIsLoader(false);
     console.log(error)
 });

};

useEffect(()=>{
  getD6SaveDetails();
  getD7SaveDetails();
    getD4Details();
    getD8Details();
    getD2Details();
    getTableDataD1();
    getTableDataD3();
    getTableDataD4Why();
    getTableDataD4WhyNot();
    getTableDataD5Sec1();
    getTableDataD5Sec2();
    getTableDataD6();
    getTableDataD7();
},[])
return (
<div className={classes.root}>
<div >
<Grid container className={classes.pdBt} spacing={2}>
<Grid item xs={12} sm={6} md={3}>
<FormControl fullWidth>
  <InputLabel shrink htmlFor="bootstrap-input" name="supplierNumberAuto">
    Complaint Number
  </InputLabel>
  <BootstrapInput
  value={rowData[0].complaintNumber}
  disabled
  id="bootstrap-input" />
</FormControl>
</Grid>
<Grid item xs={12} sm={6} md={3}>
<FormControl fullWidth>
  <InputLabel shrink htmlFor="bootstrap-input" name="supplierNumberAuto">
    Raise Date
  </InputLabel>
  <BootstrapInput
  disabled
  value={moment(rowData[0].raiseDate).format('YYYY-MM-DD')}
  id="bootstrap-input" />
</FormControl>
</Grid>
<Grid item xs={12} sm={6} md={3}>
<FormControl fullWidth>
  <InputLabel shrink htmlFor="bootstrap-input" name="supplierNumberAuto">
    Part Number
  </InputLabel>
  <BootstrapInput
  value={rowData[0].partNumber}
  disabled
  id="bootstrap-input" />
</FormControl>
</Grid>
<Grid item xs={12} sm={6} md={3}>
<FormControl fullWidth>
  <InputLabel shrink htmlFor="bootstrap-input" name="supplierNumberAuto">
    Part Description
  </InputLabel>
  <BootstrapInput
  value={rowData[0].partDescription}
  disabled
  id="bootstrap-input" />
</FormControl>
</Grid>
</Grid>
</div>
  <AppBar position="static">
      <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="MCB Scrollable List"
      >
          <Tab label="Discipline 1" {...a11yProps(0)} />
          <Tab label="Discipline 2" {...a11yProps(1)} />
          <Tab label="Discipline 3" {...a11yProps(2)} />
          <Tab label="Discipline 4" {...a11yProps(3)} />
          <Tab label="Discipline 5" {...a11yProps(4)} />
          <Tab label="Discipline 6" {...a11yProps(5)} />
          <Tab label="Discipline 7" {...a11yProps(6)} />
          <Tab label="Discipline 8" {...a11yProps(7)} />
        
      </Tabs>
  </AppBar>
  <TabPanel value={value} index={0}>
      <Discipline1  rowData={rowData}  />
  </TabPanel>
  <TabPanel value={value} index={1}>
      <Discipline2 rowData={rowData} getD2Details={getD2Details} />
  </TabPanel>
  <TabPanel value={value} index={2}>
      <Discipline3 rowData={rowData} />
  </TabPanel>
  <TabPanel value={value} index={3}>
      <Discipline4 rowData={rowData} response={response} d4UpdateResponse={d4UpdateResponse} 
      getD4Details={getD4Details}
      />
  </TabPanel>
  <TabPanel value={value} index={4}>
      <Discipline5 rowData={rowData} />
  </TabPanel>
  <TabPanel value={value} index={5}>
      <Discipline6 rowData={rowData} d6Details={d61UpdateResponse} d61UpdateResponse={d61UpdateResponse} 
       getD6SaveDetails={getD6SaveDetails}
      />
  </TabPanel>
  <TabPanel value={value} index={6}>
      <Discipline7 rowData={rowData} d7Details={d71UpdateResponse} d71UpdateResponse={d71UpdateResponse} 
      getD7SaveDetails={getD7SaveDetails}
      />
  </TabPanel>
  <TabPanel value={value} index={7}>
      <Discipline8 rowData={rowData} d8Details={d8Details} d8UpdateResponse={d8UpdateResponse} 
       getD8Details={getD8Details} 
       getD2Details={getD2Details}
       getD4Details={getD4Details}
       getD1Details={getTableDataD1}
       getD3Details={getTableDataD3}
       getD41Details={getTableDataD4Why}
       getD42Details={getTableDataD4WhyNot}
       getD51Details={getTableDataD5Sec1}
       getD52Details={getTableDataD5Sec2}
       getD6Details={getTableDataD6}
       getD7Details={getTableDataD7}
      />
  </TabPanel>
  <>
{loader? <Loading loaderText="Loading...." /> : <></>}
</>
</div>
  );
}