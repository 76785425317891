import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { InputAdornment, makeStyles, Typography, useTheme } from "@material-ui/core";
import {
  AppBar, Tabs, Tab, Box, Button, TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useAuth } from "react-oidc-context";
import { Link } from "react-router-dom";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Loading from "../../Components/Loader/Loader";
import SwipeableViews from "react-swipeable-views";
import { useDispatch, useSelector } from "react-redux";
import { spocEmail } from "../../Utilities/Utility";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useForm, Controller } from "react-hook-form";
import { storeUserDetailsAction, isVerifiedOTPAction, custHeaderAction, isForgotPasswordAction } from "../../Redux/Actions/actions";
import { authenticateExternalUser, forgotUserPassword, generateTokenOnly, validateTheUserBasedOnApi } from "../../Redux/APIs/api_login";
import { useMsal } from '@azure/msal-react';
import Timer from "../../Components/CountdownTimer/Timer";
import { loginRequest } from "../../authConfig";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "rgba(0, 204, 204, .05)",
    backdropFilter: "blur(3px)",
    minHeight: "100vh",
    overflow: "auto",
  },
  paper: {
    padding: theme.spacing(2),
  },
  errorMessage: {
    color: "var(--red)",
    fontSize: "16px",
  },
  timerDetails: {
    padding: "7px",
    backgroundColor: "var(--bg-green-2)",
  },
  forgotPwd: {
    textAlign: "center",
    textDecoration: "underline",
  },
  loginTabs: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "15px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: "0px",
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}



export default function LoginRegisterTabs() {
  const useStyles = makeStyles((theme) => ({
    loginTabs: {
      flexGrow: 1,
      backgroundColor: `rgba(255, 255, 255, 0.85)`,
      boxShadow: `0 8px 32px #CCCCCC`,
      borderRadius: "10px",
    },
    closeButton: {
      position: "absolute",
      right: "0px",
      top: "0px",
    },
    dialog: {
      position: "absolute",
      top: "230px",
      left: "633px",
    },
    ForgotpwdDialog: {
      top: "10px",
      right: "-97px",
      position: "relative"
    }
  }));
  const dispatch = useDispatch();
  const auth = useAuth();
  const classes = useStyles();
  const theme = useTheme();
  const { control, handleSubmit } = useForm();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = React.useState("");
  const [dialogTitle, setDialogTitle] = React.useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userDataTemp, setUserDataTemp] = useState({});
  const [expiredTime, setExpiredTime] = useState(Date.now());
  const [isLoader, setIsLoader] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [emailHelper, setEmailHelper] = useState("");
  const [otpHelper, setOtpHelper] = useState(null);
  const [pwdDialogOpen, setPwdDialogOpen] = useState(false);
  const [forgotPwdEmail, setForgotPwdEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [enterOTPDialogOpen, setEnterOTPDialogOpen] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [isOtpValid, setIsOtpValid] = useState(false);
    const [value, setValue] = React.useState(0);
    const { instance } = useMsal();
  const handleDialogOpen = (content, title) => {
    setDialogTitle(title);
    setDialogContent(content);
    setDialogOpen(true);
  };
  function handleChangeOtp(event) {
    validateOTP(event.target.value);
    setOtpValue(event.target.value);
  }
  const handleClickShowPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);
  function VerifyOTP() {
    if (otpValue && userDataTemp.generatedOTP === otpValue) {
      let userData = {
        gid: userDataTemp.gid,
        roleId: userDataTemp.roleId,
        roleName: userDataTemp.roleName,
        userEmail: userDataTemp.userEmail,
        userFirstName: userDataTemp.userFirstName,
        userLastName: userDataTemp.userLastName,
        userId: userDataTemp.userId,
        supplierId: userDataTemp.supplierId,
        changePassword: userDataTemp.changePassword,
        passwordChangedOn: userDataTemp.passwordChangedOn,
          isSupplierFirstLogin: userDataTemp.isSupplierFirstLogin,
          supplierLoginType: userDataTemp.supplierLoginType,
          roleList:userDataTemp.roleList,
      };
      dispatch(storeUserDetailsAction(userData));
      dispatch(isVerifiedOTPAction(true));
    } else {
      handleDialogOpen("OTP did not match, try again");
    }
  }
  function VerifyForgotPwdOTP() {
    let userData = {
      userEmail: userDataTemp.userEmail,
    };
    dispatch(storeUserDetailsAction(userData));
    dispatch(custHeaderAction("SQM Tool"));
    dispatch(isVerifiedOTPAction(true));
    dispatch(isForgotPasswordAction(true));
  }
  const handleClose = () => {
    setDialogOpen(false);
    setPwdDialogOpen(false);
  };
  const handleContactClose = () => {
    setContactDialogOpen(false);
  };
  const ResendOTP = (e) => {
    setExpiredTime(Date.now() + 180000);
    handleClose.call();
    let userData = {
      userEmailId: email,
      password: password,
    };
    handleOTPDialogClose();
    externalUserLogin(userData);
  };
  const handleForgotPwdChange = (event) => {
    let emailValid;
    setForgotPwdEmail(event.target.value);
    emailValid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        event.target.value
      );
    if (!emailValid) {
      setIsEmailValid(false);
      setEmailHelper("Invalid Email");
    } else {
      setIsEmailValid(true);
      setEmailHelper("");
    }
  };
  const sendForgotPwdOTP = () => {
    if (isEmailValid) {
      forgotPassword();
    } else {
      setIsEmailValid(false);
      setEmailHelper("Email is mandatory");
    }
  };
  const ResendForgotPwdOTP = () => {
    handleOTPDialogClose();
    forgotPassword();
  };
  function validateOTP(value) {
    if (!value) {
      setOtpHelper("OTP is mandatory");
    } else {
      const re = /^\d*$/;
      if (!re.test(value)) {
        setOtpHelper("OTP must be integer");
        setIsOtpValid(false);
      } else if (value.toString().length !== 6) {
        setOtpHelper("OTP must be of 6 digit");
        setIsOtpValid(false);
      } else if (value !== userDataTemp.generatedOTP) {
        setOtpHelper("OTP mismatch");
        setIsOtpValid(false);
      } else {
        setOtpHelper(null);
        setIsOtpValid(true);
      }
    }
  }
  const handlePwdDialogOpen = () => {
    setPwdDialogOpen(true);
    setIsForgotPassword(true);
  };
  const handlePwdDialogClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setForgotPwdEmail("");
    setEmailHelper("");
    setPwdDialogOpen(false);
  };
  async function forgotPassword() {
    setExpiredTime(Date.now() + 180000);
    const userData = {
      userEmailId: forgotPwdEmail,
    };
    setIsLoader(true);

    generateTokenOnly(dispatch)
      .then((tokResponse) => {
        const genToken = tokResponse;
        forgotUserPassword(genToken, userData)
          .then((responseAuthenticate) => {
            if (responseAuthenticate) {
              if (typeof responseAuthenticate === "object") {
                setUserDataTemp(responseAuthenticate);
                setIsLoader(false);
                setPwdDialogOpen(false);
                setEnterOTPDialogOpen(true);
              } else {
                if (typeof responseAuthenticate === "string") {
                  setIsLoader(false);
                  handleDialogOpen(
                    responseAuthenticate,
                    responseAuthenticate.includes("Failed") ? "Error" : "Alert"
                  );
                } else {
                  setIsLoader(false);
                  handleDialogOpen(
                    "Invalid user data returned from server",
                    "Error"
                  );
                }
              }
            } else {
              setIsLoader(false);
              handleDialogOpen("Invalid email or password", "Error");
            }
          })
          .catch((error) => {
            setIsLoader(false);
            handleDialogOpen("Error in external login", "Error");
          });
      })
      .catch((error) => {
        handleDialogOpen("Error: " + error, "error");
      });
  }

  const handleOTPDialogClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setEnterOTPDialogOpen(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  function myIDLogin() {
    dispatch(auth.signinRedirect);
    }

    function azureADLogin() {
        instance.loginRedirect(loginRequest).catch(e => {
            console.log(e);
        });
        // dispatch(auth.signinRedirect);
    }
  function externalUserLogin(userData) {
    setIsLoader(true);
    setEmail(userData.userEmailId);
    setPassword(userData.password);
    setExpiredTime(Date.now() + 180000);
    validateTheUserBasedOnApi(dispatch, auth)
      .then((responseValidate) => {
        if (responseValidate.response) {
          authenticateExternalUser(responseValidate.objResult, userData)
            .then((responseAuthenticate) => {
              if (responseAuthenticate) {
                if (typeof responseAuthenticate === "object") {
                    setUserDataTemp(responseAuthenticate);
                    console.log("supplierlogin", userDataTemp);
                  setIsLoader(false);
                  // setDialogOpen(true);
                  setEnterOTPDialogOpen(true);
                } else {
                  if (typeof responseAuthenticate === "string") {
                    setIsLoader(false);
                    handleDialogOpen(
                      responseAuthenticate,
                      responseAuthenticate.includes("Failed")
                        ? "Error"
                        : "Alert"
                    );
                  } else {
                    setIsLoader(false);
                    handleDialogOpen(
                      "Invalid user data returned from server",
                      "Error"
                    );
                  }
                }
              } else {
                setIsLoader(false);
                handleDialogOpen("Invalid email or password", "Error");
              }
            })
            .catch((error) => {
              setIsLoader(false);
              handleDialogOpen("Error in external login", "Error");
            });
        }
      })
      .catch((error) => {
        handleDialogOpen("Error in token generation", "Error");
      });
  }
  useEffect(() => {
    if (enterOTPDialogOpen) {
      setExpiredTime(Date.now() + 180000);
      setTimeout(function () {
        // setEnterOTPDialogOpen(false);
        handleOTPDialogClose();
      }, 180000);
    }
  }, [enterOTPDialogOpen]);
  return (
    <>
      <div className="ssiat-lr-block">
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            scrollButtons="auto"
          >
            <Tab label="Azure AD Login" {...a11yProps(0)} />
            <Tab label="External Supplier" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <span className="d-block">
              <h3 className="heading-margin-5tb-0lr">
                Siemens Employee Login{" "}
              </h3>
              <p>If you are a Siemens Employee, login via Azure AD</p>
            </span>
                      <div className="d-flex jc-center mt-1">
                          <Link to="/">
                              <Button
                                  className="pt-button--hero myid-login-btn-block"
                                  id="AzureAdLogin"
                                  onClick={azureADLogin}
                              >
                                  <span>Azure AD Login</span>
                              </Button>
                          </Link>
                      </div>
            <div className="d-flex jc-center mt-1">
              <span>Having Issues?&nbsp;</span>
              <span className="contact-us-text" onClick={() => setContactDialogOpen(true)}>
                Contact us
              </span>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="pt-login-external_user">
              <h3 className="heading-margin-5tb-0lr">Supplier Login</h3>
              <span>
                If you are an external supplier, login via registered
                email
              </span>
              <form
                noValidate
                autoComplete="off"
                className="pt-login-form"
                onSubmit={handleSubmit((data) => externalUserLogin(data))}
              >
                <Controller
                  name="userEmailId"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Email ID required",
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Invalid email format",
                    },
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      type="email"
                      label="Email"
                      variant="filled"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      id="supplierEmail"
                      className="pt-login-form-input mb-1"
                    />
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Password required",
                    minLength: {
                      value: 6,
                      message:
                        "Password must be greater than 6 characters",
                    },
                    maxLength: {
                      value: 15,
                      message:
                        "Password cannot be greater than 15 characters",
                    },
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      type={showPasswordConfirm ? "text" : "password"}
                      label="Password"
                      variant="filled"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      id="supplierPassword"
                      className="pt-login-form-input mb-1"
                      InputProps={{
                        endAdornment: (
                          <>


                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                className="password-adornment"
                                onClick={handleClickShowPasswordConfirm}
                              //onMouseDown={handleMouseDownShowPasswordConfirm}
                              >
                                {showPasswordConfirm ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          </>
                        ),
                      }}
                    />
                  )}
                />

                <div className="d-flex jc-center supplier-login-btn-block">
                  <Button
                    type="submit"
                    className="pt-button--hero supplier-login-btn"
                    id="SupplierLoginBtn"
                  >
                    Generate OTP
                  </Button>
                </div>
              </form>
            </div>

            <div className={classes.forgotPwd}>
              <h5
                className="forget-pwd-cursor"
                onClick={handlePwdDialogOpen}
              >
                Forgot Password
              </h5>
            </div>
            <div>
              <Dialog
                classes={{
                  paper: classes.ForgotpwdDialog
                }}
                open={pwdDialogOpen}
                onClose={handlePwdDialogClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">Forgot Password</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    variant="filled"
                    id="forgotEmail"
                    label="Enter Registered Email ID"
                    type="email"
                    onChange={handleForgotPwdChange}
                    value={forgotPwdEmail}
                    error={emailHelper.length !== 0}
                    helperText={emailHelper}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handlePwdDialogClose}
                    className="pt-button--secondary"
                  >
                    Cancel
                  </Button>
                  <Button onClick={sendForgotPwdOTP} className="pt-button--primary">
                    Send OTP
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                classes={{
                  paper: classes.dialog
                }}
                open={enterOTPDialogOpen}
                onClose={handleOTPDialogClose}
                aria-labelledby="otp-dialog-title"
                aria-describedby="otp-dialog-description"
              >

                <DialogTitle id="otp-dialog-title">Enter OTP</DialogTitle>
                <DialogContent>

                  <DialogContentText id="otp-dialog-description">
                    <TextField
                      autoFocus
                      fullWidth
                      type="text"
                      label="Enter OTP"
                      variant="filled"
                      value={otpValue}
                      inputProps={{ maxLength: 6 }}
                      onChange={handleChangeOtp}
                      error={otpHelper && otpHelper.length > 0}
                      helperText={otpHelper}
                      id="forgotPwdOTP"
                    />
                    <Box>
                      <Grid container>
                        <Grid item md={8} className="timer-otp"><span>OTP will be expired in</span></Grid>
                        <Grid item md={4} className="expire-otp">
                          <Timer
                            expiredTime={expiredTime}
                            className={classes.timerDetails}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <IconButton
                      aria-label="close"
                      className={classes.closeButton}
                      onClick={handleOTPDialogClose}
                    >
                      <CloseIcon />
                    </IconButton>


                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={isForgotPassword ? ResendForgotPwdOTP : ResendOTP}
                    type="input"
                    className="pt-button--secondary supplier-login-btn"
                    id="btnOtpResend"
                  >
                    Resend
                  </Button>
                  <Button
                    onClick={isForgotPassword ? VerifyForgotPwdOTP : VerifyOTP}
                    className="pt-button--primary supplier-login-btn"
                    id="btnOtpVerify"
                    disabled={otpHelper && otpHelper.length > 0 && !otpValue}
                  >
                    Verify
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <div>
              <Dialog
                open={dialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {dialogContent}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    type="input"
                    className="pt-button--secondary supplier-login-btn"
                    id="SupplierOTPResend"
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </TabPanel>
        </SwipeableViews>
        {isLoader ? <Loading /> : <></>}
      </div>


      <Dialog
        open={contactDialogOpen}
        onClose={handleContactClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Contact Us
        </DialogTitle>
        <DialogContent>
          <h3>Name : Sowmya Bhat</h3>
          <h3>Email : sowmya.bhat@siemens.com</h3>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleContactClose}
            type="input"
            className="pt-button--secondary supplier-login-btn"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}
