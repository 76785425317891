import React, { useEffect, useState, useRef } from "react";
import {
    Grid,
    Button,
    TextField
} from "@material-ui/core";
import { useSelector } from "react-redux";
import "./masterupload.scss";
import { uploadMasterFile } from "../../../../Redux/APIs/api_view";
import Loader from "../../../../Components/Loader/Loader";
import SnackbarCustom from "../../../../Components/Snackbar/Snackbar";

export default function AttachmentMaster() {
    const [selectedFile, setSelectedFile] = useState([]);
    const [inputFileValue, setInputFileValue] = useState(Date.now);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer.token);
    const [isLoading, setIsLoading] = useState(false);
    //Snackbar
    const [fileData, setFileData] = useState([]);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const updateExcelHandler = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        setSelectedFile(chosenFiles);
        console.log("SelectedFile", e.target.files);
    };

    const onSubmit = () => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('userId', userDetails.userId);
        for (let i = 0; i < selectedFile.length; i++) {
            formData.append("Document", selectedFile[i]);

        }

        if (selectedFile.length > 0) {
            setIsLoading(true);
            uploadMasterFile(formData, siteToken)
                .then((response) => {
                    setIsLoading(false);
                    setSelectedFile("");
                    setFileData(response.data);

                    setIsLoading(false);
                    handleSnackOpen("File Uploaded Successfully.", "success");

                    console.log("Uploaded File Details", response.data);
                })
                .catch(function (error) {
                    setIsLoading(false);
                    handleSnackOpen("Error in saving.", "error");
                    console.log(error);
                });
            setIsFileUploaded(true);
        }
        else {
            setIsLoading(false);
            handleSnackOpen("Select files to Upload", "warning");
        }
    };

    return (
        <>
            <h3 className="gridMargin">Attachment Master </h3>
            <Grid container spacing={2} className="gridMargin">
                <Grid item xs={12} sm={4} md={3}>
                    <form className="file-upload-form">
                        <TextField
                            type="file"
                            inputProps={{
                                multiple: true,
                            }}
                            label=""
                            variant="filled"
                            className="file-upload"
                            name="fileUpload"
                            onChange={updateExcelHandler}
                            key={inputFileValue}
                        />
                    </form>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <Button
                        className="pt-button--secondary bulk-upload-submit-btn"
                        type="button"
                        disabled={selectedFile ? false : true}
                        onClick={onSubmit}
                    >
                        Upload
                    </Button>
                    {/*<span className="mandatoryText">Note: Upload files with fixed names and filetypes.Eg: DFM.pdf</span>*/}
                </Grid>
            </Grid>
            {/* <Grid className="marginChange">
                <span className="mandatoryText">Note: Filenames should match exactly as below<br />1. DFM_Format.pptx<br />2. EM_EA_GF_QM_FR_033 TFC.xls<br />3. EM_EA_GF_QM_FR_046 TRM.xlsx<br />4. EM_EA_GF_QM_FR_056 ISIR.xlsm<br />5. EM_EA_GF_QM_FR_068 PPAP.xlsx</span>
            </Grid>*/}
            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />
            {isLoading ? <Loader loaderText="Loading...!" /> : <></>}
        </>
    );
}