import { FormControl, Grid, InputLabel } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import "./ComplaintsList.scss";
import { TextField } from "@material-ui/core";
import MaterialTable from "material-table";
import { CircularProgress } from "@material-ui/core";
import { BootstrapInput, getTodayDateMoment, tableIcons } from "../../../Utilities/Utility";
import { useNavigate } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Loading from "../../../Components/Loader/Loader";
import FileSaver from "file-saver";
import { useSelector, useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import { GetComplaintDetails, GetComplaintDetailsByComponent, GetComplaintDetailsBySupplier, GetKPIDetails } from "../../../Redux/APIs/api_Complaint";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import { GetPartDDl, GetSupplierEmailDDl } from "../../../Redux/APIs/api_Common";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import { FeedbackBySupplierStatus } from "../../../Redux/APIs/api_status";
import moment from "moment";
import { disciplineAction, supplierEmailArrAction, supplierMailValAction } from "../../../Redux/Actions/actions";
import { Generate8DReportPdf } from "../../../Redux/APIs/api_Disciplines";

export default function ComplaintsList() {
    const dispatch = useDispatch();
    const tableRef = useRef();
    const icon = () => {
        return (
            <GetAppRoundedIcon
                style={{ fontSize: 45, }}
            />
        );
    };
    function getProgressComponent() {
        return <CircularProgress style={{ height: `15px`, width: `15px` }} />;
    }

    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const storedSupplierMailArr = useSelector((state) => state.supplierEmailArrReducer);
    const [isLoader, setIsLoader] = useState(false);
    const [isLoaderKpi, setIsLoaderKpi] = useState(false);
    const [isLoaderFeedback, setIsLoaderFeedback] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const handleClickOpen = (text) => {
        setOpenDialog(true);
    };
    const [dateInputs, setDateInputs] = useState({
        fromDate: null,
        toDate: null
    });
    function handleDateChange(event, value) {
        let elem = event.target.name;
        switch (elem) {
            case "fromDate":
                setDateInputs({
                    ...dateInputs,
                    fromDate: event.target.value,
                })
                break;
            case "toDate":
                setDateInputs({
                    ...dateInputs,
                    toDate: event.target.value,
                })
                break;
            default:
                break;

        }
    }
    const [feedback, setFeedback] = useState("");
    const handleChange = (e) => {
        setFeedback(e.target.value);
        setFeedbackError("");
    }
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    const [feedbackError, setFeedbackError] = useState("");
    const [partData, setPartData] = useState([]);
    async function getPartDetails() {
        const response = await GetPartDDl(token, userDetails.userId);
        if (response && response.length > 0) {
            setPartData(
                response.map((item) => ({ value: item.id, label: item.name, partNumber: item.partNumber }))
            );
        } else {
            setPartData([]);
        }
    }
    function renderPartValue(value) {
        let obj = {};
        if (partData && partData.length > 0) {
            obj = partData.find((c) => c.value == value);
        }
        if (obj) {
            return obj.partNumber;
        } else {
            return "";
        }
    }
    function renderDate(value) {
        let DateFormat = !value ? null : moment(value).format('YYYY-MM-DD');
        return DateFormat;
    }
    const navigate = useNavigate();
    var columns = [
        {
            title: "Complaints Id.",
            field: "complaintNumber",
            render: (rowData) => navigateToComplaintsPage(rowData),
        },

        {
            title: "Part No",
            field: "partId",
            render: (rowData) => <span>{renderPartValue(rowData.partId)}</span>
        },
        {
            title: "Sender",
            field: "senderName",
        },
        {
            title: "Supplier Name",
            field: "supplierName",
        },
        {
            title: "Raise Date",
            field: "raiseDate",
            render: (rowData) => <span>{renderDate(rowData.raiseDate)}</span>
        },
        {
            title: "Target Closure Date",
            field: "targetdate",
            render: (rowData) => <span>{renderDate(rowData.targetdate)}</span>
        },
        {
            title: "8D Closure Date",
            field: "closeDate",
            render: (rowData) => <span>{renderDate(rowData.closeDate)}</span>
        },
        {
            title: "Status",
            field: "status",
        },
        {
            title: "Required8D",
            field: "required8DStatus",
            //render: (rowData) => renderRequired8D(rowData),
        },
        userDetails.roleId !== 3 ?
            {
                title: "",
                field: "button1",
                render: (rowData) => rowData.status === "Submitted" || rowData.status === "Draft" || rowData.status === "Feedback completed" || rowData.status === "Recall" || rowData.required8D === false ? <></> : view8DBtn(rowData),
            }
            :
            {
                title: "",
                field: "button1",
                render: (rowData) => !(rowData.status === "Submitted" || rowData.status === "Draft" || rowData.required8D === false) ? prepare8DBtn(rowData) : <></>,
            },
        userDetails.roleId === 3 ?
            {
                title: "",
                field: "button1",
                render: (rowData) => !rowData.required8D && rowData.statusId != 8 ? sendFeedback(rowData) : <div></div>,
            } : {

                title: "",
                field: "button1",
                render: (rowData) => rowData.required8D && (rowData.status === "8D Completed" || rowData.status === "Approved") ? generatePdf(rowData) : <></>,
            },
        userDetails.roleId !== 3 ?
            {
                title: "",
                field: "button1",
                render: (rowData) => rowData.statusId === 8 ? duplicateComplaint(rowData) : <div></div>,
            } : {}

    ];
    const [tableData, setTableData] = useState([]);
    function getTableData() {
        if (userDetails.roleId === 3) {
            setIsLoader(true);
            GetComplaintDetailsBySupplier(token, userDetails.supplierId)
                .then((response) => {
                    
                    setTableData(response);
                    setIsLoader(false);
                })
                .catch((error) => {
                    handleSnackOpen("Error : " + error, "error");
                    setIsLoader(false);
                });
        }
        else if (userDetails.roleId === 1) {
            setIsLoader(true);
            GetComplaintDetails(token, userDetails.userId)
                .then((response) => {
                   
                    setTableData(response);
                    setIsLoader(false);
                })
                .catch((error) => {
                    handleSnackOpen("Error : " + error, "error");
                    setIsLoader(false);
                });
        }
        else {
            setIsLoader(true);
            GetComplaintDetailsByComponent(token, userDetails.userId)
                .then((response) => {
                    
                    setTableData(response);
                    setIsLoader(false);
                })
                .catch((error) => {
                    handleSnackOpen("Error : " + error, "error");
                    setIsLoader(false);
                });
        }
    }
    const [kpiData, setKpiData] = useState([]);
   
    function getKPIData() {
        setIsLoaderKpi(true);
        GetKPIDetails(token, dateInputs.fromDate, dateInputs.toDate, userDetails.userId, userDetails.supplierId)
            .then((response) => {
                setKpiData(response);
                console.log("KPI res", response);
               setTableData(response.complaintData)
               
                setIsLoaderKpi(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }

    const [supplierEmail, setSupplierEmail] = useState([]);
    async function SupplierEmailDDL() {
        const response = await GetSupplierEmailDDl(token, userDetails.userId);
        let supplierArr = [];
        if (response && response.length > 0) {
            setSupplierEmail(response);
            supplierArr = response;
        }
        else {
            setSupplierEmail([{
                id: 0,
                name: "No Options",
            },]);
            supplierArr = [{
                id: 0,
                name: "No Options",
            }];
        }
        dispatch(supplierEmailArrAction(supplierArr));
    }

    useEffect(() => {
        console.log(token);
        getTableData();
        getKPIData();
        getPartDetails();
        SupplierEmailDDL();
        dispatch(supplierMailValAction(null));
    }, [])
    function renderRequired8D(rowData) {
        if (rowData?.required8D == true) {
            return "Yes";
        }
        else {
            return "No";
        }

    }
    function view8DBtn(data) {
        return (
            <Button
                className="pt-button--tertiary"
                onClick={() => {
                    dispatch(disciplineAction({
                        discipline1: false,
                        discipline2: false,
                        discipline3: false,
                        discipline4: false,
                        discipline41: false,
                        discipline42: false,
                        discipline5: false,
                        discipline51: false,
                        discipline6: false,
                        discipline61: false,
                        discipline7: false,
                        discipline71: false,
                        discipline8: false,
                        disciplineStatus3: false,
                        disciplineStatus51: false,
                        disciplineStatus52: false,
                        disciplineStatus6: false,
                        disciplineStatus7: false,
                    }));
                    navigate("/report8D",
                        {
                            state: {
                                rowData: data
                            }
                        }
                    )
                }
                }
            >
                View 8D
            </Button>
        );

    }
    function generatePdf(data) {
        return (
            <Button
                className="pt-button--tertiary"
                onClick={() => {
                    if (data) {
                    }
                    Generate8DPdf(data.complaintNumber)
                }
                }
            // disabled={!(data.status==="8D Completed"||data.status==="Approved")}
            >
                Generate PDF
            </Button>
        );
    }
    function onClickDuplicateComplaint(data) {
        var dataNew = [data];
        const supMailIndex = findDefSupplerMailIdx(data);
        dispatch(supplierMailValAction(supMailIndex));
        var newData = dataNew.map((item) => {
            item.status = "Draft"
            item.name = "duplicateComplaint";
            return item;
        })
        return newData[0]
    }
    function duplicateComplaint(data) {
        return (
            <Button
                className="pt-button--tertiary"
                onClick={() =>
                    navigate("/createComplaint",
                        {
                            state: {
                                rowData: onClickDuplicateComplaint(data)
                            }
                        },
                    )}
                disabled={data.status !== "Approved"}
            >
                Duplicate
            </Button>
        );
    }
    function prepare8DBtn(data) {
        return (
            <Button
                className="pt-button--tertiary"
                onClick={() => {
                    dispatch(disciplineAction({
                        discipline1: false,
                        discipline2: false,
                        discipline3: false,
                        discipline4: false,
                        discipline41: false,
                        discipline42: false,
                        discipline5: false,
                        discipline51: false,
                        discipline6: false,
                        discipline61: false,
                        discipline7: false,
                        discipline71: false,
                        discipline8: false,
                        disciplineStatus3: false,
                        disciplineStatus51: false,
                        disciplineStatus52: false,
                        disciplineStatus6: false,
                        disciplineStatus7: false,
                    }))

                    if (data.status === "Submitted") {
                        handleSnackOpen("Please Acknowledge the Complaint", "Info");
                    }
                    else if (!data.required8D) {
                        handleSnackOpen("Prepare 8D Enables only when Report is  Required", "Info");
                    }
                    else {
                        navigate("/report8D",
                            {
                                state: {
                                    rowData: data
                                }
                            }
                        )
                    }
                }
                }
            >
                Prepare 8D
            </Button>
        );

    }
    const [cn, setCN] = useState("");
    function sendFeedback(data) {
        return (
            <Button
                className="pt-button--tertiary"
                disabled={userDetails.roleId == 3 && data.statusId == 11 }
                onClick={() => {
                    if (data.status === "Submitted") {
                        handleSnackOpen("Please Acknowledge the Complaint", "Info");
                    }
                    else if (!data.required8D) {
                        setCN(data.complaintNumber);
                        handleClickOpen();
                    }
                    else {
                        handleSnackOpen("Feedback Enables only when 8D Report is Not Required", "Info");
                    }
                }
                }
            >
                Closure Comments
            </Button>
        );
    }

    function handleFeedbackApi() {
        if (!feedback) {
            setFeedbackError("*Mandatory");
        }
        else {
            handleCloseDialog();
            const complaintNumber = cn;
            setIsLoaderFeedback(true);
            FeedbackBySupplierStatus(token, complaintNumber, feedback, userDetails.userId)
                .then((response) => {
                    if (response.response) {
                        handleSnackOpen(response.responseMsg, "success");
                        setIsLoaderFeedback(false);

                    } else {
                        handleSnackOpen(response.responseMsg, "error");
                        setIsLoaderFeedback(false);
                    }
                })
                .catch((error) => {
                    handleSnackOpen("Exception : " + error, "error");
                    setIsLoaderFeedback(false);
                });
        }
    }
    function Generate8DPdf(data) {
        setIsLoader(true);
        Generate8DReportPdf(token, userDetails.userId, data)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.byteLength <= 200) {
                        setIsLoader(false);
                        handleSnackOpen();
                        setSnackMessage("No Records are Found ");
                        setAlertType("success");
                    } else {
                        var blob = new Blob([response.data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        });
                        FileSaver.saveAs(blob, "8D Report PDF.pdf");
                        setIsLoader(false);
                        handleSnackOpen();
                        setSnackMessage("8D Report Downloaded");
                        setAlertType("success");
                    }
                } else {
                    setIsLoader(false);
                    handleSnackOpen();
                    setSnackMessage("Report download failed");
                    setAlertType("error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                handleSnackOpen();
                setSnackMessage("Error: ", error);
                setAlertType("error");
            });
    }
    const actions = [
        {
            icon: icon,
            tooltip: "Export to Excel",
            onClick: () => ExportToExcel(),
            isFreeAction: true,
        },
    ];
    function ExportToExcel() {
        const dataToDownload = tableRef?.current?.dataManager?.searchedData.map(
            (row) => {
                return {
                    complaintNumber: row.complaintNumber,
                    senderName: row.senderName,
                    supplierName: row.supplierName,
                    partNumber: row.partNumber,
                    raiseDate: moment(row.raiseDate).format("YYYY-MM-DD"),
                    closeDate: row.closeDate ? moment(row.closeDate).format("YYYY-MM-DD") : "",
                    required8D: row.required8D,
                    status: row.status,
                };
            }
        );
        let headers = [
            "Complaint Number",
            "Sender Name",
            "Supplier Name",
            "Part Number",
            "Raise Date",
            "Close Date",
            "8D Required",
            "Status",
        ];
        

        let ws = XLSX.utils.book_new();
        let wb = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [headers]);
        XLSX.utils.sheet_add_json(ws, dataToDownload, {
            origin: "A2",
            skipHeader: true,
            cellStyles: true,
        });
        const dataForCellAdjustments = dataToDownload;
        const headerObj = {
            complaintNumber: headers[0],
            senderName: headers[1],
            supplierName: headers[2],
            partNumber: headers[3],
            raiseDate: headers[4],
            closeDate: headers[5],
            required8D: headers[6],
            status: headers[7],
        }
        dataForCellAdjustments.push(headerObj);
        ws["!cols"] = cellAdjustToContents(dataToDownload);
        XLSX.utils.book_append_sheet(wb, ws, "Complaints List");
        XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(wb, "Complaints List.xlsx");
    }
    function cellAdjustToContents(data) {
        return [
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.complaintNumber ? c.complaintNumber?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.senderName ? c.senderName.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.supplierName ? c.supplierName.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.partNumber ? c.partNumber.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.raiseDate ? c.raiseDate.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.closeDate ? c.closeDate.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.required8D ? c.required8D.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.status ? c.status.toString()?.length : 0
                    )
                ),
            },

        ];
    }

    function findDefSupplerMailIdx(arg) {
        //console.log("Index", defaIndex);
        return storedSupplierMailArr.findIndex(x => x.supplierId == arg.supplierId && x.email == arg.supplierEmail);
        //setDefaIndex(defaIndexVal);
    }
    function navigateToComplaintsPage(data) {
        // supplierId
        return (
            <Button
                className="pt-button--light"
                onClick={() => {
                    console.log("Tab Data", data);
                    const supMailIndex = findDefSupplerMailIdx(data);
                    dispatch(supplierMailValAction(supMailIndex));
                    navigate("/createComplaint", {
                        state: {
                            rowData: data
                        }
                    });
                }}
            >
                {data.complaintNumber}
            </Button>
        );
    }
    const tableHeaderStyle = {
        backgroundColor: "var(--light-sand)",
        color: "var(--dark-blue)",
    };
    const tableOptions = {
        headerStyle: tableHeaderStyle,
        showTitle: false,
        draggable: false,
        pageSize: 5,
        pageSizeOptions: [5, 10, 25, 50],
        paginationPosition: "bottom",
        maxBodyHeight: "600px",
    };

    return (
        <>
            <Grid container xs={12} className="complaintsHomePage">
                <Grid item xs={12} className="pageTitle">
                    <h3>List of Complaints</h3>
                </Grid>
                <Grid container className="bg-card" spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                From Date
                            </InputLabel>
                            <BootstrapInput
                                type="date"
                                id="bootstrap-input"
                                name="fromDate"
                                onChange={handleDateChange}
                                value={dateInputs.fromDate}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                To Date
                            </InputLabel>
                            <BootstrapInput
                                type="date"
                                id="bootstrap-input"
                                name="toDate"
                                onChange={handleDateChange}
                                value={dateInputs.toDate}
                                inputProps={{ max: getTodayDateMoment }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item className="mr-btm" xs={12} sm={6} md={2}>
                        <Button className="pt-button--primary button-submit"
                            variant="contained"
                            onClick={getKPIData}
                        >
                            Filter Result
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <div className="dashboard-block">

                            <p className="dashboard-text-block d-flex jc-space-bt">
                                <span>Open Complaints Percentage&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    </span>:
                                {isLoaderKpi ? (
                                    getProgressComponent()
                                ) : (
                                        <span>{kpiData?.openComplaintsPercentage}</span>
                                )}
                            </p>


                            <p className="dashboard-text-block d-flex jc-space-bt">
                                <span>OverDue Complaints Percentage</span>:
                                {isLoaderKpi ? (
                                    getProgressComponent()
                                ) : (
                                        <span style={{ color: "red" }}>{kpiData?.dueComplaintsPercentage}</span>
                                )}
                            </p>

                            <p className="dashboard-text-block d-flex jc-space-bt">
                                <span>Closed Complaints Percentage&nbsp;&nbsp;&nbsp;&nbsp; </span>:
                                {isLoaderKpi ? (
                                    getProgressComponent()
                                ) : (
                                    <span>{kpiData?.closeComplaintsPercentage}</span>
                                )}
                            </p>

                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="matTable">
                    <MaterialTable
                        tableRef={tableRef}
                        actions={actions}
                        columns={columns}
                        data={tableData}
                        icons={tableIcons}
                        isLoading={isLoader}
                        options={tableOptions}
                    />
                </Grid>
            </Grid>
            <>
                <SnackbarCustom
                    open={open}
                    message={snackMessage}
                    alertType={alertType}
                    handleClose={handleClose}
                />
            </>
            <></>
            <div>
                <Dialog fullWidth open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">

                    <DialogContent>
                        <h3 style={{ paddingBottom: "25px" }}>Closure Comments</h3>
                        <Grid>
                            <TextField
                                fullWidth
                                id="standard-multiline-flexible"
                                placeholder=" Enter Feedback"
                                variant="outlined"
                                error={
                                    feedbackError && feedbackError.length > 0
                                }
                                helperText={feedbackError}
                                multiline
                                inputProps={{
                                    style: {
                                        height: "70px",
                                    },
                                }}
                                maxRows={5}
                                value={feedback}
                                onChange={handleChange}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        {userDetails.roleId === 3 ?
                            <Button className="pt-button--primary button-submit" variant="contained" onClick={handleFeedbackApi} color="primary">
                                Submit
                            </Button>
                            : ""
                        }
                        <Button className="pt-button--secondary button-submit" variant="contained" onClick={handleCloseDialog} color="primary">
                            Cancel
                        </Button>

                    </DialogActions>
                </Dialog>
                {isLoaderFeedback ? <Loading loaderText="Loading...." /> : <></>}
            </div>
        </>
    );
}
