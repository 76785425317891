import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import {
    FormControl,
    TextField,
    FormHelperText,
    Dialog,
    DialogContent,
    Tooltip,
    Button,
    DialogContentText,
    DialogActions,
    DialogTitle,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";
import { BootstrapInput, getTodayDate, getTodayDateMoment } from "../../../Utilities/Utility";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import {
    customStylesDefault,
    tableIconsInputScreen,
    theme,
} from "../../../Utilities/Utility";
import { tableOptionsUser } from "../../../Themes/LightTheme";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import {
    addUserDetails,
    getUserDetails,
    updateUserDetails,
    deleteUserDetails,
} from "../../../Redux/APIs/api_MasterData";
import { getRoleDDL, getSupplierDDL } from "../../../Redux/APIs/api_Common";
import { getRDLocation } from "../../../Redux/APIs/api_view";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { DeleteOutlined } from "@material-ui/icons";
import Loader from "../../../Components/Loader/Loader";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function UserManagement() {
    const classes = useStyles();
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const [tableData, setTableData] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("Required **");

    // New
    const [openForm, setOpenForm] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteRowData, setDeleteRowData] = useState(null);
    const [formDialogTitle, setFormDialogTitle] = useState("Save");

    // const handleClickOpenForm = () => {
    //   setFormDialogTitle("Save");
    //   setOpenForm(true);
    // };
    const handleCloseForm = () => {
        clearFormValues();
        setOpenForm(false);
    };
    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setDeleteRowData(null);
    };
    const handleDeleteIconClick = (rowData) => {
        setDeleteRowData(rowData);
        setDeleteDialogOpen(true);
    };
    const handleDialogRowDelete = () => {
        console.log(deleteRowData);
        setIsLoader(true);
        setDeleteDialogOpen(false);
        const deleteArgs = deleteRowData;
        deleteUserDetails(token, deleteArgs)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                setIsLoader(false);
            });
    };
    const handleSaveForm = () => {
        setFormDialogTitle("Save");
        if (firstNameVal !== "" && firstNameHelperText != "") {
            handleSnackOpen("Enter Valid First Name", "error");
            return;
        } else if (lastNameVal !== "" && lastNameHelperText != "") {
            handleSnackOpen("Enter Valid Last Name", "error");
            return;
        } else if (emailVal !== "" && emailHelperText != "") {
            handleSnackOpen("Enter Valid Email", "error");
            return;
        } else if (roleVal !== "" && roleHelperText != "") {
            handleSnackOpen("Select any Role", "error");
            return;
        }
       
         else if (roleVal !== 3 && gidVal == "" && gidHelperText != "") {
            handleSnackOpen("Enter Valid Gid", "error");
            return;
        } else if (
            roleVal === 3 &&
            supplierVal === "" &&
            supplierHelperText !== ""
        ) {
            handleSnackOpen("Select any Supplier", "error");
            return;
        } else {
            console.log("All Val", firstNameVal, lastNameVal);
            setIsLoader(true);

            const eneredAdminUserDetails = {
                gid: gidVal,
                loggedinUserId: userDetails.userId,
                roleId: roleVal,
                supplierCode: supplierCodeVal,
                userEmail: emailVal,
                userFirstName: firstNameVal,
                userLastName: lastNameVal,
                RdlocationId: rndLocationData,
                SupplierLoginType: userVal,
            };

            const enetredSupplierUserDetails = {
                gid: gidVal,
                loggedinUserId: userDetails.userId,
                roleId: roleVal,
                supplierCode: supplierCodeVal,
                supplierId: supplierIdVal,
                userEmail: emailVal,
                userFirstName: firstNameVal,
                userLastName: lastNameVal,
                SupplierLoginType: userVal,
               // RdlocationId: rndLocationData,
            };

            const apiArgs =
                roleVal === 3 ? enetredSupplierUserDetails : eneredAdminUserDetails;
            addUserDetails(token, apiArgs)
                .then((response) => {
                    if (response.response) {
                        setIsLoader(false);
                        handleCloseForm();
                        handleSnackOpen(response.responseMsg, "success");
                        getTableData();
                    } else {
                        setIsLoader(false);
                        handleCloseForm();
                        handleSnackOpen(response.responseMsg, "error");
                    }
                })
                .catch((error) => {
                    setIsLoader(false);
                    handleCloseForm();
                    handleSnackOpen("Exception : " + error, "error");
                });
        }
    };

    const handleUpdateForm = () => {
        if (firstNameVal !== "" && firstNameHelperText != "") {
            handleSnackOpen("Enter Valid First Name", "error");
            return;
        } else if (lastNameVal !== "" && lastNameHelperText != "") {
            handleSnackOpen("Enter Valid Last Name", "error");
            return;
        } else if (emailVal !== "" && emailHelperText != "") {
            handleSnackOpen("Enter Valid Email", "error");
            return;
        } else if (roleVal !== "" && roleHelperText != "") {
            handleSnackOpen("Select any Role", "error");
            return;
        }
            //else if (userVal !== "" && userNameHelperText != "") {
            //handleSnackOpen("select user type", "error");
        
         else if (roleVal !== 3 && gidVal !== "" && gidHelperText != "") {
            handleSnackOpen("Enter Valid Gid", "error");
            return;
        } else if (
            roleVal === 3 &&
            supplierVal !== "" &&
            supplierHelperText !== ""
        ) {
            handleSnackOpen("Select any Supplier", "error");
            return;
        } else {
            console.log("All Val", firstNameVal, lastNameVal);
            setIsLoader(true);

            const eneredAdminUserDetails = {
                gid: gidVal,
                loggedinUserId: userDetails.userId,
                roleId: roleVal,
                userId: editUserId,
                supplierCode: supplierCodeVal,
                userEmail: emailVal,
                userFirstName: firstNameVal,
                userLastName: lastNameVal,
               SupplierLoginType: userVal,
                //SupplierLoginType: reportInputs.userType,
                RdlocationId: rndLocationData,
            };

            const enetredSupplierUserDetails = {
                gid: gidVal,
                loggedinUserId: userDetails.userId,
                roleId: roleVal,
                userId: editUserId,
                supplierCode: supplierCodeVal,
                supplierId: supplierIdVal,
                userEmail: emailVal,
                userFirstName: firstNameVal,
                userLastName: lastNameVal,
                SupplierLoginType:userVal,
                //SupplierLoginType: reportInputs.userType,
                RdlocationId: rndLocationData,
            };
          

            const deleteApiArgs =
                roleVal === 3 ? enetredSupplierUserDetails : eneredAdminUserDetails;
            console.log(deleteApiArgs);
            updateUserDetails(token, deleteApiArgs)
                .then((response) => {
                    if (response.response) {
                        handleCloseForm();
                        setIsLoader(false);
                        handleSnackOpen(response.responseMsg, "success");

                        getTableData();
                    } else {
                        handleCloseForm();
                        setIsLoader(false);
                        handleSnackOpen(response.responseMsg, "error");
                    }
                })
                .catch((error) => {
                    handleCloseForm();
                    setIsLoader(false);
                    handleSnackOpen("Exception : " + error, "error");
                });
        }
    };
    const [editUserId, setEditUserId] = useState(null);
    const dialogDraftValuesFn = (rowData) => {
        console.log(rowData);
        setEditUserId(rowData.userId);
        setFirstNameVal(rowData.userFirstName);
        setFirstNameHelperText("");
        setLastNameVal(rowData.userLastName);
        setLastNameHelperText("");
        setEmailVal(rowData.userEmail);
        setEmailHelperText("");
        setRoleVal(rowData.roleId);
        setRoleHelperText("");
        setRndLocationData(rowData.rdlocationId)
        setRndHelperText("");
        setUserVal(rowData.supplierLoginType)
        setUserNameHelperText("")
        if (rowData.roleId == 3) {
            setSupplierCodeVal(rowData.supplierCode);
            setSupplierVal(rowData.supplierId);
            const suppCodeVal = supplierDataNew.find(
                (x) => x.id == rowData.supplierId
            );
            setSupplierIdVal(suppCodeVal != undefined ? suppCodeVal.id : null);
            setSupplierHelperText("");
            setGidVal("");
            setIsGidEnabled(false);
            setIsSupplierEnabled(true);
        } else {
            setGidVal(rowData.gid);
            setSupplierVal("");
            setSupplierCodeVal("");
            setGidHelperText("");
            setIsGidEnabled(true);
            setIsSupplierEnabled(false);
            setSupplierHelperText("");
        }
        setFormDialogTitle("Update");
        setOpenForm(true);
    };
    const clearFormValues = () => {
        setIsGidEnabled(false);
        setIsSupplierEnabled(false);
        setFirstNameVal("");
        setLastNameVal("");
        setEmailVal("");
        setRoleVal("");
        setGidVal("");
        setSupplierVal("");
        setSupplierCodeVal("");
        setSupplierIdVal("");
        setRndLocationData("");
        setFirstNameHelperText("Required **");
        setLastNameHelperText("Required **");
        setEmailHelperText("Required **");
        setGidHelperText("");
        setRndHelperText("");
        setRoleHelperText("Required **");
        setSupplierHelperText("");
    };
    function GetEditButton() {
        return (
            <Tooltip title="Edit" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <EditOutlinedIcon fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    function GetDeleteButton() {
        return (
            <Tooltip title="Delete" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <DeleteOutlined fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }

    const [isGidEnabled, setIsGidEnabled] = useState(false);
    const [isSupplierEnabled, setIsSupplierEnabled] = useState(false);
    const [isRNDEnabled, setIsRNDEnabled] = useState(false);
    const [firstNameVal, setFirstNameVal] = useState("");
    const [lastNameVal, setLastNameVal] = useState("");
    const [emailVal, setEmailVal] = useState("");
    const [roleVal, setRoleVal] = useState("");
    const [gidVal, setGidVal] = useState("");
    const [userVal, setUserVal] = useState(3);
    const [supplierVal, setSupplierVal] = useState("");
    const [supplierCodeVal, setSupplierCodeVal] = useState("");
    const [supplierIdVal, setSupplierIdVal] = useState("");
    const [firstNameHelperText, setFirstNameHelperText] = useState("Required **");
    const [lastNameHelperText, setLastNameHelperText] = useState("Required **");
    const [userNameHelperText, setUserNameHelperText] = useState(" ");
    const [emailHelperText, setEmailHelperText] = useState("Required **");
    const [gidHelperText, setGidHelperText] = useState("");
    const [rndHelperText, setRndHelperText] = useState("");
    const [roleHelperText, setRoleHelperText] = useState("Required **");
    const [supplierHelperText, setSupplierHelperText] = useState("");
    const [reportInputs, setReportInputs] = useState({
        
        userType: 1,
       
    });
    const handleNameChange = (e) => {
        const enteredVal = e.target.value;
        if (isValidNameField(enteredVal)) {
            setFirstNameVal(enteredVal);
            setFirstNameHelperText("");
        } else {
            setFirstNameHelperText("Enter Valid First Name");
            setFirstNameVal(enteredVal);
        }
    };
    const handleLastNameChange = (e) => {
        const enteredVal = e.target.value;
        if (isValidNameField(enteredVal)) {
            setLastNameVal(enteredVal);
            setLastNameHelperText("");
        } else {
            setLastNameHelperText("Enter Valid Last Name");
            setLastNameVal(enteredVal);
        }
    };
    const handleEmailChange = (e) => {
        const enteredVal = e.target.value;
        if (isValidEmail(enteredVal)) {
            setEmailVal(enteredVal);
            setEmailHelperText("");
        } else {
            setEmailHelperText("Enter Valid Email");
            setEmailVal(enteredVal);
        }
    };
    const handleGidOnChange = (e) => {
        const enteredVal = e.target.value;
        if (isValidGidEntered(enteredVal)) {
            setGidVal(enteredVal);
            setGidHelperText("");
        } else {
            setGidHelperText("Enter Valid Gid");
            setGidVal(enteredVal);
        }
    };
    const handleRoleChange = (e) => {
        const enteredVal = e.target.value;
        setRoleVal(enteredVal);
        setRoleHelperText("");
        switch (enteredVal) {
            case 1:
                setGidVal("");
                setSupplierVal("");
                setSupplierCodeVal("");
                setIsGidEnabled(true);
                setIsSupplierEnabled(false);
                setIsRNDEnabled(false);
                setGidHelperText("Required **");
                setRndHelperText("");
                setSupplierHelperText("");
                setUserVal(3);
                break;
            case 2:
                setGidVal("");
                setSupplierVal("");
                setSupplierCodeVal("");
                setIsGidEnabled(true);
                setIsRNDEnabled(false);
                setIsSupplierEnabled(false);
                setGidHelperText("Required **");
                setRndHelperText("");
                setSupplierHelperText("");
                setUserVal(3);
                break;
            case 3:
                setGidVal("");
                setSupplierVal("");
                setSupplierCodeVal("");
                setIsGidEnabled(false);
                setIsRNDEnabled(false);
                setIsSupplierEnabled(true);
                setGidHelperText("");
                setSupplierHelperText("Required **");
                setRndHelperText("");
                setUserVal(3);
                break;
            case 6:
                setGidVal("");
                setSupplierVal("");
                setSupplierCodeVal("");
                setIsGidEnabled(true);
                setIsRNDEnabled(true);
                setIsSupplierEnabled(false);
                setGidHelperText("Required **");
                setRndHelperText("");
                setSupplierHelperText("");
                setUserVal(3);
                break;
            case 7:
                setGidVal("");
                setSupplierVal("");
                setSupplierCodeVal("");
                setIsGidEnabled(true);
                setIsRNDEnabled(true);
                setIsSupplierEnabled(false);
                setGidHelperText("Required **");
                setRndHelperText("Required **");
                setSupplierHelperText("");
                setUserVal(2);
                break;
            case 8:
                setGidVal("");
                setSupplierVal("");
                setSupplierCodeVal("");
                setRndHelperText("");
                setIsGidEnabled(true);
                setIsRNDEnabled(true);
                setIsSupplierEnabled(false);
                setGidHelperText("Required **");
                setSupplierHelperText("");
                setUserVal(2);
                break;
            case 9:
                setGidVal("");
                setSupplierVal("");
                setSupplierCodeVal("");
                setIsGidEnabled(true);
                setIsRNDEnabled(true);
                setRndHelperText("");
                setIsSupplierEnabled(false);
                setGidHelperText("Required **");
                setSupplierHelperText("");
                setUserVal(2);
                break;
            case 10:
                setGidVal("");
                setSupplierVal("");
                setSupplierCodeVal("");
                setIsGidEnabled(true);
                setIsRNDEnabled(true);
                setIsSupplierEnabled(false);
                setGidHelperText("Required **");
                setRndHelperText("");
                setSupplierHelperText("");
                setUserVal(2);
                break;
            default:
                setGidVal("");
                setSupplierVal("");
                setSupplierCodeVal("");
                setIsGidEnabled(false);
                setIsSupplierEnabled(false);
                setIsRNDEnabled(false);
                setGidHelperText("Required **");
                setGidHelperText("");
                setRndHelperText("");
        }
    };
    const handleSupplierChange = (e) => {
        const enteredVal = e.target.value;
        setSupplierVal(enteredVal);
        const suppCodeVal = supplierDataNew.find((x) => x.id == enteredVal);
        setSupplierCodeVal(suppCodeVal != undefined ? suppCodeVal.code : "");
        setSupplierIdVal(suppCodeVal != undefined ? suppCodeVal.id : null);
        setSupplierHelperText("");
    };
    const [rndLocation, setRndLocation] = useState([]);
    const [rndLocationData, setRndLocationData] = useState();
   
    const handleRndLocation = (e) => {
       
        
        console.log("rndlocationvalue", e.target.value);
        setRndLocationData(e.target.value);
        //console.log(e.target.value);
        setRndHelperText("");
    
    }
   
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    const [roleData, setRoleData] = useState([]);
    const [suppliercodeValue, setSupplierCodeValue] = useState("");
    async function getRoleDetails() {
        const response = await getRoleDDL(token, userDetails.userId);
        if (response && response.length > 0) {
            setRoleData(response);
        } else {
            setRoleData([]);
        }
    }
    function renderRoleValue(value) {
        let obj = {};
        if (roleData && roleData.length > 0) {
            obj = roleData.find((c) => c.id == value);
        }
        if (obj) {
            return obj.name;
        } else {
            return "";
        }
    }
    const [supplierData, setSupplierData] = useState([]);
    const [supplierDataNew, setSupplierDataNew] = useState([]);
    async function getSupplierDetails() {
        const response = await getSupplierDDL(token, userDetails.userId);
        if (response && response.length > 0) {
            setSupplierDataNew(response);
            setSupplierData(
                response.map((item) => ({
                    value: item.id,
                    label: item.name,
                    code: item.code,
                }))
            );
        } else {
            setSupplierData([]);
        }
    }
    function renderSupplierValue(value) {
        let obj = {};
        if (supplierData && supplierData.length > 0) {
            obj = supplierData.find((c) => c.value == value);
        }
        if (obj) {
            return obj.label;
        } else {
            return "";
        }
    }
    const handleBudgetDDLValue = (e) => {
        console.log(e.target.value);
        setReportInputs({
            ...reportInputs,
            userType: e.target.value,
        });
        setUserVal(e.target.value);
       // console.log(userVal);
    }
   
    // function renderSupplierCode(value) {
    //   let obj = {};
    //   if (supplierData && supplierData.length > 0) {
    //     obj = supplierData.find((c) => c.value == value);
    //   }
    //   if (obj) {
    //     return obj.label;
    //   } else {
    //     return "";
    //   }
    // }

    function isValidEmail(email) {
        const re =
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        return re.test(String(email).toLowerCase());
    }
    function isValidNameField(name) {
        const re = /^[a-zA-Z ]*$/;
        return re.test(name);
    }
    // function isValidNumberField(number) {
    //   const re = /[0-9]{10}/;
    //   return re.test(number);
    // }
    function validateAlphabet(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : !isValidNameField(value)
                ? { isValid: false, helperText: "Only alphabets" }
                : { isValid: true, helperText: "" };
    }
    function validateRequired(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : { isValid: true, helperText: "" };
    }
    function validateSupplierSelect(value) {
        return value.roleId == 3 &&
            (suppliercodeValue == "" || suppliercodeValue == null)
            ? { isValid: false, helperText: "*Required" }
            : { isValid: true, helperText: "" };
    }
    function isValidGidPerRoleId(value) {
        const arr = [1, 2];
        return arr.includes(value);
    }
    function isValidGidEntered(value) {
        const regexValidation = /^[a-zA-Z0-9]+$/;
        return regexValidation.test(value);
    }
    function validateGID(data) {
        return isValidGidPerRoleId(data.roleId) && !data.gid
            ? { isValid: false, helperText: "*Required" }
            : { isValid: true, helperText: "" };
    }

    function validateEmail(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : !isValidEmail(value)
                ? { isValid: false, helperText: "*invalid Email" }
                : { isValid: true, helperText: "" };
    }
    const columns = [
        {
            title: "First Name",
            field: "userFirstName",
            validate: (rowData) => validateAlphabet(rowData.userFirstName),
        },
        {
            title: "Last Name",
            field: "userLastName",
            validate: (rowData) => validateAlphabet(rowData.userLastName),
        },
        {
            title: "Email",
            field: "userEmail",
            validate: (rowData) => validateEmail(rowData.userEmail),
        },
        {
            title: "Role",
            field: "roleId",
            render: (rowData) => <span>{renderRoleValue(rowData.roleId)}</span>,
            editComponent: (props) => getRoleComponent(props),
            validate: (rowData) => validateRequired(rowData.roleId),
        },
        {
            title: "GID",
            field: "gid",
            validate: (rowData) => validateGID(rowData),
            editComponent: (props) => getGIDComponent(props),
        },
        {
            title: "Supplier",
            field: "supplierName",
            //render: (rowData) => (
            //    <span>{renderSupplierValue(rowData.supplierId)}</span>
            //),
            editComponent: (props) => getSupplierComponent(props),
            validate: (rowData) => validateSupplierSelect(rowData),
        },
        {
            title: "Supplier Code",
            field: "supplierCode",
            editComponent: (props) => getSupplierCodeComponent(props),
        },
    ];
    function rdLocation() {
        // console.log("rdlocation");
        getRDLocation(token, userDetails.userId)
            .then((response) => {
                // console.log(response);
                setRndLocation(response);
                // console.log(rndLocation);

            })
            .catch((error) => {
                console.log(error);

            });
    };
    function getRoleComponent(objProps) {
        const Role = objProps.rowData.roleId
            ? roleData.find((x) => x.value === objProps.rowData.roleId)
            : null;
        return (
            <FormControl error={!objProps.value ? true : false} fullWidth>
                <Select
                    theme={theme}
                    options={roleData}
                    value={
                        typeof objProps.value === "string" ||
                            typeof objProps.value === "number"
                            ? Role
                            : objProps.value
                    }
                    onChange={(opt) => {
                        objProps.onChange(opt?.value);
                        delete objProps.rowData.supplierId;
                        // objProps.rowData.supplierId = null;
                        setSupplierCodeValue("");
                    }}
                    styles={customStylesDefault}
                    placeholder=" Role"
                    isClearable
                />
                <>
                    <FormHelperText>{!objProps.value ? "*Required" : ""}</FormHelperText>
                </>
            </FormControl>
        );
    }
    function getGIDComponent(objProps) {
        const rowData = objProps.rowData;
        let value = rowData.gid;
        if (!isValidGidPerRoleId(rowData.roleId)) {
            value = "";
        }
        return (
            <TextField
                id="standard-basic"
                value={value}
                placeholder="GID"
                onChange={(e) => objProps.onChange(e.target.value)}
                disabled={!isValidGidPerRoleId(rowData.roleId)}
                error={isValidGidPerRoleId(rowData.roleId) && !value}
                helperText={
                    isValidGidPerRoleId(rowData.roleId) && !value ? "*Required" : ""
                }
            />
        );
    }
    function getSupplierComponent(objProps) {
        const rowData = objProps.rowData;
        let value = rowData.supplierId;
        // if (!isValidGidPerRoleId(rowData.roleId)) {
        //   objProps.rowData.supplierId = ""
        // }
        const Supplier = objProps.rowData.supplierId
            ? supplierData.find((x) => x.value === rowData.supplierId)
            : null;
        return (
            <FormControl error={!objProps.value ? true : false} fullWidth>
                <Select
                    theme={theme}
                    options={supplierData}
                    value={
                        typeof objProps.value === "string" ||
                            typeof objProps.value === "number"
                            ? Supplier
                            : objProps.value
                    }
                    onChange={(opt) => {
                        objProps.onChange(opt?.value);
                        setSupplierCodeValue(opt?.code);
                    }}
                    isDisabled={rowData.roleId == 3 ? false : true}
                    styles={customStylesDefault}
                    placeholder=" Supplier"
                    isClearable
                />
                <>
                    <FormHelperText>
                        {!objProps.value && rowData.roleId == 3 ? "*Required" : ""}
                    </FormHelperText>
                </>
            </FormControl>
        );
    }
    function getSupplierCodeComponent(objProps) {
        return (
            <TextField
                value={suppliercodeValue}
                fullWidth
                placeholder=" SupplierCode"
            />
        );
    }
    useEffect(() => {
        getTableData();
        getRoleDetails();
        getSupplierDetails();
        rdLocation();
        console.log(userVal);
       
    }, []);
    function getTableData() {
        setIsLoader(true);
        getUserDetails(token, userDetails.userId)
            .then((response) => {
                console.log("Tab;le Data", response);
                setTableData(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    useEffect(() => {
        getTableData();
    }, [userDetails]);

    // const handleRowUpdate = (newTableData, oldTableData, resolve, reject) => {
    //   setIsLoader(true);
    //   newTableData.loggedinUserId = userDetails.userId;
    //   newTableData.supplierCode = suppliercodeValue;
    //   updateUserDetails(token, newTableData)
    //     .then((response) => {
    //       if (response.response) {
    //         handleSnackOpen(response.responseMsg, "success");
    //         resolve();
    //         setIsLoader(false);
    //         getTableData();
    //       } else {
    //         handleSnackOpen(response.responseMsg, "error");
    //         reject();
    //         setIsLoader(false);
    //       }
    //     })
    //     .catch((error) => {
    //       handleSnackOpen("Exception : " + error, "error");
    //       reject();
    //       setIsLoader(false);
    //     });
    // };
    // const handleRowAdd = (newTableData, resolve, reject) => {
    //   setIsLoader(true);
    //   newTableData.loggedinUserId = userDetails.userId;
    //   newTableData.supplierCode = suppliercodeValue;
    //   addUserDetails(token, newTableData)
    //     .then((response) => {
    //       if (response.response) {
    //         handleSnackOpen(response.responseMsg, "success");
    //         setSupplierCodeValue("");
    //         resolve();
    //         setIsLoader(false);
    //         getTableData();
    //       } else {
    //         handleSnackOpen(response.responseMsg, "error");
    //         reject();
    //         setIsLoader(false);
    //       }
    //     })
    //     .catch((error) => {
    //       handleSnackOpen("Exception : " + error, "error");
    //       reject();
    //       setIsLoader(false);
    //     });
    // };
    // const handleRowDelete = (oldTableData, resolve, reject) => {
    //   setIsLoader(true);
    //   oldTableData.loggedinUserId = userDetails.userId;
    //   deleteUserDetails(token, oldTableData)
    //     .then((response) => {
    //       if (response.response) {
    //         handleSnackOpen(response.responseMsg, "success");
    //         resolve();
    //         setIsLoader(false);
    //         getTableData();
    //       } else {
    //         handleSnackOpen(response.responseMsg, "error");
    //         reject();
    //         setIsLoader(false);
    //       }
    //     })
    //     .catch((error) => {
    //       handleSnackOpen("Exception : " + error, "error");
    //       reject();
    //       setIsLoader(false);
    //     });
    // };

    return (
        <>
            <div className="App table-padding">
                <Grid container spacing={1}>
                    <Grid item xs={12} className="d-flex jc-space-bt">
                        <Grid item xs={9} className="text-left ml-1">
                            <h2>User Data</h2>
                        </Grid>
                        <Grid item xs={3} className="text-right"></Grid>
                    </Grid>
                    {/* <Grid item xs={12}>
            <MaterialTable
              title="User List"
              columns={columns}
              data={tableData}
              icons={tableIconsInputScreen}
              isLoading={isLoader}
              options={tableOptionsUser}
              editable={{
                onRowUpdate: (newTableData, oldTableData) =>
                  new Promise((resolve, reject) => {
                    handleRowUpdate(
                      newTableData,
                      oldTableData,
                      resolve,
                      reject
                    );
                  }),
                onRowAdd: (newTableData) =>
                  new Promise((resolve, reject) => {
                    handleRowAdd(newTableData, resolve, reject);
                  }),
                onRowAddCancelled: () => {
                  setSupplierCodeValue("");
                },
                onRowDelete: (oldTableData) =>
                  new Promise((resolve, reject) => {
                    handleRowDelete(oldTableData, resolve, reject);
                  }),
              }}
            />
          </Grid> */}

                    {/* New */}
                    <Grid item xs={12}>
                        <MaterialTable
                            title="User List"
                            columns={columns}
                            data={tableData}
                            icons={tableIconsInputScreen}
                            isLoading={isLoader}
                            options={tableOptionsUser}
                            actions={[
                                {
                                    icon: "add",
                                    isFreeAction: true,
                                    onClick: () => {
                                        setFormDialogTitle("Save");
                                        setOpenForm(true);
                                        setUserVal(3);
                                    },
                                },
                                {
                                    icon: GetEditButton,
                                    tisFreeAction: true,
                                    onClick: (e, rowData) => {
                                        console.log("Row Data", rowData);
                                        dialogDraftValuesFn(rowData);
                                    },
                                },
                                {
                                    icon: GetDeleteButton,
                                    tisFreeAction: true,
                                    onClick: (e, rowData) => {
                                        console.log("Row Data", rowData);
                                        handleDeleteIconClick(rowData);
                                    },
                                },
                            ]}
                        // editable={{
                        //   onRowUpdate: (newTableData, oldTableData) =>
                        //     new Promise((resolve, reject) => {
                        //       handleRowUpdate(
                        //         newTableData,
                        //         oldTableData,
                        //         resolve,
                        //         reject
                        //       );
                        //     }),
                        //   onRowAdd: (newTableData) =>
                        //     new Promise((resolve, reject) => {
                        //       handleRowAdd(newTableData, resolve, reject);
                        //     }),
                        //   onRowAddCancelled: () => {
                        //     setSupplierCodeValue("");
                        //   },
                        //   onRowDelete: (oldTableData) =>
                        //     new Promise((resolve, reject) => {
                        //       handleRowDelete(oldTableData, resolve, reject);
                        //     }),
                        // }}
                        />
                    </Grid>
                    <>
                        <SnackbarCustom
                            open={open}
                            message={snackMessage}
                            alertType={alertType}
                            handleClose={handleClose}
                        />
                    </>
                </Grid>
            </div>

            <Dialog
                onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                        handleCloseForm();
                    }
                }}
                aria-labelledby="litigation-form--registration"
                open={openForm}
                maxWidth={"lg"}
                disableEscapeKeyDown
            >
                <DialogTitle id="responsive-dialog-title">Add Row</DialogTitle>
                <div style={{ position: "absolute", right: "1%" }}>
                    <Tooltip title="Close">
                        <IconButton onClick={handleCloseForm}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    name="firstName"
                                    label="First Name"
                                    variant="filled"
                                    value={firstNameVal}
                                    helperText={firstNameHelperText}
                                    onChange={handleNameChange}
                                    id="firstName"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    name="lastName"
                                    label="Last Name"
                                    variant="filled"
                                    value={lastNameVal}
                                    helperText={lastNameHelperText}
                                    onChange={handleLastNameChange}
                                    id="lastName"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    name="email"
                                    label="Email"
                                    variant="filled"
                                    value={emailVal}
                                    helperText={emailHelperText}
                                    onChange={handleEmailChange}
                                    id="email"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <FormControl variant="filled" fullWidth>
                                    <InputLabel id="efficiency-label">Role</InputLabel>
                                    <Select
                                        name="efficiencyClass"
                                        label="demo-simple-select-helper-label"
                                        id="efficiencyClass"
                                        value={roleVal}
                                        onChange={handleRoleChange}
                                    >
                                        <MenuItem value="" disabled>
                                            -- Select --
                                        </MenuItem>
                                        {roleData.map((item, index) => (
                                            <MenuItem key={index} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{roleHelperText}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    name="gid"
                                    label="GID"
                                    variant="filled"
                                    value={gidVal}
                                    helperText={gidHelperText}
                                    onChange={handleGidOnChange}
                                    id="gid"
                                    disabled={!isGidEnabled}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                 <FormControl variant="filled" fullWidth>
                                    <InputLabel id="efficiency-label"> R&D Location</InputLabel>
                                       
                                   
                                    <Select
                                        name="efficiencyClass"
                                        label="demo-simple-select-helper-label"
                                        id="efficiencyClass"
                                        disabled={userDetails.roleId == 1 || userDetails.roleId == 2 || userDetails.roleId == 3}
                                        onChange={handleRndLocation}
                                        value={rndLocationData}
                                        disabled={!isRNDEnabled}
                                        
                                    >
                                        {rndLocation.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{rndHelperText}</FormHelperText>
                                </FormControl>
                            </Grid> 

                            <Grid item xs={12} sm={4} md={3}>
                                <FormControl variant="filled" fullWidth>
                                    <InputLabel id="efficiency-label">Supplier</InputLabel>
                                    <Select
                                        name="efficiencyClass"
                                        label="demo-simple-select-helper-label"
                                        id="efficiencyClass"
                                        value={supplierVal}
                                       
                                        onChange={handleSupplierChange}
                                        disabled={!isSupplierEnabled}
                                    >
                                        <MenuItem value="" disabled>
                                            -- Select --
                                        </MenuItem>
                                        {supplierDataNew.map((item, index) => (
                                            <MenuItem key={index} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{supplierHelperText}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    name="supplierCode"
                                    label="Supplier Code"
                                    variant="filled"
                                    value={supplierCodeVal}
                                    disabled
                                    id="supplierCode"
                                />
                            </Grid>
                            
                            <Grid item xs={12} sm={3} className="d-flex Width-100 mt-1">
                                <TextField className="lightInputFields  "
                                    select
                                    id=""
                                    //disabled={true}
                                    disabled={userDetails.roleId == 1 ||userDetails.roleId == 2 || userDetails.roleId == 3 || userDetails.roleId == 4 || userDetails.roleId == 5 ||userDetails.roleId == 6|| userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10}
                                    label="User Type"
                                    variant="filled"
                                    fullWidth
                                    helperText={userNameHelperText}
                                    
                                    name=""
                                    value={userVal}
                                    onChange={handleBudgetDDLValue}
                                >
                                    {/*<MenuItem value="">*/}
                                    {/*    <em>Select</em>*/}
                                    {/*</MenuItem>*/}
                                    <MenuItem value={3}> </MenuItem>
                                    <MenuItem value={1}>SQM User</MenuItem>
                                        <MenuItem value={2}>ISIR User</MenuItem>
                                       
                                </TextField>
                            </Grid>
                            


                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        fullWidth
                        className="pt-button--secondary"
                        id="addUserSave"
                        onClick={
                            formDialogTitle === "Save" ? handleSaveForm : handleUpdateForm
                        }
                    >
                        {formDialogTitle}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="responsive-dialog-title">Delete User</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure to Delete this User ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDialogRowDelete}
                        className="pt-button--primary"
                    >
                        Ok
                    </Button>
                    <Button
                        onClick={handleCloseDeleteDialog}
                        className="pt-button--secondary"
                        autoFocus
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            {isLoader ? <Loader loaderText="Loading...." /> : <></>}
        </>
    );
}
