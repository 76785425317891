import { baseApiURL } from "../../Utilities/Utility";
import axios from "axios";

  export function GetD1Details(siteToken,ComplaintNumber,userId) {
    const options = {
      method: "get",
      url: baseApiURL + "/Discipline/GetDiscipline1DetailsbyComplaintnum",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: { userId: userId,
        Complaintnumber:ComplaintNumber
    },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
  export function addD1Details(siteToken, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Discipline/PostDiscipline_1_Details", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
  }
  export function GetD3Details(siteToken,ComplaintNumber,userId) {
    const options = {
      method: "get",
      url: baseApiURL + "/Discipline/GetDiscipline3DetailsbyComplaintnum",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: { userId: userId,
        ComplaintNumber:ComplaintNumber
    },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
  export function addD3Details(siteToken, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Discipline/PostDiscipline_3_Details", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
  }
  export function GetD4WhyDetails(siteToken,ComplaintNumber,userId) {
    const options = {
      method: "get",
      url: baseApiURL + "/Discipline/GetDiscipline4_WhyDetailsbyComplaintnum",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: { userId: userId,
        ComplaintNumber:ComplaintNumber
    },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
  export function GetD4WhyNotDetails(siteToken,ComplaintNumber,userId) {
    const options = {
      method: "get",
      url: baseApiURL + "/Discipline/GetDiscipline4_WhyNotDetailsbyComplaintnum",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: { userId: userId,
        ComplaintNumber:ComplaintNumber
    },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
  export function PostD4WhyDetails(siteToken, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Discipline/PostDiscipline_4_WhyDetails", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
  }
  export function postD4WhyNotDetails(siteToken, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Discipline/PostDiscipline_4_WhyNotDetails", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
  }

  export function GetD5Section1Details(siteToken,ComplaintNumber,userId) {
    const options = {
      method: "get",
      url: baseApiURL + "/Discipline/GetDiscipline5_Section1DetailsbyComplaintnum",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: { userId: userId,
        ComplaintNumber:ComplaintNumber
    },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
  export function GetD5Section2Details(siteToken,ComplaintNumber,userId) {
    const options = {
      method: "get",
      url: baseApiURL + "/Discipline/GetDiscipline5_Section2DetailsbyComplaintnum",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: { userId: userId,
        ComplaintNumber:ComplaintNumber
    },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
  export function PostD5Section1Details(siteToken, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Discipline/PostDiscipline_5_Sec1_Details", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
  }
  export function postD5Section2Details(siteToken, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Discipline/PostDiscipline_5_Sec2_Details", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
  }
  export function GetD6Details(siteToken,ComplaintNumber,userId) {
    const options = {
      method: "get",
      url: baseApiURL + "/Discipline/GetDiscipline6DetailsbyComplaintnum",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: { userId: userId,
        ComplaintNumber:ComplaintNumber
    },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
  export function PostD6Details(siteToken, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Discipline/PostDiscipline_6_Details", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
  }
  export function GetD7Details(siteToken,ComplaintNumber,userId) {
    const options = {
      method: "get",
      url: baseApiURL + "/Discipline/GetDiscipline7DetailsbyComplaintnum",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: { userId: userId,
        ComplaintNumber:ComplaintNumber
    },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
  export function PostD7Details(siteToken, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Discipline/PostDiscipline_7_Details", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
  }
  export function GetD4Details(siteToken,ComplaintNumber,userId) {
    const options = {
      method: "get",
      url: baseApiURL + "/Discipline/GetDiscipline4DetailsbyComplaintnum",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: { userId: userId,
        ComplaintNumber:ComplaintNumber
    },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
  export function GetD2Details(siteToken,ComplaintNumber,userId) {
    const options = {
      method: "get",
      url: baseApiURL + "/Discipline/GetDiscipline2DetailsbyComplaintnum",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: { userId: userId,
        ComplaintNumber:ComplaintNumber
    },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
  export function PostD4Details(siteToken, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Discipline/PostDiscipline_4_Details", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
  }
  export function GetD8Details(siteToken,ComplaintNumber,userId) {
    const options = {
      method: "get",
      url: baseApiURL + "/Discipline/GetDiscipline8DetailsbyComplaintnum",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: { userId: userId,
        ComplaintNumber:ComplaintNumber
    },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
  export function PostD8Details(siteToken, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Discipline/PostDiscipline_8_Details", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
  }
  export function PostD2Details(siteToken, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Discipline/PostDiscipline_2_Details", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
  }
  export function GetDisciplineAttachmentDetails(siteToken, userId,complaintNumber,discipline) {
    const options = {
      headers: { Authorization: "Bearer " + siteToken },
      params: { userId: userId ,complaintNumber:complaintNumber,Discipline:discipline },
    };
    return axios.get(
      baseApiURL + "/Discipline/GetDisciplineAttachments/",
      options
    )
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
  }
    export function AddDisciplineAttachmentDetails(siteToken,formData) {
      const options = {
          method: "post",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + siteToken,
          },
          body: formData,
        };
        return fetch(baseApiURL + "/Discipline/UploadDisciplineAttachments", options)
          .then((response) => response.json())
          .then((data) => {
            return data.result;
          })
          .catch((error) => {
            console.log("Error : ", error);
          });
    }
    export function UpdateD1(siteToken, data) {
      const options = {
          method: "put",
          headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: "Bearer " + siteToken,
          },
          body: JSON.stringify(data),
      };
      return fetch(baseApiURL + "/Discipline/UpdateD1", options)
          .then((response) => response.json())
          .then((data) => {
              return data.result;
          })
          .catch((error) => {
              console.log("Error : ", error);
          });
    }
    export function UpdateD2(siteToken, data) {
      const options = {
          method: "put",
          headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: "Bearer " + siteToken,
          },
          body: JSON.stringify(data),
      };
      return fetch(baseApiURL + "/Discipline/UpdateD2", options)
          .then((response) => response.json())
          .then((data) => {
              return data.result;
          })
          .catch((error) => {
              console.log("Error : ", error);
          });
    }
    export function UpdateD3(siteToken, data) {
      const options = {
          method: "put",
          headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: "Bearer " + siteToken,
          },
          body: JSON.stringify(data),
      };
      return fetch(baseApiURL + "/Discipline/UpdateD3", options)
          .then((response) => response.json())
          .then((data) => {
              return data.result;
          })
          .catch((error) => {
              console.log("Error : ", error);
          });
    }
    export function UpdateD4(siteToken, data) {
      const options = {
          method: "put",
          headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: "Bearer " + siteToken,
          },
          body: JSON.stringify(data),
      };
      return fetch(baseApiURL + "/Discipline/UpdateD4", options)
          .then((response) => response.json())
          .then((data) => {
              return data.result;
          })
          .catch((error) => {
              console.log("Error : ", error);
          });
    }
    export function UpdateD4_Why(siteToken, data) {
      const options = {
          method: "put",
          headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: "Bearer " + siteToken,
          },
          body: JSON.stringify(data),
      };
      return fetch(baseApiURL + "/Discipline/UpdateD4_Why", options)
          .then((response) => response.json())
          .then((data) => {
              return data.result;
          })
          .catch((error) => {
              console.log("Error : ", error);
          });
    }
    export function UpdateD4_WhyNot(siteToken, data) {
      const options = {
          method: "put",
          headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: "Bearer " + siteToken,
          },
          body: JSON.stringify(data),
      };
      return fetch(baseApiURL + "/Discipline/UpdateD4_WhyNot", options)
          .then((response) => response.json())
          .then((data) => {
              return data.result;
          })
          .catch((error) => {
              console.log("Error : ", error);
          });
    }
    export function UpdateD5_Section1(siteToken, data) {
      const options = {
          method: "put",
          headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: "Bearer " + siteToken,
          },
          body: JSON.stringify(data),
      };
      return fetch(baseApiURL + "/Discipline/UpdateD5_Sec1", options)
          .then((response) => response.json())
          .then((data) => {
              return data.result;
          })
          .catch((error) => {
              console.log("Error : ", error);
          });
    }
    export function UpdateD5_Section2(siteToken, data) {
      const options = {
          method: "put",
          headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: "Bearer " + siteToken,
          },
          body: JSON.stringify(data),
      };
      return fetch(baseApiURL + "/Discipline/UpdateD5_Sec2", options)
          .then((response) => response.json())
          .then((data) => {
              return data.result;
          })
          .catch((error) => {
              console.log("Error : ", error);
          });
    }
    export function UpdateD6(siteToken, data) {
      const options = {
          method: "put",
          headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: "Bearer " + siteToken,
          },
          body: JSON.stringify(data),
      };
      return fetch(baseApiURL + "/Discipline/UpdateD6", options)
          .then((response) => response.json())
          .then((data) => {
              return data.result;
          })
          .catch((error) => {
              console.log("Error : ", error);
          });
    }
    export function UpdateD7(siteToken, data) {
      const options = {
          method: "put",
          headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: "Bearer " + siteToken,
          },
          body: JSON.stringify(data),
      };
      return fetch(baseApiURL + "/Discipline/UpdateD7", options)
          .then((response) => response.json())
          .then((data) => {
              return data.result;
          })
          .catch((error) => {
              console.log("Error : ", error);
          });
    }
    export function UpdateD8(siteToken, data) {
      const options = {
          method: "put",
          headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: "Bearer " + siteToken,
          },
          body: JSON.stringify(data),
      };
      return fetch(baseApiURL + "/Discipline/UpdateD8", options)
          .then((response) => response.json())
          .then((data) => {
              return data.result;
          })
          .catch((error) => {
              console.log("Error : ", error);
          });
    }
    export function Report8DDownload(siteToken, data) {
      const options = {
        responseType: "arraybuffer",
        headers: {
          Authorization: "Bearer " + siteToken.token,
        },
      };
      return axios
        .post(
          baseApiURL + "/Report/Get8DCloseDateReport",
          data,
          options
        )
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log("Error : ", error);
        });
    }
    export function GetD6TextDetails(siteToken,ComplaintNumber,userId) {
      const options = {
        method: "get",
        url: baseApiURL + "/Discipline/GetDiscipline6CorrectivetextDetailsbyComplaintnum",
        headers: {
          Authorization: "Bearer " + siteToken,
        },
        params: { userId: userId,
          ComplaintNumber:ComplaintNumber
      },
      };
      return (
        axios(options)
          .then((response) => {
            return response.data.result;
          })
          .catch((error) => {
            console.log("Error : ", error);
          })
      );
    }
    export function GetD7TextDetails(siteToken,ComplaintNumber,userId) {
      const options = {
        method: "get",
        url: baseApiURL + "/Discipline/GetDiscipline7FinalSummaryDetailsbyComplaintnum",
        headers: {
          Authorization: "Bearer " + siteToken,
        },
        params: { userId: userId,
          ComplaintNumber:ComplaintNumber
      },
      };
      return (
        axios(options)
          .then((response) => {
            return response.data.result;
          })
          .catch((error) => {
            console.log("Error : ", error);
          })
      );
    }
    export function PostD6TextDetails(siteToken, data) {
      const options = {
          method: "post",
          headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: "Bearer " + siteToken,
          },
          body: JSON.stringify(data),
      };
      return fetch(baseApiURL + "/Discipline/PostDiscipline_6_CorrectiveTextDetails", options)
          .then((response) => response.json())
          .then((data) => {
              return data.result;
          })
          .catch((error) => {
              console.log("Error : ", error);
          });
    }
    export function PostD7TextDetails(siteToken, data) {
      const options = {
          method: "post",
          headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: "Bearer " + siteToken,
          },
          body: JSON.stringify(data),
      };
      return fetch(baseApiURL + "/Discipline/PostDiscipline_7_FinalSummaryDetails", options)
          .then((response) => response.json())
          .then((data) => {
              return data.result;
          })
          .catch((error) => {
              console.log("Error : ", error);
          });
    }
    export function UpdateD6TextDetails(siteToken, data) {
      const options = {
          method: "put",
          headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: "Bearer " + siteToken,
          },
          body: JSON.stringify(data),
      };
      return fetch(baseApiURL + "/Discipline/UpdateD6textfields", options)
          .then((response) => response.json())
          .then((data) => {
              return data.result;
          })
          .catch((error) => {
              console.log("Error : ", error);
          });
    }
    export function UpdateD7TextDetails(siteToken, data) {
      const options = {
          method: "put",
          headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: "Bearer " + siteToken,
          },
          body: JSON.stringify(data),
      };
      return fetch(baseApiURL + "/Discipline/UpdateD7FinalSummary", options)
          .then((response) => response.json())
          .then((data) => {
              return data.result;
          })
          .catch((error) => {
              console.log("Error : ", error);
          });
    }
   
    export function Generate8DReportPdf(siteToken,userId,ComplaintNumber) {
      const options = {
        method: "get",
        responseType: "arraybuffer",
        url: baseApiURL + "/Discipline/GeneratePDF",
        headers: {
          Authorization: "Bearer " + siteToken,
        },
        params: { userId: userId,
          ComplaintNumber:ComplaintNumber
      },
      };
      return (
        axios(options)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            console.log("Error : ", error);
          })
      );
    }
    export function DeleteD1Details(siteToken,formData) {

      const options = {
          method: "put",
          headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: "Bearer " + siteToken,
          },
          body: JSON.stringify(formData),
      };
      return fetch(baseApiURL + "/Discipline/DeleteD1Details", options)
          .then((response) => response.json())
          .then((data) => {
              return data.result;
          })
          .catch((error) => {
              console.log("Error : ", error);
          });
}
export function DeleteD3Details(siteToken,formData) {

  const options = {
      method: "put",
      headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + siteToken,
      },
      body: JSON.stringify(formData),
  };
  return fetch(baseApiURL + "/Discipline/DeleteD3Details", options)
      .then((response) => response.json())
      .then((data) => {
          return data.result;
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
}
export function DeleteD4WhyDetails(siteToken,formData) {
  const options = {
      method: "put",
      headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + siteToken,
      },
      body: JSON.stringify(formData),
  };
  return fetch(baseApiURL + "/Discipline/DeleteD4WhyDetails", options)
      .then((response) => response.json())
      .then((data) => {
          return data.result;
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
}
export function DeleteD4WhyNotDetails(siteToken,formData) {
  const options = {
      method: "put",
      headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + siteToken,
      },
      body: JSON.stringify(formData),
  };
  return fetch(baseApiURL + "/Discipline/DeleteD4WhyNotDetails", options)
      .then((response) => response.json())
      .then((data) => {
          return data.result;
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
}
export function DeleteD5Sec1(siteToken,formData) {
  const options = {
      method: "put",
      headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + siteToken,
      },
      body: JSON.stringify(formData),
  };
  return fetch(baseApiURL + "/Discipline/DeleteD5Sec1Details", options)
      .then((response) => response.json())
      .then((data) => {
          return data.result;
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
}
export function DeleteD5Sec2(siteToken,formData) {
  const options = {
      method: "put",
      headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + siteToken,
      },
      body: JSON.stringify(formData),
  };
  return fetch(baseApiURL + "/Discipline/DeleteD5Sec2Details", options)
      .then((response) => response.json())
      .then((data) => {
          return data.result;
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
}
export function DeleteD6(siteToken,formData) {
  const options = {
      method: "put",
      headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + siteToken,
      },
      body: JSON.stringify(formData),
  };
  return fetch(baseApiURL + "/Discipline/DeleteD6Details", options)
      .then((response) => response.json())
      .then((data) => {
          return data.result;
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
}
export function DeleteD7(siteToken,formData) {
  const options = {
      method: "put",
      headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + siteToken,
      },
      body: JSON.stringify(formData),
  };
  return fetch(baseApiURL + "/Discipline/DeleteD7Details", options)
      .then((response) => response.json())
      .then((data) => {
          return data.result;
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
}