import { Grid, TextField, Button } from "@material-ui/core";
import { useState, useEffect } from "react";
import { baseApiURL, getTodayDateMoment, tableIcons } from "../../../Utilities/Utility";
import MaterialTable from "material-table";
import { tableOptionsExport } from "../../../Themes/LightTheme";
import { theme, customStylesDefault } from "../../../Utilities/Utility";
import { useSelector, useDispatch } from "react-redux";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import Loading from "../../../Components/Loader/Loader";
import Select from 'react-select'
import { useNavigate } from "react-router-dom";
import {
    FormControl,
    FormHelperText
} from "@material-ui/core";
import { getD1WhoDDL, getStatusDDL } from "../../../Redux/APIs/api_Common";
import { AddDisciplineAttachmentDetails, DeleteD5Sec1, DeleteD5Sec2, GetD5Section1Details, GetD5Section2Details, GetDisciplineAttachmentDetails, PostD5Section1Details, postD5Section2Details, UpdateD5_Section1, UpdateD5_Section2 } from "../../../Redux/APIs/api_Disciplines";
import { DeleteDisciplineAttachments } from "../../../Redux/APIs/api_Complaint";
import moment from "moment";
import { disciplineAction } from "../../../Redux/Actions/actions";
export default function Discipline5(props) {
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const dispatch = useDispatch();
    const discpline5Data = useSelector(
        (state) => state.disciplineReducer.disciplineData
    );
    const [tableData1, setTableData1] = useState([]);
    const [tableData2, setTableData2] = useState([]);
    const [isLoader1, setIsLoader1] = useState(false);
    const [isLoader2, setIsLoader2] = useState(false);
    const [isLoader3, setIsLoader3] = useState(false);
    const [attachmentData, setAttchmentData] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    let [inputFileAttachment, setInputFileAttachment] = useState(Date.now);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    function renderDate(value) {
        let DateFormat = !value ? null : moment(value).format('YYYY-MM-DD');
        return DateFormat;
    }
    const [whoData, setWhoData] = useState([]);
    async function getWhoDetails() {
        const response = await getD1WhoDDL(token, userDetails.userId, props?.rowData[0].complaintNumber);
        if (response && response.length > 0) {
            setWhoData(
                response.map((item, key) => ({ value: key, label: response[key] }))
            );
        } else {
            setWhoData([]);
        }
    }

    const [statusData, setStatusData] = useState([]);

    async function getStatusDetails() {
        const response = await getStatusDDL(token, userDetails.userId);
        if (response && response.length > 0) {
            setStatusData(
                response.map((item) => ({ value: item.id, label: item.name }))
            );
        } else {
            setStatusData([]);
        }
    }
    function renderStatusValue(value) {
        let obj = {};
        if (statusData && statusData.length > 0) {
            obj = statusData.find((c) => c.value === value);
        }
        if (obj) {
            return obj.label;
        } else {
            return "";
        }
    }
    function getStatusComponent(objProps) {
        const Status = objProps.rowData.statusId
            ? statusData.find((x) => x.value === objProps.rowData.statusId)
            : null;
        return (
            <FormControl error={!objProps.value ? true : false} fullWidth>
                <Select
                    theme={theme}
                    options={statusData}
                    value={
                        typeof objProps.value === "string" ||
                            typeof objProps.value === "number"
                            ? Status
                            : objProps.value
                    }
                    onChange={(opt) => {
                        objProps.onChange(opt?.value);
                    }}
                    styles={customStylesDefault}
                    placeholder="Status"
                    isClearable
                />
                <>
                    <FormHelperText>{!objProps.value ? "*Required" : ""}</FormHelperText>
                </>
            </FormControl>
        );
    }
    function validateTable1(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : { isValid: true, helperText: "" };
    }
    const column1 = [
        {
            title: "What",
            field: "whatD6",
            editComponent: ({ value, onChange }) => (
                <TextField
                    fullWidth
                    onChange={e => onChange(e.target.value)}
                    error={!value}
                    helperText={!value ? "required" : ""}
                    value={value}
                    multiline
                />
            ),
            validate: (rowData) => validateTable1(rowData.whatD6),
            cellStyle: {
                minWidth: 900,
                maxWidth: 900,
            },
        },
        {
            title: "Who",
            field: "whoD6",
            editComponent: (props) => getWhoComponent(props),
            validate: (rowData) => validateTable1(rowData.whoD6)
        },
        {
            title: "When",
            field: "whenD6",
            editComponent: (objProps) => getWhenComponent(objProps),
            validate: (rowData) => validateTable1(rowData.whenD6),
            render: (rowData) => <span>{renderDate(rowData.whenD6)}</span>,
        },
        {
            title: "Status",
            field: "statusId",
            validate: (rowData) => validateTable1(rowData.statusId),
            render: (rowData) => <span>{renderStatusValue(rowData.statusId)}</span>,
            editComponent: (props) => getStatusComponent(props)
        },

    ];
    const column2 = [
        {
            title: "What",
            field: "whatD6",
            editComponent: ({ value, onChange }) => (
                <TextField
                    fullWidth
                    onChange={e => onChange(e.target.value)}
                    error={!value}
                    helperText={!value ? "required" : ""}
                    value={value}
                    multiline
                />
            ),
            cellStyle: {
                minWidth: 900,
                maxWidth: 900,
            },
            validate: (rowData) => validateTable1(rowData.whatD6),
        },
        {
            title: "Who",
            field: "whoD6",
            editComponent: (props) => getWhoComponent(props),
            validate: (rowData) => validateTable1(rowData.whoD6),
        },
        {
            title: "When",
            field: "whenD6",
            render: (rowData) => <span>{renderDate(rowData.whenD6)}</span>,
            validate: (rowData) => validateTable1(rowData.whenD6),
            editComponent: (objProps) => getWhenComponent(objProps),
        },
        {
            title: "Status",
            field: "statusId",
            validate: (rowData) => validateTable1(rowData.statusId),
            render: (rowData) => <span>{renderStatusValue(rowData.statusId)}</span>,
            editComponent: (props) => getStatusComponent(props)
        },

    ];
    var columnsAttachment = [
        {
            title: "Attachment",
            field: "fileName",
            editComponent: (props) => getAttachmentComponent(props)
        },
        {
            title: "Download",
            field: "download",
            editable: "never",
            render: (rowData) => DownloadFile(rowData),
        }

    ];
    function DownloadFile(data) {
        return (
            <Button
                className="pt-button--tertiary" variant="contained"
                onClick={() =>
                    downloadFileAttachment(data)
                }
            >
                Download
            </Button>
        );
    }
    function downloadFileAttachment(data) {
        let attachmentData = {
            fileName: (data.fileName).trim(),
            fileUrl: (data.fileUrl).trim(),
            filetype: (data.filetype).trim()
        }
        const fetchPromise = fetch(baseApiURL + "/Admin/downloadfile",
            {
                method: "post",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + token,
                },
                body: JSON.stringify(attachmentData),
            });
        fetchPromise.then(response => {
            response.blob().then(blob => {
                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                link.href = url;
                link.download = "AttachmentD5_" + props.rowData[0]?.complaintNumber + attachmentData.filetype;
                link.click();
            })
        })
    }
    const [uploadedFileName, setUploadedFileName] = useState("");

    const updateExcelHandler = (event) => {
        setSelectedFile(event.target.files);
        setUploadedFileName(event.target.files[0].name);
    };
    function getAttachmentComponent(objProps) {
        return (
            <form className="bulk-upload-form">
                {selectedFile == null ? <>
                    <TextField
                        id="fileUploadAttachment"
                        variant="filled"
                        label="Attachment Upload"
                        fullWidth
                        onChange={updateExcelHandler}
                        key={inputFileAttachment}
                        type="file"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ multiple: true }}
                        helperText={uploadedFileName}
                    />
                </> : <>
                    <span>{uploadedFileName}</span></>}
            </form>
        );
    }
    function getWhenComponent(objProps) {
        const rowData = objProps.rowData;
        let value = rowData.whenD6;

        return (
            <TextField
                id="standard-basic"
                value={value}
                type="date"
                fullWidth
                inputProps={{ min: getTodayDateMoment() }}
                placeholder="Password"
                onChange={(e) => objProps.onChange(e.target.value)}
                error={!value}
                helperText={!value ? "*required" : ""}

            />
        );
    }
    function getWhoComponent(objProps) {

        return (
            <FormControl error={!objProps.value ? true : false} fullWidth>
                <Select
                    theme={theme}
                    options={whoData}
                    value={
                        objProps.label
                    }
                    onChange={(opt) => {
                        objProps.onChange(opt?.label);
                    }}
                    styles={customStylesDefault}
                    placeholder="Who"
                    isClearable
                />
                <>
                    <FormHelperText>{!objProps.value ? "*Required" : ""}</FormHelperText>
                </>
            </FormControl>
        );
    }
    function isEditable() {
        if (userDetails.roleId !== 3) {
            return false;
        }
        else {
            if (props.rowData[0]?.status === "Pending by Supplier" || props.rowData[0]?.status === "Rejected by User") {
                return true
            }
            else {
                return false;
            }
        }
    }
    function getTable1() {
        var flag = true;
        var complaintNum = props?.rowData[0].complaintNumber;
        setIsLoader1(true);
        GetD5Section1Details(token, complaintNum, userDetails.userId)
            .then((response) => {
                if (response.result.length > 0) {
                    discpline5Data.discipline5 = true;
                    for (var i = 0; i < response.result.length; i++) {
                        var obj = response.result[i];
                        if (obj?.statusId !== 2) {
                            flag = false;
                        }
                    }
                    if (flag === true) {
                        discpline5Data.disciplineStatus51 = true;
                    }
                    else {
                        discpline5Data.disciplineStatus51 = false;
                    }
                    dispatch(disciplineAction(discpline5Data));
                }
                setTableData1(response.result);
                setIsLoader1(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader1(false);
            });
    }
    function getTable2() {
        var flag = true;
        var complaintNum = props?.rowData[0].complaintNumber;
        setIsLoader2(true);
        GetD5Section2Details(token, complaintNum, userDetails.userId)
            .then((response) => {
                if (response.result.length > 0) {
                    discpline5Data.discipline51 = true;
                    for (var i = 0; i < response.result.length; i++) {
                        var obj = response.result[i];
                        if (obj?.statusId !== 2) {
                            flag = false;
                        }
                    }
                    if (flag === true) {
                        discpline5Data.disciplineStatus52 = true;
                    }
                    else {
                        discpline5Data.disciplineStatus52 = false;
                    }
                    dispatch(disciplineAction(discpline5Data));
                }
                setTableData2(response.result);
                setIsLoader2(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader2(false);
            });
    }
    const handleRowAdd1 = (newTableData, resolve, reject) => {
        setIsLoader1(true);
        newTableData.loggedinUserId = userDetails.userId;
        newTableData.complaintNum = props?.rowData[0].complaintNumber;
        if (isEditable() === false) {
            handleSnackOpen("Action Restricted", "Info");
            reject();
            setIsLoader1(false)
        }
        else {
            PostD5Section1Details(token, newTableData)
                .then((response) => {
                    if (response.response) {
                        handleSnackOpen(response.responseMsg, "success");
                        resolve();
                        setIsLoader1(false);
                        getTable1();
                    } else {
                        handleSnackOpen(response.responseMsg, "error");
                        reject();
                        setIsLoader1(false);
                    }
                })
                .catch((error) => {
                    handleSnackOpen("Exception : " + error, "error");
                    reject();
                    setIsLoader1(false);
                });
        }
    };
    const handleRowAdd2 = (newTableData, resolve, reject) => {
        setIsLoader2(true);
        newTableData.loggedinUserId = userDetails.userId;
        newTableData.complaintNum = props?.rowData[0].complaintNumber;
        if (isEditable() === false) {
            handleSnackOpen("Action Restricted", "Info");
            reject();
            setIsLoader(false)
        }
        else {
            postD5Section2Details(token, newTableData)
                .then((response) => {
                    if (response.response) {
                        handleSnackOpen(response.responseMsg, "success");
                        resolve();
                        setIsLoader2(false);
                        getTable2();
                    } else {
                        handleSnackOpen(response.responseMsg, "error");
                        reject();
                        setIsLoader2(false);
                    }
                })
                .catch((error) => {
                    handleSnackOpen("Exception : " + error, "error");
                    reject();
                    setIsLoader2(false);
                });
        }
    };
    function getAttchmentData() {
        setIsLoader(true);
        GetDisciplineAttachmentDetails(token, userDetails.userId, props?.rowData[0].complaintNumber, "D5")
            .then((response) => {
                setAttchmentData(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }

    function handleRowAddAttachment(newTableData, resolve, reject) {
        if (isEditable() === false) {
            handleSnackOpen("Action Restricted", "Info");
            reject();
            setIsLoader(false)
        }
        else if (!selectedFile) {
            handleSnackOpen("Please Select a File", "info");
            setIsLoader3(false);
            reject();
        }
        else {
            setIsLoader3(true);
            const formData = new FormData();
            for (let i = 0; i < selectedFile.length; i++) {
                formData.append("file", selectedFile[i]);
            }
            formData.append("files", selectedFile);
            formData.append("userId", userDetails.userId);
            formData.append("ComplaintNumber", props?.rowData[0].complaintNumber);
            formData.append("Discipline", "D5");
            setIsLoader3(false);
            AddDisciplineAttachmentDetails(token, formData)
                .then((response) => {
                    if (response.response) {
                        handleSnackOpen(response.responseMsg, "success");
                        resolve();
                        setUploadedFileName("");
                        setIsLoader3(false);
                        getAttchmentData();
                        setSelectedFile(null);
                    } else {
                        handleSnackOpen(response.responseMsg, "error");
                        setUploadedFileName("");
                        reject();
                        setIsLoader3(false);
                    }
                })
                .catch((error) => {
                    console.log(error)
                    setIsLoader3(false);
                    setSelectedFile(null);
                    setUploadedFileName("");
                    setInputFileAttachment(Date.now);
                    handleSnackOpen("Exception : " + error, "error");
                    reject();
                });
        }
    }
    const handleRowUpdate1 = (newTableData, oldTableData, resolve, reject) => {
        newTableData.loggedinUserId = userDetails.userId;
        setIsLoader(true);
        newTableData.loggedinUserId = userDetails.userId;
        newTableData.complaintNum = props?.rowData[0].complaintNumber;
        UpdateD5_Section1(token, newTableData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTable1();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    const handleRowUpdate2 = (newTableData, oldTableData, resolve, reject) => {
        newTableData.loggedinUserId = userDetails.userId;
        setIsLoader(true);
        newTableData.loggedinUserId = userDetails.userId;
        newTableData.complaintNum = props?.rowData[0].complaintNumber;
        UpdateD5_Section2(token, newTableData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTable2();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    function handleRowDelete(oldFormData, resolve, reject) {
        var attachmentId = oldFormData.attachmentId;
        setIsLoader(true);
        DeleteDisciplineAttachments(token, attachmentId, userDetails.userId)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getAttchmentData()
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                console.log(error)
                setIsLoader(false);
                handleSnackOpen("Exception : " + error, "error");
                reject();
            });
    }
    function handleRowDeleteD5Sec1(oldFormData, resolve, reject) {
        setIsLoader(true);
        DeleteD5Sec1(token, oldFormData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTable1();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                console.log(error)
                setIsLoader(false);
                handleSnackOpen("Exception : " + error, "error");
                reject();
            });
    }
    function handleRowDeleteD5Sec2(oldFormData, resolve, reject) {
        setIsLoader(true);
        DeleteD5Sec2(token, oldFormData)
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTable2();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                console.log(error)
                setIsLoader(false);
                handleSnackOpen("Exception : " + error, "error");
                reject();
            });
    }
    useEffect(() => {
        getTable1();
        getAttchmentData();
        getTable2();
        getWhoDetails();
        getStatusDetails()
    }, [])
    return (
        <Grid container spacing={2} md={12}>
            <Grid item xs={12} className="details-center">
                <h3 className="title-heading">Identify and Implement Permanent Corrective Actions</h3>
            </Grid>
            <Grid item className="align-ctr">
                <span>What are your Corrective Actions to prevent the root cause of Non-Conformity from re-occurring ?
                    (Poka-Yoke is recommended whenever possible) </span>
            </Grid>
            <>
                <Grid container xs={12} className="complaintsHomePage">

                    <Grid item xs={12} className="matTable">
                        <div className="Table-Margin">
                            <MaterialTable
                                columns={column1}
                                data={tableData1}
                                icons={tableIcons}
                                editable={{
                                    editTooltip: () => userDetails.roleId === 3 ?
                                        "Edit" : "Action Restricted"
                                    ,
                                    deleteTooltip: () => userDetails.roleId === 3 ?
                                        "Delete" : "Action Restricted"
                                    ,
                                    isEditable: isEditable,
                                    isDeletable: isEditable,
                                    onRowAdd: (newTableData) =>
                                        new Promise((resolve, reject) => {
                                            handleRowAdd1(newTableData, resolve, reject);
                                        }),
                                    onRowDelete: (oldFormData) =>
                                        new Promise((resolve, reject) => {
                                            handleRowDeleteD5Sec1(oldFormData, resolve, reject);
                                        }),
                                    onRowUpdate: (newTableData, oldTableData) =>
                                        new Promise((resolve, reject) => {
                                            handleRowUpdate1(
                                                newTableData,
                                                oldTableData,
                                                resolve,
                                                reject
                                            );
                                        }),
                                }}
                                isLoading={isLoader1}
                                options={tableOptionsExport}
                            />
                        </div>
                    </Grid>
                </Grid>
            </>

            <Grid item className="align-ctr" xs={12}>
                <span>What are your Corrective Actions to improve Detection of Non-Conformity if it is still likely to occur  ?  </span>
            </Grid>
            <Grid className="align-ctr" item xs={12} >
                <Grid item xs={12} className="matTable">
                    <div className="Table-Margin">
                        <MaterialTable
                            columns={column2}
                            data={tableData2}
                            icons={tableIcons}
                            editable={{
                                editTooltip: () => userDetails.roleId === 3 ?
                                    "Edit" : "Action Restricted"
                                ,
                                deleteTooltip: () => userDetails.roleId === 3 ?
                                    "Delete" : "Action Restricted"
                                ,
                                isEditable: isEditable,
                                isDeletable: isEditable,
                                onRowUpdate: (newTableData, oldTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowUpdate2(
                                            newTableData,
                                            oldTableData,
                                            resolve,
                                            reject
                                        );
                                    }),
                                onRowAdd: (newTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowAdd2(newTableData, resolve, reject);
                                    }),
                                onRowDelete: (oldFormData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowDeleteD5Sec2(oldFormData, resolve, reject);
                                    }),
                            }}
                            isLoading={isLoader2}
                            options={tableOptionsExport}
                        />
                    </div>
                </Grid>
            </Grid>
            <Grid className="align-ctr" item xs={12} sm={12} md={6}>
                <h3>Upload Attachment</h3>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Grid item xs={12} className="fileMatTable">
                    <MaterialTable
                        columns={columnsAttachment}
                        data={attachmentData}
                        icons={tableIcons}
                        editable={{
                            deleteTooltip: () => userDetails.roleId === 3 ?
                                "Action Restricted" : "Delete",
                            isDeletable: isEditable,
                            onRowAdd: (newTableData) =>
                                new Promise((resolve, reject) => {
                                    handleRowAddAttachment(newTableData, resolve, reject);
                                }),
                            onRowAddCancelled: () => {
                                setUploadedFileName("");
                                setSelectedFile(null)
                            },
                            onRowDelete: (oldFormData) =>
                                new Promise((resolve, reject) => {
                                    handleRowDelete(oldFormData, resolve, reject);
                                }),
                        }}

                        isLoading={isLoader}
                        options={tableOptionsExport}
                    />
                </Grid>

                <>
                    <SnackbarCustom
                        open={open}
                        message={snackMessage}
                        alertType={alertType}
                        handleClose={handleClose}
                    />
                </>
            </Grid>
            {isLoader3 ? <Loading loaderText="Loading...." /> : <></>}
        </Grid>
    )
}