import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    Button, TextField,
    Grid,
    Dialog,
    DialogContent,
    DialogTitle,
    Container,
    InputAdornment, makeStyles,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useForm, Controller } from "react-hook-form";
import IconButton from "@material-ui/core/IconButton";
import Cognisphere from "../../assets/images/HomePage-ImgNew.png";
import Loader from "../../Components/Loader/Loader";
import Header from "../../Components/Header/Header";
import "./Home.scss";
import { MasterRouteLanding, logoutUser } from "../../Utilities/Utility";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import { ResetUserPassword } from "../../Redux/APIs/api_login";
import { useDispatch, useSelector } from "react-redux";
import { custHeaderAction } from "../../Redux/Actions/actions";

const useStyles = makeStyles((theme) => ({
    loginTabs: {
        flexGrow: 1,
        backgroundColor: `rgba(255, 255, 255, 0.85)`,
        boxShadow: `0 8px 32px #CCCCCC`,
        borderRadius: "10px",
    },
    closeButton: {
        position: "absolute",
        right: "0px",
        top: "0px",
    },
    dialog: {
        position: "absolute",
        top: "230px",
        left: "633px",
    },
    ForgotpwdDialog: {
        top: "10px",
        right: "-97px",
        position: "relative"
    }
}));

export default function Home() {
    const dispatch = useDispatch();
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer.token);
    const [routes, setRoutes] = useState([]);
    function getMenus() {
       
        

            if (userDetails.roleId === 3 && userDetails.supplierLoginType != null) {
                let filtered = MasterRouteLanding.filter((c) => c.roleId === userDetails.roleId && c.supplierLoginType === userDetails.supplierLoginType);
                setRoutes(filtered);
            }
            else {
                let filtered = MasterRouteLanding.filter((c) => c.roleId === userDetails.roleId);
                setRoutes(filtered);
            }
        

      

    }
    function getroleList() {
        
            if (userDetails.roleId !== 1) {
                let filtered = MasterRouteLanding.filter((c) => c.roleList === 1 &&c.role==userDetails.role);
                setRoutes(filtered);
            }
            else {
                let filtered = MasterRouteLanding.filter((c) => c.roleId === userDetails.roleId);
                setRoutes(filtered);
            }
        

    }
    var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
    window.history.pushState({ path: newurl }, "", newurl);
    const classes = useStyles();

    const [isLoader, setIsLoader] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackAlertType, setSnackAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setSnackAlertType(type);
        setOpenSnack(true);
    };
    const handleClose = (e) => {
        setOpenSnack(false);
    };

    const [enterPwdDialogOpen, setEnterPwdDialogOpen] = React.useState(false);
    const [showNewUserPwdConfirm, setShowNewUserPwdConfirm] = useState(false);
    const [showNewUserRePwdConfirm, setShowNewUserRePwdConfirm] = useState(false);
    const [enterPwdHelper, setEnterPwdHelper] = useState("");
    const [reEnterPwdHelper, setReEnterPwdHelper] = useState("");
    const newUserSetPasswordShowClick = () => setShowNewUserPwdConfirm(!showNewUserPwdConfirm);
    const newUserSetRePasswordShowClick = () => setShowNewUserRePwdConfirm(!showNewUserRePwdConfirm);

    const [errorMessageContent, setErrorMessageContent] = useState("");
    const { control, handleSubmit } = useForm();
    const id = localStorage.getItem("Id");
    const [enteredPwd, setEnteredPwd] = useState("");
    const [reEnteredPwd, setreEnteredPwd] = useState("");
    const enterPwdOnChange = (event) => {
        setErrorMessageContent("");
        const value = event.target.value;
        setEnteredPwd(value);
        if (value.length < 6)
            setEnterPwdHelper("Enter Valid Password");
    }
    const reEnterPwdOnChange = (event) => {
        setErrorMessageContent("");
        const value = event.target.value;
        setreEnteredPwd(value);
        if (value.length < 6 || value != enteredPwd)
            setReEnterPwdHelper("Enter Valid Password");
    }
    const setPwdDialogOpenFn = () => {
        setEnteredPwd("");
        setreEnteredPwd("");
        setEnterPwdDialogOpen(true);
    }
    const setPwdDialogCloseFn = (e, reason) => {
        if (reason == "backdropClick")
            return;

        setEnteredPwd("");
        setreEnteredPwd("");
        setEnterPwdDialogOpen(false)
    }
    function newUserSetPassword() {
        if (enteredPwd.length == 0 || reEnteredPwd.length == 0)
            setErrorMessageContent("Enter Both Password.");
        else if (enteredPwd.length < 6 || reEnteredPwd.length < 6 || enteredPwd.length > 15 || reEnteredPwd.length > 15)
            setErrorMessageContent("Passwords length should be 6 to 15.");
        else if (enteredPwd !== reEnteredPwd)
            setErrorMessageContent("Both Passwords shoud Match.");
        else
            ChangeNewUserPwd(reEnteredPwd);

        // ChangePassword
        console.log("User Data", enteredPwd);
    }

    function ChangeNewUserPwd(data) {
        setIsLoader(true);
        const apiData = {
            userEmailId: userDetails.userEmail,
            password: data,
        };
        ResetUserPassword(siteToken, apiData)
            .then((response) => {
                if (response.response) {
                    setIsLoader(false);
                    handleSnackOpen("Password Reset Successfully", "success");
                    logoutUser();
                } else {
                    setIsLoader(false);
                    handleSnackOpen("Password Reset Failed", "error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                handleSnackOpen("Password Changed Failed!", "error");
            });
    }

    useEffect(() => {
        
        console.log(userDetails)
        //if (userDetails && userDetails.userId > 0) {
        //    getMenus(userDetails);
        //}
        if (userDetails.isSupplierFirstLogin)
            setPwdDialogOpenFn();
    }, [userDetails]);

    useEffect(() => {
        if (userDetails && userDetails.userId > 0) {
            if (userDetails.roleList.length === 2) {
                getroleList()
            }
            else {
                getMenus();
            }
        }
        console.log("usertypes", id);
        if (userDetails.roleId == 3 && userDetails.supplierLoginType == 2) {
            dispatch(custHeaderAction("ISIR Tool"));
        }
        else {
            dispatch(custHeaderAction("SQM Tool"));
        }
        console.log(userDetails);
    }, [userDetails]);

    return (
        <>
            <div className="sqm-master-block">
                <Header />
                <main className="main-component">
                    <img
                        src={Cognisphere}
                        alt="Cognisphere"
                        className="homepage-cognisphere"
                    />

                    {routes.length ? (<div className="bg-landing-page">
                        <Container maxWidth="lg">
                            <Grid container className="homepage-container">
                                <Grid item xs={12} sm={12} md={12} lg={4} className="z-9">
                                    <div className="logged-in-user">
                                        <h1 className="logged-in-user-heading">
                                            <span className="logged-in-greetings">Hello,</span>
                                            <span className="logged-in-user-name">
                                                {userDetails.userFirstName}
                                            </span>
                                        </h1>
                                        {userDetails.roleId == 3 && userDetails.supplierLoginType == 2 ? (
                                            <p className="logged-in-project-desc">

                                                Welcome to ISIR Tool
                                            </p>
                                        ) : (
                                            <></>
                                        )}
                                        {userDetails.roleId == 1 || userDetails.roleId == 2 || userDetails.roleId == 4 || userDetails.roleId == 5 || userDetails.roleId == 6 || userDetails.roleId == 7 || userDetails.roleId == 8 || userDetails.roleId == 9 || userDetails.roleId == 10 || userDetails.supplierLoginType == 1 ? (
                                            <p className="logged-in-project-desc">

                                                Welcome to SQM Tool
                                            </p>
                                        ) : (
                                            <></>
                                        )}

                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={8}
                                    className="landing-page-menu"
                                >
                                    <Grid container spacing={2}>
                                        {routes.map((item, index) => (
                                            <Grid item xs={12} sm={12} md={4} key={index}>
                                                <Link to={item.menuLink} key={index}>
                                                    <div className="menu-item-container">
                                                        <div className="menu-item-image">{item.icon}</div>
                                                        <div className="menu-item-text-block">
                                                            <span>{item.menuName}</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                    ) : (
                        <Loader />
                    )}
                </main>
            </div>

            {/* Reset Password New */}
            <Dialog
                classes={{
                    paper: classes.ForgotpwdDialog
                }}
                open={enterPwdDialogOpen}
                onClose={setPwdDialogCloseFn}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
                <DialogContent>
                    <form
                        noValidate
                        autoComplete="off"
                        className="pt-login-form"
                        onSubmit={handleSubmit((data) => newUserSetPassword())}
                    >
                        <Controller
                            name="enterPassword"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "Password required",
                                minLength: {
                                    value: 6,
                                    message:
                                        "Password must be greater than 6 characters",
                                },
                                maxLength: {
                                    value: 15,
                                    message:
                                        "Password cannot be greater than 15 characters",
                                },
                            }}
                            render={({
                                field: { onChange, enterPwdValue },
                                fieldState: { error },
                            }) => (
                                <TextField
                                    fullWidth
                                    type={showNewUserPwdConfirm ? "text" : "password"}
                                    label="Enter Password"
                                    variant="filled"
                                    value={enteredPwd}
                                    onChange={enterPwdOnChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    id="newUserPwd"
                                    className="pt-login-form-input mb-1"
                                    InputProps={{
                                        endAdornment: (
                                            <>
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        className="password-adornment"
                                                        onClick={newUserSetPasswordShowClick}
                                                    >
                                                        {showNewUserPwdConfirm ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />

                        {/* Reenter Password */}
                        <Controller
                            name="repassword"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "Password required",
                                minLength: {
                                    value: 6,
                                    message:
                                        "Password must be greater than 6 characters",
                                },
                                maxLength: {
                                    value: 15,
                                    message:
                                        "Password cannot be greater than 15 characters",
                                },
                            }}
                            render={({
                                field: { onChange, enterRePwdValue },
                                fieldState: { error },
                            }) => (
                                <TextField
                                    fullWidth
                                    type={showNewUserRePwdConfirm ? "text" : "password"}
                                    label="Re-Enter Password"
                                    variant="filled"
                                    value={reEnteredPwd}
                                    onChange={reEnterPwdOnChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    id="newUserRePwd"
                                    className="pt-login-form-input mb-1"
                                    InputProps={{
                                        endAdornment: (
                                            <>
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        className="password-adornment"
                                                        onClick={newUserSetRePasswordShowClick}
                                                    >
                                                        {showNewUserPwdConfirm ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />

                        {errorMessageContent.length > 0 ? <div className="errorMessageContent">
                            <h4>{errorMessageContent}</h4>
                        </div> : <div></div>}

                        <div className="d-flex jc-center supplier-login-btn-block">
                            <Button
                                onClick={newUserSetPassword}
                                className="pt-button--primary"
                                id="SavePwdLoginBtn"
                            >
                                Save
                            </Button>
                        </div>

                    </form>
                </DialogContent>
            </Dialog>
            {isLoader ? <Loader /> : <></>}
            <SnackbarCustom
                open={openSnack}
                message={snackMessage}
                alertType={snackAlertType}
                handleClose={handleClose}
            />
        </>
    );
}
