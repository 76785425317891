import React, { useEffect, useState,useRef } from "react";
import Grid from "@material-ui/core/Grid";
import {
    TextField,
    Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  } from "@material-ui/core";
  import * as XLSX from "xlsx";
  import partMasterTemplate from "../../../../assets/template/partmaster.xlsx";
  import SnackbarCustom from "../../../../Components/Snackbar/Snackbar";
  import Loading from "../../../../Components/Loader/Loader";
  import { useSelector } from "react-redux";
import MaterialTable from "material-table";
import { tableIcons } from "../../../../Utilities/Utility";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import { tableOptions, tableOptionsExport } from "../../../../Themes/LightTheme";
import { addPartDetails, deletePartDetails, getPartDetails, PartMasterUpload, updatePartDetails } from "../../../../Redux/APIs/api_MasterData";
export default function MaterialMaster(){
    const tableRef = useRef();
    const icon = () => {
      return (
        <GetAppRoundedIcon
          style={{ fontSize: 45 }}
        />
      );
    };
    const useStyles = makeStyles(() => ({
        downloadButonText: {
          color: "#008aa6",
        },
        downloadText: {
          marginRight: "10px",
        },
      }));
      const classes = useStyles();
      function isValidNameField(name) {
        const re = /^[a-zA-Z ]*$/;
        return re.test(name);
      }
      function isValidNumberField(number) {
        const re = /[0-9]/;
        return re.test(number);
      }
      function validatepartDesc(value) {
        return !value
          ? { isValid: false, helperText: "*Required" }
          : !isValidNameField(value)
          ? { isValid: false, helperText: "Only alphabets" }
          : { isValid: true, helperText: "" };
      }
     
      function validatepartNumber(value) {
        return !value
          ? { isValid: false, helperText: "*Required" }
          : !isValidNumberField(value)
          ? { isValid: false, helperText: "Invalid Number" }
          : { isValid: true, helperText: "" };
      }
    const columns=[
        {
            title:"Part Number",
            field:"partNumber",
            validate:(rowData)=>validatepartNumber(rowData.partNumber),
        },
        {
            title:"Part Description",
            field:"partDescription",
            validate:(rowData)=>validatepartDesc(rowData.partDescription)
        },
      
    ];
    const actions = [
      {
        icon: icon,
        tooltip: "Export to Excel",
        onClick: () => ExportToExcel(),
        isFreeAction: true,
      },
    ];
    const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const [tableData, setTableData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isLoaderDialog, setIsLoaderDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  let [inputFileValue, setInputFileValue] = useState(Date.now);
  const [selectedValue, setSelectedValue] = useState("");
  const [disable, setDisable] = useState(true);
 
  const updateExcelHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogContent, setDialogContent] = React.useState("");
  const handleDialogMessage = (text) => {
    setDialogContent(text);
    setDialogOpen(true);
  };
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = (e) => {
    setOpen(false);
    setDialogOpen(false);
  };
  function getTableData() {
    setIsLoader(true);
    getPartDetails(token, userDetails.userId)
      .then((response) => {
        setTableData(response);
        setIsLoader(false);
      })
      .catch((error) => {
        handleSnackOpen("Error : " + error, "error");
        setIsLoader(false);
      });
  }
  const handleRowUpdate = (newTableData, oldTableData, resolve, reject) => {
    setIsLoader(true);
    newTableData.loggedinUsesrId = userDetails.userId;
    updatePartDetails(token, newTableData)
      .then((response) => {
        if (response.response) {
          handleSnackOpen(response.responseMsg, "success");
          resolve();
          setIsLoader(false);
          getTableData();
        } else {
          handleSnackOpen(response.responseMsg, "error");
          reject();
          setIsLoader(false);
        }
      })
      .catch((error) => {
        handleSnackOpen("Exception : " + error, "error");
        reject();
        setIsLoader(false);
      });
      
  };
  const handleRowAdd = (newTableData, resolve, reject) => {
    setIsLoader(true);
    newTableData.loggedinUserId = userDetails.userId;
    addPartDetails(token, newTableData)
      .then((response) => {
        if (response.response) {
          handleSnackOpen(response.responseMsg, "success");
          resolve();
          setIsLoader(false);
          getTableData();
        } else {
          handleSnackOpen(response.responseMsg, "error");
          reject();
          setIsLoader(false);
        }
      })
      .catch((error) => {
        handleSnackOpen("Exception : " + error, "error");
        reject();
        setIsLoader(false);
      });
     
  };
  const handleRowDelete = (oldTableData, resolve, reject) => {
    setIsLoader(true);
    oldTableData.loggedinUserId = userDetails.userId;
    deletePartDetails(token, oldTableData)
      .then((response) => {
        if (response.response) {
          handleSnackOpen(response.responseMsg, "success");
          resolve();
          setIsLoader(false);
          getTableData();
        } else {
          handleSnackOpen(response.responseMsg, "error");
          reject();
          setIsLoader(false);
        }
      })
      .catch((error) => {
        handleSnackOpen("Exception : " + error, "error");
        reject();
        setIsLoader(false);
      });
     
  };
  function handleUpload(){
    setIsLoaderDialog(true)
    if (!selectedFile) {
      setIsLoaderDialog(false);
      setDialogContent("Please select a file");
      setDialogOpen(true);
    } else if (
      selectedFile &&
      !selectedFile?.type?.includes(
        "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) &&
      !selectedFile?.type?.includes("vnd.ms-excel")
    ) {
      setIsLoaderDialog(false);
      setDialogContent("Only .xlsx or .xls file is allowed");
      setDialogOpen(true);
    } else {
      setIsLoaderDialog(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("UserId", userDetails.userId);
          PartMasterUpload
          (formData, token)
            .then((response) => {
              if (response.response) {
                setIsLoaderDialog(false);
                handleDialogMessage(response.responseMsg);
                getTableData();
              } else {
                setIsLoaderDialog(false);
                setSelectedFile(null);
                setInputFileValue(Date.now);
              //  setErrorData(response.objResult);
                handleDialogMessage(
                  response.responseMsgs && response.responseMsgs.length > 0
                    ? response.responseMsgs
                    : response.responseMsg
                );
              }
            })
            .catch((error) => {
              setIsLoaderDialog(false);
              setSelectedFile(null);
              setInputFileValue(Date.now);
              handleDialogMessage("Exception in upload");
            });
  }
}
function handleDownload(){
  window.open(partMasterTemplate);
}
function ExportToExcel() {
  const dataToDownload = tableRef?.current?.dataManager?.searchedData.map(
    (row) => {
      return {
          salesOffice: row.partNumber,
          salesBranch: row.partDescription,
      };
    }
  );
  let headers = [
    "Part Number",
    "Part Description",
  ];
  //const workSheet = XLSX.utils.json_to_sheet(newData);
  let ws = XLSX.utils.book_new();
  let wb = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, [headers]);
  XLSX.utils.sheet_add_json(ws, dataToDownload, {
    origin: "A2",
    skipHeader: true,
    cellStyles: true,
  });
  const dataForCellAdjustments=dataToDownload;
  const headerObj={
      partNumber: headers[0],
      partDescription: headers[1],
  }
  dataForCellAdjustments.push(headerObj);
  ws["!cols"] = cellAdjustToContents(dataToDownload);
  XLSX.utils.book_append_sheet(wb, ws, "Part Master");
  XLSX.write(wb, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(wb, "PartMaster.xlsx");
}
function cellAdjustToContents(data) {
  return [
    {
      wch: Math.max(
        ...data?.map((c) =>
          c.partNumber ? c.partNumber?.toString()?.length : 0
        )
      ),
    },
    {
      wch: Math.max(
        ...data?.map((c) =>
          c.partDescription ? c.partDescription.toString()?.length : 0
        )
      ),
    },
  
  ];
}
  useEffect(()=>{
    getTableData();
  },[])
    return(    
        <>
      
        <Grid container xs={12} className="complaintsHomePage">
      <Grid container className="paper-style">
      <Grid item md={12}> <h3 className="h3-upload">Bulk Upload</h3></Grid>
        <Grid item className="margin-rm" md={6}>
       
        <div className="mr-bottom-upload">
       
        <form
          className="bulk-upload-form mt-upload"
        //onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            label="Bulk Upload File"
            variant="filled"
            className="bulk-upload"
            //{...register("bulkUpload")}
            type="file"
            InputLabelProps={{ shrink: true }}
            name="bulkUpload"
            onChange={updateExcelHandler}
            key={inputFileValue}
          />
          <Button
            className="pr-button--secondary bulk-upload-submit-btn"
           onClick={handleUpload}
          >
            Submit
          </Button>
        </form>
        <span>Upload file format in excel i.e .xsls, .xls format only</span>
      </div>
      </Grid>
      <Grid md={6}>
      <div>
        <span className={classes.downloadText}>
          Download Sample Template
        </span>
        <Button onClick={handleDownload} className="pt-button--secondary bulk-upload-submit-btn">
        
            Download
      
        </Button>
      </div>
        </Grid>
        </Grid>
          <Grid item xs={12}  className="matTable">
            <MaterialTable
              //title={getTableTitle()}
              columns={columns}
              tableRef={tableRef}
              data={tableData}
              icons={tableIcons}
              actions={actions}
              editable={{
                onRowUpdate: (newTableData, oldTableData) =>
                new Promise((resolve, reject) => {
                  handleRowUpdate(
                    newTableData,
                    oldTableData,
                    resolve,
                    reject
                  );
                }),
              onRowAdd: (newTableData) =>
                new Promise((resolve, reject) => {
                  handleRowAdd(newTableData, resolve, reject);
                }),
                onRowDelete: (oldTableData) =>
                new Promise((resolve, reject) => {
                  handleRowDelete(oldTableData, resolve, reject);
                }),       
              }}
              isLoading={isLoader}
              options={
                tableOptions   
              }
            />
          </Grid>
          <>
          <SnackbarCustom
            open={open}
            message={snackMessage}
            alertType={alertType}
            handleClose={handleClose}
          />
        </>
        <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Bulk Upload</DialogTitle>
        <DialogContent>
          {dialogContent && typeof dialogContent === "object" ? (
            <DialogContentText id="alert-dialog-description">
              <ol className="ol-bulkUpload-Error">
                {dialogContent.map((c) => (
                  <li>
                    {c.map((x) =>
                      x.includes("Part Number :") ? (
                        x
                      ) : (
                        <ul className="ul-bulkUpload-Error">
                          <li>{x}</li>
                        </ul>
                      )
                    )}
                  </li>
                ))}
              </ol>
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-description">
              {dialogContent}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="pt-button--secondary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {isLoaderDialog ? <Loading loaderText="Upload in progress...." /> : <></>}
        </Grid>
      </>
    )
}