import { FormControl, Grid, InputLabel } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
//import "./ComplaintsList.scss";
import { TextField } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import MaterialTable from "material-table";
import { CircularProgress } from "@material-ui/core";
import { BootstrapInput, getTodayDateMoment, tableIcons } from "../../Utilities/Utility";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
//import { BootstrapInput, getTodayDateMoment, tableIcons } from "../../../Utilities/Utility";
import moment from "moment";
import Loading from ".././../Components/Loader/Loader";
import Button from "@material-ui/core/Button";
import { getAllIsirDetails, getComponentEngiDetailsList, GetISIRKPIDetails, getsqeDetailsList, getSupplierlist } from "../../Redux/APIs/api_view";
import * as XLSX from "xlsx";
//import { BootstrapInput } from "../../Utilities/Utility";
import { useNavigate } from "react-router-dom";


import AddIsirRoot from "../ADDISIR/AddIsirRoot";
import AddIsir from "../ADDISIR/AddlSir";
//import { supplierMailValAction } from "../../../Redux/Actions/actions";
const Epwork = () => {
    const dispatch = useDispatch();
    const tableRef = useRef();
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const storedSupplierMailArr = useSelector((state) => state.supplierEmailArrReducer);
    const [isirDetailsData, setIsirDetailsData] = useState([]);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [isLoaderKpi, setIsLoaderKpi] = useState(false);
    const [isLoaderFeedback, setIsLoaderFeedback] = useState(false);
    const navigate = useNavigate();
    const [dateInputs, setDateInputs] = useState({
        fromDate: null,
        toDate: null
    });
    function handleDateChange(event, value) {
        let elem = event.target.name;
        switch (elem) {
            case "fromDate":
                setDateInputs({
                    ...dateInputs,
                    fromDate: event.target.value,
                })
                break;
            case "toDate":
                setDateInputs({
                    ...dateInputs,
                    toDate: event.target.value,
                })
                break;
            default:
                break;

        }
    }
    var columns = [
        {
            title: "ISIR ID",
            field: "automatedIsirnumber",
            render: (rowData) => navigateToComplaintsPage(rowData),
        },

        {
            title: "Spiridon No",
            field: "spiridonNumber",
            //render: (rowData) => <span>{renderPartValue(rowData.partId)}</span>
        },
        {
            title: "Sender",
            field: "sender",
        },
        {
            title: "Supplier Name",
            field: "supplierName", 
        },
        {
            title: "Raise Date",
            field: "createdDate",
            render: (rowData) => <span>{renderDateY(rowData.createdDate)}</span>
        },
        {
            title: "Release Date",
            field: "massReleasetoSQE",
            render: (rowData) => <span>{renderDateY(rowData.massReleasetoSQE)}</span>
        },
       // {
          //  title: "Release Date",
          //  field: "revisionDate",
          //  render: (rowData) => <span>{renderDateY(rowData.revisionDate)}</span>
       // },
        
        {
            title: "Status",
            field: "statusName",
        },
    ]

    function navigateToComplaintsPage(data) {
        // supplierId
        
        return (
            <Button
                className="pt-button--light"
                onClick={() => {
                   // console.log("Tab Data", data);
                    //const supMailIndex = findDefSupplerMailIdx(data);
                    //dispatch(supplierMailValAction(supMailIndex));
                    navigate("/AddIsir", {
                        state: {
                            rowData: data
                        }
                    });
                }}
            >
                {data.automatedIsirnumber} {/*automatedIsirnumber   {data.complaintNumber}*/}
            </Button>
        );
    }
    function renderDateY(value) {
        let DateFormat = !value ? null : moment(value).format('MM/DD/YYYY');
        return DateFormat;

    }
    //function findDefSupplerMailIdx(arg) {
        //console.log("Index", defaIndex);
        ///return storedSupplierMailArr.findIndex(x => x.supplierId == arg.supplierId && x.email == arg.supplierEmail);
        //setDefaIndex(defaIndexVal);
    //}
    function renderDate(value) {
        let DateFormat = !value ? null : moment(value).format('YYYY-MM-DD');
        return DateFormat;
    }
    const tableHeaderStyle = {
        backgroundColor: "var(--light-sand)",
        color: "var(--dark-blue)",
    };
    const icon = () => {
        return (
            <GetAppRoundedIcon
                style={{ fontSize: 45, }}
            />
        );
    };
    const actions = [
        {
            icon: icon,
            tooltip: "Export to Excel",
            onClick: () => ExportToExcel(),
            isFreeAction: true,
        },
    ];
    function ExportToExcel() {
        const dataToDownload = tableRef?.current?.dataManager?.searchedData.map(
            (row) => {
               
                return {
                    automatedIsirnumber: row.automatedIsirnumber,
                    spiridonNumber: row.spiridonNumber,
                    sender:row.sender,
                    supplierName: row.supplierName,
                    
                    requestopenedon: moment(row.requestopenedon).format("YYYY-MM-DD"),
                    revisionDate: row.revisionDate ? moment(row.revisionDate).format("YYYY-MM-DD") : "",
                    
                    statusName: row.statusName,
                };
            }
        );
        let headers = [
            "ISIR ID",
            "Spiridon No",
            "Sender",
            "Supplier Name",
            
            "Raise Date",
            "Release Date",
            
            "Status",
        ];


        let ws = XLSX.utils.book_new();
        let wb = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [headers]);
        XLSX.utils.sheet_add_json(ws, dataToDownload, {
            origin: "A2",
            skipHeader: true,
            cellStyles: true,
        });
        const dataForCellAdjustments = dataToDownload;
        const headerObj = {
            
            automatedIsirnumber: headers[0], 
            spiridonNumber: headers[1], 
            sender:headers[2],
            supplierName: headers[3],
            
            requestopenedon: headers[4], 
            revisionDate: headers[5], 

            statusName: headers[6], 
        }
        dataForCellAdjustments.push(headerObj);
        ws["!cols"] = cellAdjustToContents(dataToDownload);
        XLSX.utils.book_append_sheet(wb, ws, "List Of ISIR");
        XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(wb, " List Of ISIR.xlsx");
    }
    function cellAdjustToContents(data) {
        return [
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.automatedIsirnumber ? c.automatedIsirnumber?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.spiridonNumber ? c.spiridonNumber.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.sender ? c.sender.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.supplierName ? c.supplierName.toString()?.length : 0
                    )
                ),
            },
            
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.requestopenedon ? c.requestopenedon.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.revisionDate ? c.revisionDate.toString()?.length : 0
                    )
                ),
            },
            
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.statusName ? c.statusName.toString()?.length : 0
                    )
                ),
            },

        ];
    }
    const [tableData, setTableData] = useState([]);
    function getDetailsForIsir() {
       
        if (userDetails.roleId === 1) {
            setIsLoader(true);
            setIsLoaderFeedback(true);
            getAllIsirDetails(token, userDetails.userId)
                .then((response) => {
                   // console.log("ISIRDETAILSFROMEPWORK", response);
                    //setIsLoader(true);
                    setTableData(response);
                    setIsLoader(false);
                    setIsLoaderFeedback(false);
                    //setIsLoader(false);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoaderFeedback(false);
                    //setIsLoader(false);


                });
        }

        else if (userDetails.roleId === 3) {
            setIsLoader(true);
            setIsLoaderFeedback(true);
            getSupplierlist(token, userDetails.supplierId)
                .then((response) => {
                    //setIsLoader(true);
                    setTableData(response);
                    setIsLoader(false);
                    setIsLoaderFeedback(false);
                    //
                })
                .catch((error) => {
                    handleSnackOpen("Error : " + error, "error");
                    setIsLoaderFeedback(false);
                    //setIsLoader(false);
                });
        }
        else if (userDetails.roleId === 6) {
            setIsLoader(true);
            setIsLoaderFeedback(true);
            getComponentEngiDetailsList(token, userDetails.userId)
                .then((response) => {

                    setTableData(response);
                    setIsLoader(false);
                    setIsLoaderFeedback(false);

                })
                .catch((error) => {


                    handleSnackOpen("Error : " + error, "error");
                    setIsLoaderFeedback(false);

                });
        }
        else {
            setIsLoader(true);
            setIsLoaderFeedback(true);
            getsqeDetailsList(token, userDetails.userId)
                .then((response) => {
                    //setIsLoader(true);
                    setTableData(response);
                    setIsLoader(false);
                    setIsLoaderFeedback(false);
                    //
                })
                .catch((error) => {
                    handleSnackOpen("Error : " + error, "error");
                    setIsLoaderFeedback(false);
                    //setIsLoader(false);
                });
        }

        
    };
    
    function getDetailsOfSupplierList() {
        setIsLoader(true);
        setIsLoaderFeedback(true);
        if (userDetails.roleId === 3) {
            //setIsLoader(true);
            setIsLoaderFeedback(true);
            getSupplierlist(token, userDetails.supplierId)
                .then((response) => {
                    //setIsLoader(true);
                    setTableData(response);
                    setIsLoader(false);
                    setIsLoaderFeedback(false);
                    //
                })
                .catch((error) => {
                    handleSnackOpen("Error : " + error, "error");
                    setIsLoaderFeedback(false);
                    //setIsLoader(false);
                });
        }
    }
    function getDetailsByRole() {
        setIsLoader(true);
        setIsLoaderFeedback(true);
            getsqeDetailsList(token, userDetails.userId)
                .then((response) => {
                    //setIsLoader(true);
                    setTableData(response);
                    setIsLoader(false);
                    setIsLoaderFeedback(false);
                    //
                })
                .catch((error) => {
                    handleSnackOpen("Error : " + error, "error");
                    setIsLoaderFeedback(false);
                    //setIsLoader(false);
                });
        
    }
    function getDetailsOfComponentEngi() { 
        setIsLoader(true);
        setIsLoaderFeedback(true);
        getComponentEngiDetailsList(token, userDetails.userId)
            .then((response) => {
                
                setTableData(response);
                setIsLoader(false);
                setIsLoaderFeedback(false);
               
            })
            .catch((error) => {
               
               
                handleSnackOpen("Error : " + error, "error");
                setIsLoaderFeedback(false);
                
            });

    }
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };

    const [kpiData, setKpiData] = useState([])
    function getKPIData() {
        setIsLoader(true);
        GetISIRKPIDetails(token, dateInputs.fromDate, dateInputs.toDate, userDetails.userId, userDetails.supplierId)
            .then((response) => {
                setKpiData(response);
                console.log("KPI res", response);
               // setIsirDetailsData(response.isirData)
                setTableData(response.isirData);

                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }

    useEffect(() => {
        getDetailsForIsir();
        //getDetailsOfSupplierList();
        //getDetailsByRole();
       // getDetailsOfComponentEngi();
       
    }, [])
    


    const tableOptions = {
        headerStyle: tableHeaderStyle,
        showTitle: false,
        draggable: false,
        pageSize: 5,
        pageSizeOptions: [5, 10, 25, 50],
        paginationPosition: "bottom",
        maxBodyHeight: "600px",
    };
    return (
        <div>
           
            <Grid container xs={12} className="complaintsHomePage">
                <Grid item xs={12} className="pageTitle">
                    <h3>List of ISIR</h3>
                </Grid>
                <Grid container className="bg-card" spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                From Date
                            </InputLabel>
                            <BootstrapInput
                                type="date"
                                id="bootstrap-input"
                                name="fromDate"
                                onChange={handleDateChange}
                                value={dateInputs.fromDate}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                To Date
                            </InputLabel>
                            <BootstrapInput
                                type="date"
                                id="bootstrap-input"
                                name="toDate"
                                onChange={handleDateChange}
                                value={dateInputs.toDate}
                                inputProps={{ max: getTodayDateMoment }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item className="mr-btm" xs={12} sm={6} md={2}>
                        <Button className="pt-button--primary button-submit"
                            variant="contained"
                            onClick={getKPIData}
                        >
                            Filter Result
                        </Button>
                    </Grid>
                 
                </Grid>
                <Grid item xs={12} className="matTable">
                    <MaterialTable
                        tableRef={tableRef}
                        actions={actions}
                        columns={columns}
                        data={tableData}
                        //data={isirDetailsData}
                        //data={isirDetailsData}
                        icons={tableIcons}
                        isLoading={isLoader}
                        options={tableOptions}
                    />
                </Grid>
                {/* <AddIsir data={isirDetailsData} />*/ }
                
            </Grid>
            {/*     {loader ? <Loading loaderText="Loading...." /> : <></>}*/ }
            {isLoaderFeedback ? <Loading loaderText="Loading...." /> : <></>}
        </div>
    );
}
export default Epwork;