import { baseApiURL } from "../../Utilities/Utility";

export function getUserDetails(siteToken, userId) {
    const options = {
        method: "get",
        headers: { Authorization: "Bearer " + siteToken },
    };
    return fetch(baseApiURL + "/Admin/GetUserDetails?userId=" + userId, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getUserDetailsOther(siteToken, userId) {
    const options = {
        method: "get",
        headers: { Authorization: "Bearer " + siteToken },
    };
    return fetch(baseApiURL + "/Admin/GetUserDetailsforOtherRoles?userId=" + userId, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function addUserDetails(siteToken, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PostUserDetails", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function updateUserDetails(siteToken, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PutUserDetails", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function updateRole(siteToken, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/UpdateRoleDetails", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function deleteUserDetails(siteToken, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/DeleteUserDetails", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
/*   Role Master  */

export function getRoleData(siteToken, userId) {
    const options = {
        method: "get",
        headers: { Authorization: "Bearer " + siteToken },
    };
    return fetch(baseApiURL + "/Admin/GetRoleDetails?userId=" + userId, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function addRoleData(siteToken, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PostRoleDetails", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function updateRoleData(siteToken, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PutRoleDetails", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function deleteRoleData(siteToken, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/DeleteRoleDetails", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

//partMaster
export function getPartDetails(siteToken, userId) {
    const options = {
        method: "get",
        headers: { Authorization: "Bearer " + siteToken },
    };
    return fetch(baseApiURL + "/Admin/GetPartDetails?userId=" + userId, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function addPartDetails(siteToken, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PostPartDetails", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function updatePartDetails(siteToken, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PutPartDetails", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function deletePartDetails(siteToken, data) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/DeletePartDetails", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function PartMasterUpload(formData, siteToken) {
    const options = {
      method: "post",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      body: formData,
    };
    return fetch(baseApiURL + "/Admin/UploadPartfile", options)
      .then((response) => response.json())
      .then((data) => {
        return data.result;
      })
      .catch((error) => {
        //return { response: false, responseMsg: error };
        console.log("Error : ", error);
      });
  }