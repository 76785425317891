import {Grid,TextField,Button} from "@material-ui/core";
import { useState,useEffect } from "react";
import { baseApiURL, getTodayDateMoment, tableIcons } from "../../../Utilities/Utility";
import MaterialTable from "material-table";
import Loading from "../../../Components/Loader/Loader";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { theme ,customStylesDefault} from "../../../Utilities/Utility";
import { useSelector,useDispatch } from "react-redux";
import Select from 'react-select'
import {
  FormControl,
 FormHelperText
} from "@material-ui/core";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import { tableOptionsExport } from "../../../Themes/LightTheme";
import { getD1WhoDDL, getStatusDDL } from "../../../Redux/APIs/api_Common";
import { AddDisciplineAttachmentDetails, DeleteD6, GetD6Details, GetDisciplineAttachmentDetails, PostD6Details, PostD6TextDetails, UpdateD6, UpdateD6TextDetails } from "../../../Redux/APIs/api_Disciplines";
import { DeleteDisciplineAttachments } from "../../../Redux/APIs/api_Complaint";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { disciplineAction } from "../../../Redux/Actions/actions";
export default function Discipline6(props){
  const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
      (state) => state.storeUserDetailsReducer.userData
  );
  const dispatch=useDispatch();
  const discpline6Data= useSelector(
    (state) => state.disciplineReducer.disciplineData
);
const [inputValues,setInputValues]=useState({
  correctiveAction: props.d6Details?.length>0?props?.d6Details[0]?.correctiveAction:null,
  complaintNum: props.rowData[0]?.complaintNumber,
 
})
  const [tableData, setTableData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [attachmentData,setAttchmentData]=useState([]);
  const [isLoader2, setIsLoader2] = useState(false);
const [isLoaderDraft,setIsLoaderDraft]= useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  let [inputFileAttachment, setInputFileAttachment] = useState(Date.now);
  const [openValidationDialog,setOpenValidationDialog]=useState("");
  const [dialogErrorText,setDialogErrorText]=useState("");
   const handleClickOpenDialogValidation = (text) => {
      setDialogErrorText(text);
      setOpenValidationDialog(true);
    };
  function renderDate(value){
    let DateFormat=!value?null:moment(value).format('YYYY-MM-DD');
    return DateFormat;
  }
  const [errorTexts,setErrorTexts]=useState({
    correctiveAction:"",
  })
  const [d6Data,setD6Data]=useState({
    correctiveAction:props?.d6Details?.length>0? props?.d6Details[0].correctiveAction:null,
    loggedinUserId: userDetails.userId,
  });
  const handleChange = (event,value) => {
    let elem = event.target.name;
      switch (elem){
        case "correctiveAction":
         setInputValues({
          ...inputValues,
          [elem]:event.target.value
  
         })
         setErrorTexts({
          ...errorTexts,
          correctiveAction:""
        })
        break;
        default:break;
      }
    }
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = (e) => {
    setOpen(false);
    setOpenValidationDialog(false);
  };
  
  function validateD6(value) {
    return !value
      ? { isValid: false, helperText: "*Required" }
      : { isValid: true, helperText: "" };
  }
  const columns=[
    {
      title:"What",
      field:"whatD6",
      editComponent: ({ value, onChange }) => (   
        <TextField
          fullWidth
          onChange={e => onChange(e.target.value)}
          error={!value}
          helperText={!value?"required":""}
          value={value}
          multiline
        />
      ),
      validate:(rowData)=>validateD6(rowData.whatD6),
      cellStyle: {
        minWidth:900,
        maxWidth: 900,
      },
  },
  {
      title:"Who",
      field:"whoD6",
      editComponent:(props)=>getWhoComponent(props),
      validate:(rowData)=>validateD6(rowData.whoD6)
  },
  {
      title:"When",
      field:"whenD6",
      render:(rowData)=><span>{renderDate(rowData.whenD6)}</span>,
      editComponent:(props)=>getWhenComponent(props) ,
      validate:(rowData)=>validateD6(rowData.whenD6)
  },
  {
      title:"Status",
      field:"statusId",
      render: (rowData) => <span>{renderStatusValue(rowData.statusId)}</span>,
      editComponent:(props)=>getStatusComponent(props),
      validate:(rowData)=>validateD6(rowData.statusId)
  },
  
];
var columnsAttachment=[
  {
      title:"Attachment",
      field:"fileName",
      editComponent: (props) => getAttachmentComponent(props)
  },
  {
    title: "Download",
    field: "download",
    editable:"never",
    render: (rowData) => DownloadFile(rowData),
  }

];
 function DownloadFile(data){
  return (
    <Button
    className="pt-button--tertiary" variant="contained" 
    onClick={() =>
      downloadFileAttachment(data)
    }
  >
   Download
  </Button>
  );
 }
 function downloadFileAttachment(data){
  let attachmentData={
    fileName: (data.fileName).trim(),                                                                                                                                                           
    fileUrl:(data.fileUrl).trim(),
    filetype:(data.filetype).trim()
  }
  const fetchPromise=fetch(baseApiURL + "/Admin/downloadfile",
  {
    method:"post",
    headers:{
      "Content-type":"application/json; charset=UTF-8",
      "Authorization": "Bearer " + token,
    },
    body: JSON.stringify(attachmentData),
});
fetchPromise.then(response=>{
  response.blob().then(blob=>{
    const link=document.createElement('a');
    const url=URL.createObjectURL(blob);
    link.href=url;
    link.download="AttachmentD6_"+props.rowData[0]?.complaintNumber+attachmentData.filetype;
    link.click();
  })
})
}
 const [uploadedFileName, setUploadedFileName] = useState("");
const updateExcelHandler = (event) => {
  setSelectedFile(event.target.files);
  setUploadedFileName(event.target.files[0].name);
};
function getAttachmentComponent(objProps) {
   return ( 
     <form className="bulk-upload-form">
     {selectedFile == null ?<>
      <TextField
         id="fileUploadAttachment"
         variant="filled"
         label="Attachment Upload"
         fullWidth
         onChange={updateExcelHandler}  
         key={inputFileAttachment}
         type="file"
         InputLabelProps={{shrink: true}}
         inputProps={{ multiple: true }}   
         helperText={uploadedFileName} 
     />
     </>:<>
     <span>{uploadedFileName}</span></>}
   </form>
   );
 }
const [statusData,setStatusData]=useState([]);

async function getStatusDetails() {
  const response = await getStatusDDL(token, userDetails.userId);
  if (response && response.length > 0) {
    setStatusData(
      response.map((item) => ({ value: item.id, label: item.name }))
    );
  } else {
    setStatusData([]);
  }
}
const [whoData,setWhoData]=useState([]);
async function getWhoDetails() {
  const response = await getD1WhoDDL(token, userDetails.userId,props?.rowData[0].complaintNumber);
  if (response && response.length > 0) {
    setWhoData(
    response.map((item,key)=>({ value:key,label:response[key]}))
    );
  } else {
    setWhoData([]);
  }
}

function renderStatusValue(value) {
  let obj = {};
  if (statusData && statusData.length > 0) {
    obj = statusData.find((c) => c.value == value);
  }
  if (obj) {
    return obj.label;
  } else {
    return "";
  }
}
function getStatusComponent(objProps) {
  const Status= objProps.rowData.statusId
    ? statusData.find((x) => x.value === objProps.rowData.statusId)
    : null;
  return (
    <FormControl error={!objProps.value ? true : false} fullWidth>
      <Select
        theme={theme}
        options={statusData}
        value={
          typeof objProps.value === "string" ||
          typeof objProps.value === "number"
            ? Status
            : objProps.value
        }
        onChange={(opt) => {
          objProps.onChange(opt?.value);
        }}
        styles={customStylesDefault}
        placeholder="Status"
        isClearable
      />
      <>
        <FormHelperText>{!objProps.value ? "*Required" : ""}</FormHelperText>
      </>
    </FormControl>
  );
}
function getWhoComponent(objProps) {
  return (
    <FormControl error={!objProps.value ? true : false} fullWidth>
    <Select
    theme={theme}
    options={whoData}
    value={
      objProps.label
    }
    onChange={(opt) => {
      objProps.onChange(opt?.label);
    }}
    styles={customStylesDefault}
    placeholder="Who"
    isClearable
  />
  <>
  <FormHelperText>{!objProps.value ? "*Required" : ""}</FormHelperText>
</>
    </FormControl>
  );
}
function isEditable(){
  if(userDetails.roleId!==3){
      return false;
  }
  else{
    if(props.rowData[0]?.status==="Pending by Supplier"||props.rowData[0]?.status==="Rejected by User"){
      return true
    }
    else{
      return false;
    }
  }
}
function getTableData() {
  var flag=true;
  setIsLoader(true);
  GetD6Details(token,props?.rowData[0].complaintNumber,userDetails.userId)
    .then((response) => {
      if(response.result.length>0){
        discpline6Data.discipline6=true;
        for(var i=0;i<response.result.length;i++){
         var obj=response.result[i];
         if(obj?.statusId!==2){
           flag=false;
        }
        }  
        if(flag===true){ 
        discpline6Data.disciplineStatus6=true;
        }
        else{
          discpline6Data.disciplineStatus6=false;
        }
        dispatch(disciplineAction(discpline6Data));
      }
      setTableData(response.result);
      setIsLoader(false);
    })
    .catch((error) => {
      handleSnackOpen("Error : " + error, "error");
      setIsLoader(false);
    });
}
const AddD6Details = () => { 
  if
   (
    !inputValues.correctiveAction){
    handleClickOpenDialogValidation("Please Fill Inputs !!");
   }
   else{
    setIsLoaderDraft(true);
   if(props?.d61UpdateResponse?.length>0){
    var updateId=props.d61UpdateResponse[0].id;
    const updateValues={
      ...inputValues,
      id:updateId
    }
    UpdateD6TextDetails(token, updateValues)
      .then((response) => {
        if (response.response) {
          handleSnackOpen(response.responseMsg, "success");
          setIsLoaderDraft(false);
           props.getD6SaveDetails();
        } else {
          handleSnackOpen(response.responseMsg, "error");
          setIsLoaderDraft(false);
        }
      })
      .catch((error) => {
        handleSnackOpen("Exception : " + error, "error");
        setIsLoaderDraft(false);
      });
   }
   else{
    PostD6TextDetails(token, inputValues)
      .then((response) => {
        if (response.response) {
          handleSnackOpen(response.responseMsg, "success");
          setIsLoaderDraft(false);
           props.getD6SaveDetails();
        } else {
          handleSnackOpen(response.responseMsg, "error");
          setIsLoaderDraft(false);
        }
      })
      .catch((error) => {
        handleSnackOpen("Exception : " + error, "error");
        setIsLoaderDraft(false);
      });
    }
  };
  }
const handleRowAdd = (newTableData, resolve, reject) => {  
  setIsLoader(true);
  newTableData.loggedinUserId = userDetails.userId;
  newTableData.complaintNum=props?.rowData[0].complaintNumber;
  if(isEditable()===false){
    handleSnackOpen("Action Restricted", "Info");
    reject();
    setIsLoader(false)
  }
  else{
  PostD6Details(token, newTableData)
    .then((response) => {
      if (response.response) {
        handleSnackOpen(response.responseMsg, "success");
        resolve();
        setIsLoader(false);
        getTableData();
      } else {
        handleSnackOpen(response.responseMsg, "error");
        reject();
        setIsLoader(false);
      }
    })
    .catch((error) => {
      handleSnackOpen("Exception : " + error, "error");
      reject();
      setIsLoader(false);
    });
  }
};

  const handleRowUpdate=(newTableData, oldTableData, resolve, reject)=>{
    newTableData.loggedinUserId = userDetails.userId;
    setIsLoader(true);
    newTableData.loggedinUserId = userDetails.userId;
    newTableData.complaintNum=props?.rowData[0].complaintNumber;
   UpdateD6(token, newTableData)
        .then((response) => {
            if (response.response) {
                handleSnackOpen(response.responseMsg, "success");
                resolve();
                setIsLoader(false);
                getTableData();
            } else {
                handleSnackOpen(response.responseMsg, "error");
                reject();
                setIsLoader(false);
            }
        })
        .catch((error) => {
            handleSnackOpen("Exception : " + error, "error");
            reject();
            setIsLoader(false);
        });
  };

function getAttchmentData() {
  setIsLoader2(true);
      GetDisciplineAttachmentDetails(token, userDetails.userId,props?.rowData[0].complaintNumber,"D6")
      .then((response) => {
        setAttchmentData(response);
        setIsLoader2(false);
      })
      .catch((error) => {
       handleSnackOpen("Error : " + error, "error");
        setIsLoader2(false);
      }); 
  }
function handleRowAddAttachment(newTableData, resolve, reject){
  if(isEditable()===false){
    handleSnackOpen("Action Restricted", "Info");
    reject();
    setIsLoader(false)
  }
  else if (!selectedFile) {
    handleSnackOpen("Please Select a File", "info");
    setIsLoader2(false);
    reject();
  } 
  else{
    setIsLoader2(true);
    const formData = new FormData();
    for (let i = 0 ; i < selectedFile.length ; i++) {
      formData.append("file", selectedFile[i]);
  }
  formData.append("files", selectedFile);
  formData.append("userId", userDetails.userId);
  formData.append("ComplaintNumber",props?.rowData[0].complaintNumber);
  formData.append("Discipline", "D6");
       setIsLoader2(false); 
    AddDisciplineAttachmentDetails(token, formData)
    .then((response) => {
      if (response.response) {
       handleSnackOpen(response.responseMsg, "success");
        resolve();
        setUploadedFileName("");
       setIsLoader2(false);
        getAttchmentData();
        setSelectedFile(null);
      } else {
       handleSnackOpen(response.responseMsg, "error");
        reject();
        setUploadedFileName("");
        setIsLoader2(false);
      }
    })
    .catch((error) => {
      console.log(error)
      setIsLoader2(false);
      setSelectedFile(null);
      setUploadedFileName("");
      setInputFileAttachment(Date.now);
      handleSnackOpen("Exception : " + error, "error");
      reject();
    });
  }
  }
  function handleRowDelete(oldFormData, resolve, reject){
    var  attachmentId=oldFormData.attachmentId;
     setIsLoader(true);
     DeleteDisciplineAttachments(token,attachmentId,userDetails.userId)
     .then((response) => {
       if (response.response) {
        handleSnackOpen(response.responseMsg, "success");
         resolve();
        setIsLoader(false);
        getAttchmentData()
       } else {
        handleSnackOpen(response.responseMsg, "error");
         reject();
         setIsLoader(false);
       }
     })
     .catch((error) => {
       console.log(error)
       setIsLoader(false);
       handleSnackOpen("Exception : " + error, "error");
       reject();
     });
   }
   function handleRowDeleteD6(oldData, resolve, reject){
    setIsLoader(true);
    DeleteD6(token,oldData)
    .then((response) => {
      if (response.response) {
       handleSnackOpen(response.responseMsg, "success");
        resolve();
       setIsLoader(false);
       getTableData();
      } else {
       handleSnackOpen(response.responseMsg, "error");
        reject();
        setIsLoader(false);
      }
    })
    .catch((error) => {
      console.log(error)
      setIsLoader(false);
      handleSnackOpen("Exception : " + error, "error");
      reject();
    });
  }
useEffect(()=>{
  props.getD6SaveDetails();
  getAttchmentData();
  getTableData();
  getWhoDetails();
  getStatusDetails();
},[])

  function getWhenComponent(objProps) {
    const rowData = objProps.rowData;
    let value = rowData.whenD6;  
    return (
      <TextField
        id="standard-basic"
        value={value}
        type="date"
        fullWidth
        placeholder="Password"
        inputProps={{ min:getTodayDateMoment()}}
        onChange={(e) => objProps.onChange(e.target.value)}
        error={!value}
        helperText={!value?"*required":""}
      />
    );
   }
    return(         
    <Grid container spacing={2} md={12}>
    <Grid item xs={12} className="details-center">
    <h3 className="title-heading">Verify Corrective Actions Implementation and Effectiveness </h3>
  </Grid>
  <Grid className="align-ctr" item xs={12}  md={6}>
  <span>Corrective action implementation Date ( Specify lot number , date code or first production date ) </span>
 </Grid>
 <Grid item xs={12} sm={3} md={4}>
 <TextField
    className="mt-1"
    fullWidth
    name="correctiveAction"
    onChange={handleChange}
    disabled={userDetails.roleId!==3||props?.rowData[0]?.status==="8D Completed"||props?.rowData[0]?.status==="Approved"}
                    value={
                        userDetails.roleId === 1 || userDetails.roleId === 2 || userDetails.roleId===6
     ?d6Data?.correctiveAction
     :inputValues.correctiveAction
   }
   error={
     !inputValues.correctiveAction
   }
   helperText={!inputValues.correctiveAction?"** Required":""}
    type="text"
    id="manufacturingCause"
   multiline
  />
 </Grid>
 {userDetails.roleId===3?
 <Grid item>
 <Button  className="pt-button--primary button-submit" disabled={props?.rowData[0].status==="8D Completed"||props?.rowData[0]?.status==="Approved"} onClick={AddD6Details} variant="contained">
 Save
 </Button>
 </Grid>:<Grid></Grid>
 }
  <Grid item className="align-ctr">
  <span>Supplier will provide evidence of implementation of Corrective Actions described above : </span>
  </Grid>
  <>      
  <Grid container xs={12} className="complaintsHomePage">
    <Grid item xs={12}  className="matTable">
    <div className="Table-Margin">
      <MaterialTable
        //title={getTableTitle()}
        columns={columns}
        data={tableData}
        icons={tableIcons}
        editable={{
            editTooltip: () => userDetails.roleId === 3 ?
                "Edit" : "Action Restricted"
            ,
            deleteTooltip: () => userDetails.roleId === 3 ?
                "Delete" : "Action Restricted"
            ,
            isEditable: isEditable,
            isDeletable: isEditable,
          onRowUpdate: (newTableData, oldTableData) =>
          new Promise((resolve, reject) => {
            handleRowUpdate(
              newTableData,
              oldTableData,
              resolve,
              reject
            );
          }),
          onRowDelete: (oldFormData) =>
          new Promise((resolve, reject) => {
              handleRowDeleteD6(oldFormData, resolve, reject);
          }),
        onRowAdd: (newTableData) =>
          new Promise((resolve, reject) => {
            handleRowAdd(newTableData, resolve, reject);
          }),
                   
        }}
       isLoading={isLoader}
        options={tableOptionsExport}
      />
      </div>
    </Grid>
  </Grid>
</>

<Grid className="align-ctr" item xs={12} sm={6} md={6}>
<h3>Upload Attachment</h3>
</Grid>
<Grid item xs={12} sm={12} md={12}>
<Grid item xs={12}  className="fileMatTable">
    <MaterialTable
      //title={getTableTitle()}
      columns={columnsAttachment}
      data={attachmentData}
      icons={tableIcons}
      editable={{
        deleteTooltip:()=> userDetails.roleId===3?
        "Delete":"Action Restricted",
        isDeletable:  isEditable,
        onRowDelete: (oldFormData) =>
        new Promise((resolve, reject) => {
            handleRowDelete(oldFormData, resolve, reject);
        }),
      onRowAdd: (newTableData) =>
        new Promise((resolve, reject) => {
          handleRowAddAttachment(newTableData, resolve, reject);
        }),
        onRowAddCancelled:()=> {
          setUploadedFileName("");
          setSelectedFile(null)
        },    
      }}
      isLoading={isLoader2}
      options={tableOptionsExport}
    />
  </Grid>

</Grid>
<Dialog open={openValidationDialog}>
      
<DialogContent className="content-center">
{dialogErrorText}
</DialogContent>
<DialogActions>
<Button className="pt-button--secondary button-submit" variant="contained" onClick={handleClose} color="primary">
Ok
</Button>
</DialogActions>
</Dialog>
<>
<SnackbarCustom
  open={open}
  message={snackMessage}
  alertType={alertType}
  handleClose={handleClose}
/>
</>
{isLoaderDraft ? <Loading loaderText="Loading...." /> : <></>}
</Grid>
    )
}