import { baseApiURL } from "../../Utilities/Utility";
import axios from "axios";

export function getRoleDDL(siteToken, userId) {
  const options = {
    method: "get",
    url: baseApiURL + "/Common/GetRolesDDL",
    headers: {
      Authorization: "Bearer " + siteToken,
    },
    params: { userId: userId },
  };
  return (
    axios(options)
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        console.log("Error : ", error);
      })
  );
}
export function getSupplierDDL(siteToken, userId) {
  const options = {
    method: "get",
    url: baseApiURL + "/Common/GetSupplierDDL",
    headers: {
      Authorization: "Bearer " + siteToken,
    },
    params: { userId: userId },
  };
  return (
    axios(options)
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        console.log("Error : ", error);
      })
  );
}

export function GetSupplierEmailDDl(siteToken, userId) {
    const options = {
      method: "get",
      headers: { Authorization: "Bearer " + siteToken },
    };
    return fetch(
      baseApiURL + "/Common/GetSupplierEmailDDL?userId=" + userId,
      options
    )
      .then((response) => response.json())
        .then((data) => {
            console.log("email objects", data.result);
        return data.result;
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
  }
export function GetPartDDl(siteToken,userId){
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(
    baseApiURL + "/Common/GetPartsDDL?userId=" + userId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function GetSeverityDDL(siteToken,userId){
    const options={
        method:"get",
        url:baseApiURL+"/Common/GetSeverityDDL",
        headers:{Authorization:"Bearer "+siteToken,
        },
        params:{userId:userId},
    };
     return(
         axios(options)
         .then((response)=>{
             return response.data.result;
         })
         .catch((error)=>{
             console.log("Error :",error);
         })
     );
}

export function GetDetectedDDL(siteToken, userId) {
    const options = {
      method: "get",
      headers: { Authorization: "Bearer " + siteToken },
    };
    return fetch(baseApiURL + "/Common/GetDetectedDDL?userId=" + userId, options)
      .then((response) => response.json())
      .then((data) => {
        return data.result;
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
  }

export function GetPriorityDDL(siteToken, userId) {
    const options = {
      method: "get",
      headers: { Authorization: "Bearer " + siteToken },
    };
    return fetch(baseApiURL + "/Common/GetPriorityDDL?userId=" + userId, options)
      .then((response) => response.json())
      .then((data) => {
        return data.result;
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
  }
  export function getTeamRoleDDL(siteToken, userId) {
    const options = {
      method: "get",
      url: baseApiURL + "/Common/GetTeamRoleDDL",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: { userId: userId },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
  export function getStockDDL(siteToken, userId) {
    const options = {
      method: "get",
      url: baseApiURL + "/Common/GetStockdetailsDDL", 
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: { userId: userId },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
  export function getActionOwnerDDL(siteToken, userId,cn) {
    const options = {
      method: "get",
      url: baseApiURL + "/Discipline/GetD3DDL",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: { userId: userId,complaintNumber:cn },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
  export function getStatusDDL(siteToken, userId) {
    const options = {
      method: "get",
      url: baseApiURL + "/Common/GetStatusDDL",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: { userId: userId },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
  export function getD1WhoDDL(siteToken,userId,cn) {
   
    const options = {
      method: "get",
      url: baseApiURL + "/Discipline/GetD1DDL",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: { userId: userId,complaintNumber:cn },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
  export function GetApprovedDDL(siteToken,userId){
    const options={
        method:"get",
        url:baseApiURL+"/Common/GetApprovedDDL",
        headers:{Authorization:"Bearer "+siteToken,
        },
        params:{userId:userId},
    };
     return(
         axios(options)
         .then((response)=>{
             return response.data.result;
         })
         .catch((error)=>{
             console.log("Error :",error);
         })
     );
}
export function GetSupplierMasterDDL(siteToken,userId){
  const options={
      method:"get",
      url:baseApiURL+"/Admin/GetSupplierMasterDDL",
      headers:{Authorization:"Bearer "+siteToken,
      },
      params:{userId:userId},
  };
   return(
       axios(options)
       .then((response)=>{
           return response.data.result;
       })
       .catch((error)=>{
           console.log("Error :",error);
       })
   );
}
export function GetStatusMasterDDL(siteToken,userId){
  const options={
      method:"get",
      url:baseApiURL+"/Admin/GetComplaintStatusDDL",
      headers:{Authorization:"Bearer "+siteToken,
      },
      params:{userId:userId},
  };
   return(
       axios(options)
       .then((response)=>{
           return response.data.result;
       })
       .catch((error)=>{
           console.log("Error :",error);
       })
   );
}
export function getRole(siteToken, userId,roleuserid) {
    const options = {
        method: "get",
        url: baseApiURL + "/Admin/GetRolesMapperDetails",
        headers: {
            Authorization: "Bearer " + siteToken,
        },
        params: { userId: userId ,roleuserid:roleuserid},
    };
    return (
        axios(options)
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}