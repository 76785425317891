import React,{useEffect, useState} from "react";
import { Grid,Button } from "@material-ui/core";
import { TextField ,MenuItem} from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {useSelector} from "react-redux";
import MaterialTable from "material-table";
import {tableOptionsAttachment} from "../../../Themes/LightTheme";
import { baseApiURL, BootstrapInput,getTodayDateMoment,tableIcons } from "../../../Utilities/Utility";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from "moment";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import { GetPriorityDDL } from "../../../Redux/APIs/api_Common";
import Loader from "../../../Components/Loader/Loader";
import { AddAttachmentDetails, AddSnapShotDetails, DeleteAttachmentDetails,GetAttachmentDetails, GetSnapShotDetails } from "../../../Redux/APIs/api_Complaint";
import '../Complaints.scss';
import { SaveSiemensRemarks, SaveSupplierRemarks, UpdateSupplierFeedback } from "../../../Redux/APIs/api_status";
import { useNavigate } from "react-router-dom";
export default function Upload(props){
    const token = useSelector((state) => state.saveTokenReducer.token);
    const navigate = useNavigate();
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  ); 
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");     
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = (e) => {
    setOpen(false);
  };
  const [priority,setPriority]=useState([]);
  async function PriorityDDL(){
  const response=await GetPriorityDDL(token,userDetails.userId)
    if(response && response.length>0){
      setPriority(response);
    }
    else{
      setPriority([]);
    }
  }
  const required8D=[
    {
      id:true,
      name:"Yes"
    },
    {
      id:false,
      name:"No"
    },  
  ];
  const [selectedFile, setSelectedFile] = useState(null);
  const [snapshotFile, setSnapshotFile] = useState(null);
  const [uploadImageData, setUploadImageData] = useState("");
  let [inputFileValue, setInputFileValue] = useState(Date.now);
  const [uploadedFileName, setUploadedFileName] = useState("");
  let [inputFileAttachment, setInputFileAttachment] = useState(Date.now);
  const [tableData, setTableData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const updateExcelHandler = (event) => {
    let type = event.target.files[0].type.replace("image/", "");
    if (type === "jpg" || type === "jpeg" || type=== "png") {
      handleSnackOpen(" jpg,jpeg and png format are  not supported","info");
    }
    else{
    setSelectedFile(event.target.files);
    setUploadedFileName(event.target.files[0].name);
    }
  };
  const updateSnapShotHandler = (event) => {
    let type = event.target.files[0].type.replace("image/", "");
    if (type.toLowerCase() !== "jpg" && type.toLowerCase() !== "jpeg" && type.toLowerCase() !== "png") {
      handleSnackOpen("Only jpg,jpeg and pdf format are supported","info");
    }
    else{
      setSnapshotFile(event.target.files[0]); 
    }
  };
 const handleUpload=(e)=>{
  if(
    !(props?.rowData?.status==="Draft"||props?.rowData===""||props?.rowData?.status==="Recall"||props?.rowData?.status==="Sent back to Siemens")
  ){
    handleSnackOpen("Action Restricted", "info");
  }
  else if (!snapshotFile) {
    handleSnackOpen("Please Select a File", "info");
    setIsLoader(false);
  } 
  else{
    setIsLoader(true);
    const formData = new FormData();
      formData.append("file", snapshotFile);
      formData.append("UserId", userDetails.userId);
      formData.append("ComplaintNumber", props?.rowData?props.rowData.complaintNumber:props.complaintDetails.complaintNumber);
    AddSnapShotDetails(token, formData)
        .then((response) => {
            console.log("8d", response);
      if (response.response) {
        setIsLoader(true);
       handleSnackOpen("SnapShot Saved Successfully", "success");
       setUploadImageData(response.objResult); 
       setIsLoader(false);
      } else {
       handleSnackOpen(response.responseMsg, "error");
        setIsLoader(false);
      }
    })
    .catch((error) => {
      setIsLoader(false);
      setSnapshotFile(null)
      setInputFileValue(Date.now);
      handleSnackOpen("Exception : " + error, "error");
    });
  }
 }

 function handleRowAdd(newTableData, resolve, reject){
  if (!selectedFile) {
    handleSnackOpen("Please Select a File", "info");
    setIsLoader(false);
    reject();
  } 
  else{
    setIsLoader(true);
    const formData = new FormData();
    for (let i = 0 ; i < selectedFile.length ; i++) {
      formData.append("file", selectedFile[i]);
     }
   formData.append("file", selectedFile);
      formData.append("userId", userDetails.userId);
      // formData.append("ComplaintNumber", props?.rowData?props.rowData.complaintNumber:props.complaintDetails.complaintNumber);

      // const compNumber = props.complaintDetails != ''?props.complaintDetails.complaintNumber : props.rowData.complaintNumber;

      //const compNumber = props.rowData?.name == "duplicateComplaint" && props.rowData?.status == "Draft" ? props.complaintDetails.complaintNumber : props.complaintDetails.complaintNumber;

      const compNumber = props.rowData?.status == "Draft" ? props.rowData?.name == "duplicateComplaint" ? props.complaintDetails.complaintNumber : props.rowData.complaintNumber : props.complaintDetails.complaintNumber;

      formData.append("ComplaintNumber", compNumber);
      setIsLoader(false); 
    AddAttachmentDetails(token, formData)
    .then((response) => {
      if (response.response) {
       handleSnackOpen(response.responseMsg, "success");
       setUploadedFileName("");
       setSelectedFile(null);
        resolve();
       setIsLoader(false);
       getTableData();
      } else {
       handleSnackOpen(response.responseMsg, "error");
        reject();
        setIsLoader(false);
      }
    })
    .catch((error) => {
      console.log(error)
      setIsLoader(false);
      setSelectedFile(null);
      setInputFileAttachment(Date.now);
      handleSnackOpen("Exception : " + error, "error");
      reject();
    });
  }
  }
 
    function handleRowDelete(oldFormData, resolve, reject) {
        if (userDetails.roleId === 3) {
            handleSnackOpen("Action Restricted", "info");
            reject();
            return false;
        }
      setIsLoader(true);
      DeleteAttachmentDetails(token,oldFormData)
      .then((response) => {
        if (response.response) {
         handleSnackOpen(response.responseMsg, "success");
          resolve();
         setIsLoader(false);
         getTableData();
        } else {
         handleSnackOpen(response.responseMsg, "error");
          reject();
          setIsLoader(false);
        }
      })
      .catch((error) => {
        console.log(error)
        setIsLoader(false);
        handleSnackOpen("Exception : " + error, "error");
        reject();
      });
    }
    const handleChangeInputs=(event,value)=>{
      let elem=event.target.name;
      switch(elem){
        case "priorityId":
          if(props?.rowData?.status==="Draft"||props?.rowData?.status==="Recall"||props?.rowData?.status==="Sent back to Siemens"){
            props.setDraftInputs({
              ...props.draftInputs,
              [elem]:value.props.value,
            })
           } 
           else{
          props.setCreateComplaintInputs({
            ...props.createComplaintInputs,
              [elem]:value.props.value,
          })
        }
          break;
          case "closeDate":
            if(props?.rowData?.status==="Draft"||props?.rowData?.status==="Recall"||props?.rowData?.status==="Sent back to Siemens"){
              props.setDraftInputs({
                ...props.draftInputs,
                targetdate:event.target.value,
              })
             } 
             else{
          props.setCreateComplaintInputs({
            ...props.createComplaintInputs,
              targetdate:event.target.value,
          })
        }
        break;
        case "required8D":
          if(props?.rowData?.status==="Draft"||props?.rowData?.status==="Recall"||props?.rowData?.status==="Sent back to Siemens"){
            props.setDraftInputs({
              ...props.draftInputs,
              [elem]:event.target.value,
            })
           } 
           else{
          props.setCreateComplaintInputs({
            ...props.createComplaintInputs,
              [elem]:event.target.value,
          })
        }
        break;
        case "frequency":
          if(props?.rowData?.status==="Draft"||props?.rowData?.status==="Recall"||props?.rowData?.status==="Sent back to Siemens"){
            props.setDraftInputs({
              ...props.draftInputs,
              [elem]:parseInt(event.target.value),
            })
           } 
           else{
          props.setCreateComplaintInputs({
            ...props.createComplaintInputs,
            [elem]:parseInt(event.target.value),
        });
      }
        break;
        default:
          break;
      }
    }
 var columns=[
  {
      title:"Attachment",
      field:"fileName",
    editComponent: (props) => getAttachmentComponent(props)
  },
  userDetails.roleId===3||userDetails.roleId===1?
  {
    title: "Download",
    field: "download",
    editable:"never",
    render: (rowData) => DownloadFileBtn(rowData),
  }:{}

];
 function DownloadFileBtn(data){
  return (
    <Button
      className="pt-button--tertiary" variant="contained" 
      onClick={() =>
        downloadFileAttachment(data)
      }
    >
     Download
    </Button>
  );
  
 }
 useEffect(()=>{
  getSnapShotView();
  if(props.complaintDetails){
   }  
   getTableData();
},[props.complaintDetails,props.rowData])

  function getTableData() {
    setIsLoader(true);
      if( props?.rowData?.status && !(props?.rowData?.name)){
        GetAttachmentDetails(token, userDetails.userId,props.rowData?.complaintNumber)
        .then((response) => {
          setTableData(response?response:[]);
          setIsLoader(false);
        })
        .catch((error) => {
         handleSnackOpen("Error : " + error, "error");
          setIsLoader(false);
        });
      }
    else{
    GetAttachmentDetails(token, userDetails.userId,props?.complaintDetails.complaintNumber)
      .then((response) => {
        setTableData(response?response:[]);
        setIsLoader(false);
      })
      .catch((error) => {
       handleSnackOpen("Error : " + error, "error");
        setIsLoader(false);
      });
    }
}
function getSnapShotView(){
  if( props.rowData?.status){
  GetSnapShotDetails(token, userDetails.userId,props.rowData?.complaintNumber)
  .then((response) => {
    if(props?.rowData?.name){
      setUploadImageData(null);
    }
    else{
    setUploadImageData(response.objResult);
    }
    setIsLoader(false);
  })
  .catch((error) => {
   handleSnackOpen("Error : " + error, "error");
    setIsLoader(false);
  });
}
}
  function getAttachmentComponent(objProps) {
    return ( 
      <form className="bulk-upload-form">
      {selectedFile == null ?<>
   <TextField
      id="fileUploadAttachment"
      variant="filled"
      label="Attachment Upload"
      fullWidth
      onChange={updateExcelHandler}  
      disabled={disable}
      key={inputFileAttachment}
      type="file"
      InputLabelProps={{shrink: true}}
      inputProps={{ multiple: true }}   
      helperText={uploadedFileName} 
  />
  </>:<>
  <span>{uploadedFileName}</span></>}
  </form>
    );
  }
  function downloadFileAttachment(data){
    let attachmentData={
      complaintNumber: (data.complaintNumber).trim(),
      fileName: (data.fileName).trim(),                                                                                                                                                           
      fileUrl:(data.fileUrl).trim(),
      filetype:(data.filetype).trim()
    }
    const fetchPromise=fetch(baseApiURL + "/Admin/downloadfile",
    {
      method:"post",
      headers:{
        "Content-type":"application/json; charset=UTF-8",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify(attachmentData),
  });
  fetchPromise.then(response=>{
    response.blob().then(blob=>{
      const link=document.createElement('a');
      const url=URL.createObjectURL(blob);
      link.href=url;
      link.download="Attachment_"+props.rowData?.complaintNumber+attachmentData.filetype;
      link.click();
    })
  })
}
let required8CM=(props.createComplaintInputs.required8D===true||props.createComplaintInputs.required8D===false)?true:false;
let required8DDI=(props.draftInputs.required8D===true||props.draftInputs.required8D===false)?true:false;
let required8DRD=(props.rowData.required8D===true||props.rowData.required8D===false)?true:false
const [disable,setDisable]=useState(true);
  function disableForm(){
            if(props?.rowData?.status==="Draft"||props?.rowData===""||props?.rowData?.status==="Recall"||props?.rowData?.status==="Sent back to Siemens"){
                if( props?.rowData?.status==="Sent back to Siemens" && userDetails.roleId===3){
                  setDisable(true);
                   }
                else{
                   setDisable(false);
                 }
             }
             else{
                 setDisable(true);
             }
  }
  useEffect(()=>{
   if(props){
    disableForm();
   }
    PriorityDDL();
  },[])
  
  const [feedbackDialogOpen, setFeedbackDialogOpen] = React.useState(false);
  const [siemensFeedback, setSiemensFeedback] = useState('');
    const handleFeedbackClose = () => {
        setSiemensFeedback('');
    setFeedbackDialogOpen(false);
  };
    const enterFeedbackClick = () => {
        setSiemensFeedback('');
      setFeedbackDialogOpen(true);

  }
  const siemensFeedbackChange = (e) => {
    console.log("Feedback Val", e.target.value);
    setSiemensFeedback(e.target.value);
  }
  const siemensFeedbackSubmit = () =>{
    console.log("Siemens Feedback Clicked");
    setIsLoader(true);
    const comNor = props.draftInputs.complaintNumber;
    SaveSiemensRemarks(token, comNor,siemensFeedback, userDetails.userId)
            .then((response) => {
                handleSnackOpen(response.responseMsg);
                setIsLoader(false);
                navigate("/complaintsList");
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                setIsLoader(false);
            });
      setFeedbackDialogOpen(false);
  }

  const supplierFeedbackSubmit = () =>{
    setIsLoader(true);
    const comNor = props.draftInputs.complaintNumber;
    SaveSupplierRemarks(token, comNor,siemensFeedback, userDetails.userId)
            .then((response) => {
                handleSnackOpen(response.responseMsg);
                setIsLoader(false);
                navigate("/complaintsList");
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                setIsLoader(false);
            });
      setFeedbackDialogOpen(false);
  }

  const approveSupplierFeedback = ()=>{
    console.log("Approve Feedback Clicked");
    setIsLoader(true);
    const comNor = props.draftInputs.complaintNumber;
    UpdateSupplierFeedback(token, comNor,userDetails.userId)
      .then((response) => {
          handleSnackOpen(response.responseMsg);
          setIsLoader(false);
          navigate("/complaintsList");
      })
      .catch((error) => {
          handleSnackOpen("Exception : " + error, "error");
          setIsLoader(false);
      });
      setFeedbackDialogOpen(false);
  }
return( 
  <>
    <div>
    <Grid container  className="complaintsHomePage" >
    <Grid className="view-block">
    <Grid item xs={12}  >
      <h3 className="title-heading">Upload Attachment </h3>
    </Grid>
    <Grid  className="upload-bt" container spacing={3}>
    <Grid item xs={12} md={8} className="fileMatTable">
    <MaterialTable
            columns={columns}
            data={tableData} 
            icons={tableIcons}
           isLoading={isLoader}
            options={tableOptionsAttachment}
            editable={{
          onRowAdd: (newFormData) =>
              new Promise((resolve, reject) => {
                  handleRowAdd(newFormData, resolve, reject);
              }),
          onRowAddCancelled:()=> {
            setUploadedFileName("");
            setSelectedFile(null)
          },
          onRowDelete: (oldFormData) =>
              new Promise((resolve, reject) => {
                  handleRowDelete(oldFormData, resolve, reject);
              }),
              }}
          />
          <>
              <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
              />
            </>
  </Grid>

</Grid>
<Grid item xs={12}  >
      <h3 className="title-heading">Upload Snapshot</h3>
    </Grid>
    
    <Grid   container spacing={3} md={12}>
    <Grid item xs={12} sm={6} md={3} >
    <form className="upload-form">
      <TextField
        id="snapshotUpload"
        variant="filled"
        label="SnapShot Upload"
        inputProps={{ accept: "image/*", multiple: false }}
        fullWidth
        onChange={updateSnapShotHandler}
        key={inputFileValue}
        type="file"
        InputLabelProps={{ shrink: true }}
      />
    </form>
    <span>*formats allowed are .jpeg, .png, .jpg</span>
  </Grid>
  <Grid item xs={12} sm={2} md={1} >
  <Button  onClick={handleUpload}  disabled={!snapshotFile} className="pt-button--primary button-submit" variant="contained">
   Save 
 </Button>
</Grid>
 <Grid  item xs={12} md={3}>
 <Grid className="view-flex">
  <Button onClick={handleClickOpen} disabled={!uploadImageData} className="pt-button--secondary button-submit" variant="contained">
  View
 </Button>
 {userDetails.roleId===3 || props?.rowData?.status?<h4 style={{margin:"7px"}}>{uploadImageData?props.rowData?.complaintNumber+"_SnapShot":""}</h4>:""}
 </Grid>
</Grid>

</Grid>
  <Grid className="border-bt">
 
  <h3 className="margin-text">Please analyse above mentioned defect/problem and provide the necessary CAPA/8D </h3>
  <Grid container spacing={2}>
  <Grid item xs={12} sm={6} md={3}>
   
    <FormControl error={
      props.rowData
    ?props.rowData.status==="Draft"||props.rowData.status==="Recall"||props?.rowData?.status==="Sent back to Siemens"
    ?!(props.draftInputs.priorityId)
    :!(props.rowData.priorityId)
    :!(props.createComplaintInputs.priorityId)
    } fullWidth>
   <InputLabel  shrink htmlFor="bootstrap-input"> Priority **</InputLabel>
   <Select
     labelId="demo-customized-select-label"
     id="demo-customized-select"
     name="priorityId"
    value={
      props.rowData
    ?props.rowData.status==="Draft"||props?.rowData?.status==="Recall"||props?.rowData?.status==="Sent back to Siemens"
    ?props.draftInputs.priorityId
    :props.rowData.priorityId
    :props.createComplaintInputs.priorityId
    }
    disabled={disable}
     onChange={handleChangeInputs}
     input={<BootstrapInput />}
   >
   {
  priority.map((item)=>(
       <MenuItem key={item.id} value={item.id}>
       {item.name}
       </MenuItem>
     ))
   }
   </Select>
 </FormControl>

 </Grid>
 <Grid item xs={12} sm={6} md={3}>
 <FormControl  error={
 props.rowData
    ?props.rowData.status==="Draft"||props.rowData.status==="Recall"||props?.rowData?.status==="Sent back to Siemens"
    ?!(props.draftInputs.targetdate)
    :""
    :!(props.createComplaintInputs.targetdate)
} fullWidth>
   <InputLabel shrink htmlFor="bootstrap-input">
   Target date for closure **
   </InputLabel>
   <BootstrapInput 
   type="date"
    id="bootstrap-input"  name="closeDate"
   onChange={handleChangeInputs}
   inputProps={{ min:getTodayDateMoment()}}
   disabled={disable}
   value={
  props.rowData
  ?props.rowData.status==="Draft"||props?.rowData?.status==="Recall"||props?.rowData?.status==="Sent back to Siemens"
  ?moment(props.draftInputs.targetdate).format('YYYY-MM-DD')
  :moment(props.rowData.targetdate).format('YYYY-MM-DD')
  :props.createComplaintInputs.targetdate
   }
    />
 </FormControl>
 </Grid>
 {userDetails.roleId!==3?
 <Grid item xs={12} sm={6} md={3}>
   <FormControl error={
    props.rowData
  ?props.rowData.status==="Draft"||props.rowData.status==="Recall"||props?.rowData?.status==="Sent back to Siemens"
  ?!(props.draftInputs.frequency)
  :!(props.rowData.frequency)
  :!(props.createComplaintInputs.frequency)
  }  fullWidth>
   <InputLabel shrink htmlFor="bootstrap-input">
    Frequency
   </InputLabel>
   <BootstrapInput  type="number" id="bootstrap-input" 
    name="frequency"
    inputProps={{ min:0}}
    value={
      props.rowData
      ?props.rowData.status==="Draft"||props.rowData.status==="Recall"||props?.rowData?.status==="Sent back to Siemens"
      ?props.draftInputs.frequency
      :props.rowData.frequency
      :props.createComplaintInputs.frequency
    }
   disabled={disable}
    onChange={handleChangeInputs}
   />
 </FormControl>
   </Grid>
   :<></>}
 <Grid item xs={12} sm={6} md={3}>
   
    <FormControl  error={
      props.rowData
      ?props.rowData.status==="Draft"||props.rowData.status==="Recall"||props?.rowData?.status==="Sent back to Siemens"
      ?!(required8DDI)
      :!(required8DRD)
      :!(required8CM)
    } fullWidth>
   <InputLabel  shrink htmlFor="bootstrap-input"> 8D Required **</InputLabel>
   <Select
     labelId="demo-customized-select-label"
     id="demo-customized-select"
     name="required8D"
   value={
    props.rowData
    ?props.rowData.status==="Draft"||props.rowData.status==="Recall"||props?.rowData?.status==="Sent back to Siemens"
    ?props.draftInputs.required8D
    :props.rowData.required8D
    :props.createComplaintInputs.required8D
  }
  disabled={disable}

     onChange={handleChangeInputs}
     input={<BootstrapInput />}
   >
   {
  required8D.map((item)=>(
       <MenuItem key={item.id} value={item.id}>
       {item.name}
       </MenuItem>
     ))
   }
   </Select>
 </FormControl>
 </Grid>
 {props.rowData?.acknowledgement?
  <Grid container className="view-block">
  <Grid  item xs={12} sm={3} md={6}>
 <h3>Acknowlege Remarks By Supplier</h3>
 </Grid>
 <Grid  item xs={12} sm={9} md={7}>
 <TextField 
 name="Remarks"
 multiline
 disabled
 variant="outlined"
 maxRows={3}
 value={props.rowData?.name?null:props.rowData?.acknowledgement}
 fullWidth
 id="Remarks"
 />
 </Grid>
 </Grid>:<></>
   }
 {props.rowData?.remarksFor8D?
 <Grid container className="view-block">
 <Grid  item xs={12} sm={3} md={6}>
<h3>Rejected Remarks By Supplier</h3>
</Grid>
<Grid  item xs={12} sm={9} md={7}>
<TextField 
name="Remarks"
multiline
disabled
variant="outlined"
maxRows={3}
value={props.rowData?.name?null:props.rowData?.remarksFor8D}
fullWidth
id="Remarks"
/>
</Grid>
</Grid>:<></>
  }

{props.rowData?.feedbackFor8D?
 <Grid container className="view-block">
  <Grid item xs={12} sm={3} md={12}>
    <h3>Feedback Closure Comments By Supplier</h3>
  </Grid>
  <Grid item xs={12} sm={6} md={6}>
    <TextField 
    name="Remarks"
    multiline
    disabled
    variant="outlined"
    maxRows={3}
    value={props.rowData?.name?null:props.rowData?.feedbackFor8D}
    fullWidth
    id="Remarks"
    />
  </Grid>

  {userDetails.roleId == 3 ? <Grid item xs={12} sm={3} md={3} className="gridCenter">
    <Button className="pt-button--primary" onClick={enterFeedbackClick}
     disabled= {props.rowData.statusId == 9 ? false : true } >
      Enter Comments
    </Button>
  </Grid> : <Grid item xs={12} sm={3} md={3} className="gridCenter">
    <Button className="pt-button--primary" onClick={approveSupplierFeedback}
     disabled={!(props.rowData.statusId == 11 && (userDetails.roleId == 1 || userDetails.roleId == 2))}>
      Approve
    </Button>
  </Grid>}
</Grid>:<></>
  }
  
{props.rowData.required8D == false ? (<Grid container className="view-block">
    <Grid item xs={12} sm={3} md={12}>
      <h3>Feedback Rejection Comments By Siemens</h3>
    </Grid>
    <Grid item xs={12} sm={6} md={6}>
      <TextField 
      name="Remarks"
      multiline
      disabled
      variant="outlined"
      maxRows={3}
      value={props.rowData?.name?null:props.rowData?.remarksbysiemens}
      fullWidth
      id="Remarks"
      />
    </Grid>
    {userDetails.roleId != 3 ? <Grid item xs={12} sm={3} md={3} className="gridCenter">
      <Button className="pt-button--primary" onClick={enterFeedbackClick}
       disabled={props.rowData.statusId == 11 ? false : true} >
        Enter Comment
      </Button>
    </Grid> :  <></>}
</Grid>) : <></>}

 <Dialog  fullWidth className="dialog-image" open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
 <DialogTitle id="form-dialog-title">Image Preview</DialogTitle>
 <DialogContent>
 { uploadImageData?.toString()?.length > 0 ?<img className="img-content" src={uploadImageData} alt="no test" />: "Please Select a File"}
 </DialogContent>
 <DialogActions>
   <Button className="pt-button--primary button-submit" variant="contained" onClick={handleCloseDialog} color="primary">
     Close
   </Button>
 </DialogActions>
</Dialog>
  </Grid>
  </Grid>
    </Grid>
    </Grid>
    </div>

    <Dialog open={feedbackDialogOpen} onClose={handleFeedbackClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Enter Siemens Comments</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="feedback"
            label="Siemens Feedback"
                    fullWidth
                    value={siemensFeedback}
            multiline
            maxRows={4}
            onChange={(e)=>siemensFeedbackChange(e)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFeedbackClose}  className="pt-button--secondary">
            Cancel
          </Button>
          <Button onClick={userDetails.roleId == 3 ? supplierFeedbackSubmit : siemensFeedbackSubmit } className="pt-button--primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

    {isLoader ? <Loader loaderText="Loading...." /> : <></>}
    </>
);
}