import { baseApiURL} from "../../Utilities/Utility";
import axios from "axios";
  export function AcknowlegeStatus(siteToken,complaintNumber,Acknowledgement,userId) {
    const options = {
      method: "put",
      url: baseApiURL + "/Admin/UpdateStatusAsPendingAtSupplier",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: {complaintNumber:complaintNumber,userId: userId ,Acknowledgement:Acknowledgement},
    };
    return (
      axios(options)
        .then((response) => {
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
 
  export function RejectStatus(siteToken,complaintNumber,remarks,userId) {
    const options = {
      method: "put",
      url: baseApiURL + "/Admin/UpdateRemarksfor8DBySupplier",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: {complaintNumber:complaintNumber,remarks:remarks,userId: userId  },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
  export function FeedbackBySupplierStatus(siteToken,complaintNumber,feedback,userId) {
    const options = {
      method: "put",
      url: baseApiURL + "/Admin/UpdateStatusAs8DFeedbackBySupplier",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: {complaintNumber:complaintNumber,Feedback:feedback,userId: userId  },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
  export function Status8DBySupplier(siteToken,complaintNumber,userId) {
    const options = {
      method: "put",
      url: baseApiURL + "/Admin/UpdateStatusAs8DCompleted",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: {complaintNumber:complaintNumber,userId: userId  },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
  export function Status8DApproveBySiemens(siteToken,complaintNumber,userId) {
    const options = {
      method: "put",
      url: baseApiURL + "/Admin/UpdateStatusAsApproved",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: {complaintNumber:complaintNumber,userId: userId  },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
  export function RejectRemarksBySiemens(siteToken,complaintNumber,remarks,userId) {
    const options = {
      method: "put",
      url: baseApiURL + "/Admin/UpdateRemarksfor8DBySiemens",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: {complaintNumber:complaintNumber,remarks:remarks,userId: userId  },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
  export function StatusRecallBySiemens(siteToken,complaintNumber,userId) {
    const options = {
      method: "put",
      url: baseApiURL + "/Admin/UpdateStatusAsRecalled",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: {complaintNumber:complaintNumber,userId: userId  },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
  export function SaveAsDraft8D(siteToken,complaintNumber,userId,discipline) {
    const options = {
      method: "put",
      url: baseApiURL + "/Discipline/UpdateDisciplineSaveDraft",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: {complaintNumber:complaintNumber,userId: userId,discipline:discipline },
    };
    return (
      axios(options)
        .then((response) => {
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }

  export function UpdateSupplierFeedback(siteToken,complaintNumber,userId) {
    const options = {
      method: "put",
      url: baseApiURL + "/Admin/UpdateStatusAsApproved",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: {complaintNumber:complaintNumber, userId: userId  },
    };

    return (
      axios(options)
        .then((response) => {
          console.log("My Response", response);
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }

  export function SaveSiemensRemarks (siteToken,complaintNumber,remarks,userId) {
    const options = {
      method: "put",
      url: baseApiURL + "/Admin/UpdateRemarksfor8DBySiemens",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: {complaintNumber:complaintNumber,remarks : remarks, userId: userId  },
    };

    return (
      axios(options)
        .then((response) => {
          console.log("My Response", response);
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
  export function SaveSupplierRemarks (siteToken,complaintNumber,feedback,userId) {
    const options = {
      method: "put",
      url: baseApiURL + "/Admin/UpdateStatusAs8DFeedbackBySupplier",
      headers: {
        Authorization: "Bearer " + siteToken,
      },
      params: {Complaintnumber:complaintNumber,Feedback : feedback, userId: userId  },
    };

    return (
      axios(options)
        .then((response) => {
          console.log("My Response", response);
          return response.data.result;
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
    );
  }
